<template>
  <section class="preguntas">
    <!-- <validation-questions v-if="selected_bot" /> -->
    <!-- <ValidateDesactiveBots
      v-if="selected_bot"
      :bot="selected_bot"
      :key="selected_bot.id"
    /> -->
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container">
        <FadeTransition :duration="200">
          <div class="row justify-content-center" v-show="showFaqs">
            <div class="col-lg-6 mb-5">
              <div class="mt-6">
                <div class="label-box mb-5">
                  <h2 class="">
                    {{
                      selectedCustomFaq != null
                        ? 'Editar Pregunta personalizada'
                        : 'Nueva Pregunta personalizada'
                    }}
                  </h2>
                  <FadeTransition :duration="200">
                    <button
                      v-if="view_permissions.add == true && selectedCustomFaq?.dialog_node"
                      @click="addACustomAnswer"
                      class="text_sm btn-select static m-0"
                    >
                      + Crear nueva
                    </button>
                  </FadeTransition>
                </div>
                <!-- <p class="mb-5">
                  Aquí puedes configurar respuestas que dará tu chatbot.
                </p> -->
                <!-- <div class="row justify-content-between align-items-center">
                  <div class="btn-box">
                    <button
                      class="btn btn-base custom-left"
                      :class="predefinidas ? '' : 'outline'"
                      @click="
                        predefinidas = true;
                        personalizadas = false;
                        showEditCustom = false;
                      "
                      >Predefinidas
                    </button>
                    <button
                      class="btn btn-base custom-right"
                      :class="predefinidas ? 'outline' : ''"
                      @click="(personalizadas = true), (predefinidas = false)"
                      >Personalizadas
                    </button>
                  </div>
                </div> -->
                <FadeTransition :duration="200">
                  <AddFaq
                    v-if="!messageLimit"
                    :botId="selected_bot.id"
                    :index="selectedIndex"
                    :currentFaq="selectedCustomFaq"
                    :businessType="selectedBotBusinessType"
                    :isSkillBase="isSkillBase"
                    :isBotLLM="isBotLLM"
                    :parent="parent"
                    :view_permissions="view_permissions"
                    :allFaqs="customFaqsSorted"
                    @createdCustomFaq="createdCustomFaq"
                    @updatedCustomFaq="updatedCustomFaq"
                    @activatedCustomFaq="activatedCustomFaq"
                    @liveNameMethod="textName = $event"
                    @cancel="textName = ''"
                    @updateView="onSelectedBot(selected_bot)"
                    ref="addFaq"
                    />
                </FadeTransition>
                <hr class="my-5" />
                <!-- <ChangeBusinessType
                  v-if="predefinidas"
                  :currentBusinessType="currentBusinessType"
                /> -->
                <div v-if="predefinidas" class="info_box mb-4">
                  <label>¿Buscas crear tus propias preguntas y respuestas?</label>
                  <p>
                    Si cuentas con una cuenta de pago, solo cambia la opción a
                    <span
                      class="destacado"
                      @click="(personalizadas = true), (predefinidas = false)"
                      >personalizadas</span
                    >. Si cuentas con un plan gratuito, es momento de que mejores
                    tu plan y pases al siguiente nivel.
                  </p>
                </div>
                <div v-if="messageLimit">
                  <div class="info_box mb-4">
                    <div class="row align-items-center justify-content-center">
                      <p class="text-center">
                        Haz llegado al límite de preguntas personalizadas. Para
                        obtener más, mejora tu plan ahora.
                      </p>
                      <!-- TODO Sistema de planes con flow deprecado -->
                      <!-- <button
                        class="btn btn-base second mt-4 mb-0"
                        @click="UpgradePlan(selected_bot)"
                      >
                        Mejorar plan
                      </button> -->
                    </div>
                  </div>
                </div>
                <!-- <div v-else>
                  <template v-if="!predefinidas">
                    <div class="info_box bg_grey">
                      <div class="row justify-content-center">
                        <button
                          class="btn btn-base second"
                          v-show="!showEditCustom"
                          v-if="!messageLimit"
                          @click="addACustomAnswer()"
                          >Agregar respuesta automática
                        </button>
                      </div>
                    </div>
                  </template>
                </div> -->
                <router-link class="btn-text" to="/dashboard"
                  >&lt;- Volver
                </router-link>
              </div>
            </div>
            <div class="col-lg-4 justify-content-end d-flex mb-5">
              <div class="custom-col mt-6">
                <input
                  type="search"
                  class="input_search-lg"
                  placeholder="Buscar preguntas"
                  v-model="faqSearch"
                />
                <div v-show="predefinidas">
                  <div
                    class="scroll_box"
                    :class="{ slice_scroll: filteredFaqs.length >= 3 }"
                  >
                    <div v-if="faqSearch.length == 0" class="card buefy">
                      <div class="d-flex justify-content-between">
                        <div class="col-lg-10 pl-0">
                          <label class="mb-0"
                            >¿Deseas que tu chatbot solicite RUT?
                          </label>
                        </div>
                        <div class="col-lg-2 d-flex justify-content-end pr-0">
                          <b-switch
                            v-model="botInfo.has_rut"
                            @input="saveRutSelection(botInfo)"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="faq in customFaqsSorted"
                      :key="faq.id"
                      class="card click"
                      @click="faqEdit(), (selectedFaq = faq)"
                    >
                      <div class="d-flex justify-content-between">
                        <div class="col-lg-10 pl-0">
                          <label class="hover_edit click mb-0">{{
                            faq.title
                          }}</label>
                        </div>
                        <div class="col-lg-2 buefy d-flex justify-content-end pr-0">
                          <b-switch v-model="faq.active" @input="save(faq)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Visible to debug -->
                <div v-show="personalizadas">
                  <SpinnerLoading v-if="loading" />
                  <div v-if="textName.length > 0 && !selectedCustomFaq" class="card buefy custom-card">
                    <div class="row justify-content-between">
                      <div class="col-lg-8 pl-0">
                        <label class="mb-0">{{ textName }}</label>
                      </div>
                      <div class="col-lg-2 d-flex align-items-center pr-0">
                        <b-switch disabled />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="customFaqs.length > 0"
                    :class="{ scroll_box: true, slice_scroll: customFaqs.length > 8 }"
                  >
                    <div
                      :class="{ 'card card-click buefy btn-show-sm': true, selected: selectedCustomFaq == customFaq }"
                      @click="
                        showEditCustom = true;
                        textName = '';
                        selectedCustomFaq = customFaq;
                        selectedIndex = customFaqIndex;
                        addFaqKey += 1;
                      "
                      v-for="(customFaq, customFaqIndex) in customFaqsSorted"
                      :key="customFaqIndex"
                    >
                      <div class="d-flex justify-content-between">
                        <div class="col-lg-8 pl-0">
                          <label class="mb-0">{{ customFaq.intent }}</label>
                        </div>
                        <div class="col-lg-2 pr-0">
                          <span
                            @click.stop="
                              deleteCustomFaq(customFaqIndex, customFaq)
                            "
                            class="btn-dash btn-dash-sm btn-hover"
                            ><i class="fas fa-trash-alt m-0" />
                          </span>
                        </div>
                        <div class="col-lg-2 d-flex align-items-center pr-0">
                          <b-switch
                            v-model="customFaq.active"
                            @input="
                              selected_bot && selected_bot.has_skill_base
                                ? saveSkillBaseFaq(customFaq)
                                : selectedBotBusinessType.show == true
                                ? save(customFaq)
                                : saveCorporateFaq(customFaq)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-end my-2">
                    <p class="text_sm pr-3 mb-0">
                      {{ customFaqsSorted.length }}
                      {{
                        customFaqsSorted.length == 1 ? 'Pregunta' : 'Preguntas'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeTransition>
        <FadeTransition :duration="200">
          <div
            class="col-lg-6 justify-content-center my-5 mx-auto"
            v-show="showEdit"
          >
            <div class="card no_click my-5">
              <div class="card__box">
                <h2>
                  Respuesta a:
                  <span class="destacado">{{ selectedFaq.title }}</span>
                </h2>
                <p>
                  Si el cliente pregunta por "{{ selectedFaq.title }}", el chatbot
                  responderá:
                </p>
                <textarea
                  rows="3"
                  class="no_textarea my-3"
                  placeholder="Debes contar con la información correspondiente."
                  v-model="selectedFaq.response"
                />
                <div class="text-center">
                  <button class="btn btn-base md" @click="save(selectedFaq)">
                    Guardar respuesta
                  </button>
                  <p class="btn-text mb-0" @click="Back">&lt;- Volver</p>
                </div>
              </div>
            </div>
          </div>
        </FadeTransition>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && loading == false"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import axios from 'axios';
import dashboard_api from '@/dashboard_api.js';
import environment from '@/environment';
import '../../assets/buefy_class.scss';
import { FadeTransition } from 'vue2-transitions';
import AddFaq from './AddFaq';
import ChangeBusinessType from './ChangeBusinessType';
// import ValidationQuestions from './components/validationQuestions';
// import ValidateDesactiveBots from '@/components/ValidateDesactiveBots';
import { mapState } from 'vuex';
import { checkIfBotLLM } from '@/utils/utils';

export default {
  components: {
    FadeTransition,
    AddFaq,
    ChangeBusinessType,
    // ValidationQuestions,
    // ValidateDesactiveBots,
  },
  data() {
    return {
      textName: '',
      faqSearch: '',
      currentBusinessType: '',
      predefinidas: false,
      personalizadas: true,
      messageLimit: false,
      questionLimit: 0,
      actualQuestions: 0,
      // upgrade plan
      my_register: {},
      botInfo: {},
      loading: true,
      showEditCustom: false,
      showEdit: false,
      showFaqs: true,
      selectedBotBusinessType: null,
      selectedFaq: {},
      filteredFaqs: [],
      selectedCustomFaq: null,
      addFaqKey: 0,
      faqs: [
        {
          active: false,
          title: 'Mensaje de bienvenida',
          response: 'Hola, estoy en línea. ¿En qué puedo ayudarte?',
          intent: 'welcome',
        },
        {
          active: false,
          title: 'Requisitos',
          response: 'Debes contar con la información correspondiente.',
          intent: 'faq_requisitos',
        },
        {
          active: false,
          title: 'Ubicación de la empresa',
          response: 'Nos encontraremos en la calle falsa nº 1234.',
          intent: 'faq_ubicacion_empresa',
        },
        {
          active: false,
          title: 'Más información',
          response: 'Déjanos tus datos y te enviaremos la información.',
          intent: 'faq_mas_informacion',
        },
        {
          active: false,
          title: 'Lista de precios',
          response:
            'Déjanos tus datos y te enviaremos una lista de precios actualizada.',
          intent: 'faq_lista_precios',
        },
        {
          active: false,
          title: 'Entregas',
          response: 'Los envíos tardan de 3 a 5 días hábiles.',
          intent: 'faq_entregas',
        },
        {
          active: false,
          title: 'Precio',
          response:
            'Indícanos en qué tienes interés y un asesor te enviará la contización texto oculto de prueba.',
          intent: 'faq_precio',
        },
        {
          active: false,
          title: 'Envíos',
          response: 'Realizamos envíos a todo el país.',
          intent: 'faq_envios',
        },
        {
          active: false,
          title: 'Formas de pago',
          response:
            'Puedes realizar tu compra con la tarjeta de crédito, débito o transferencia bancaria.',
          intent: 'faq_formas_pago',
        },
        {
          active: false,
          title: 'Horario de atención',
          response: 'Atendemos de lunes a viernes de 9 a 18 hrs.',
          intent: 'faq_horario_atencion',
        },
      ],
      customFaqs: [],
      parent: '',
      selectedIndex: 0,
      isSkillBase: false,
      isBotLLM: false
    };
  },
  async created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(['selected_bot', 'skill_template', 'view_permissions']),

    ...mapState('assistantsModule', ['assistant']),

    faqFilter() {
      let faqData = this.predefinidas ? this.filteredFaqs : this.customFaqs;

      if (!this.faqSearch) return faqData;

      return faqData.filter(item => {
        return [this.predefinidas ? item.title : item.intent].find(field => {
          return field
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              this.faqSearch
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim(),
            );
        });
      });
    },
    customFaqsSorted() {
      return this.faqFilter;
      // return this.faqFilter.sort((a, b) => (a.intent > b.intent ? 1 : -1));
    },
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.onSelectedBot(val);
      },
    },
  },
  methods: {
    selectItem(item) {
      this.SelectedOptionBox = item;
      for (const select_item of this.businessTypes) {
        select_item.show = false;
      }
      item.show = !item.show;
    },
    getBusinessTypes() {
      dashboard_api.get('/business_types/all_not_custom').then(response => {
        this.businessTypes = response.data;
        this.businessTypes = this.businessTypes.map(businessType => ({
          ...businessType,
          show: false,
        }));
      });
    },
    // TODO Sistema de planes con flow deprecado
    // UpgradePlan(bot) {
    //   this.my_register.bot = bot.id;

    //   this.$router.push({
    //     name: 'dashboard-planes',
    //     query: { seccion: 'mejorar-plan', bot_id: bot.id },
    //   });
    // },
    addACustomAnswer() {
      if (this.selected_bot) {
        this.showEditCustom = true;
        this.selectedCustomFaq = null;
        this.addFaqKey += 1;
      }
    },
    cancel() {
      this.showEditCustom = false;
      this.textName = '';
    },
    faqEdit() {
      this.showEdit = true;
      this.showFaqs = false;
    },
    Back() {
      this.showEdit = false;
      this.showFaqs = true;
    },
    async onSelectedBot(bot) {
      try {
        // consultando listado de respuestas por bot y verificando si tiene activo el rut
        this.currentBusinessType = bot.business_type.name;
        this.selectedBotBusinessType = bot.business_type;
        this.isBotLLM = checkIfBotLLM(this.selectedBotBusinessType.assistant_id);
        this.questionLimit = (await dashboard_api.get(`/frequent_answers/get_by_bot/${bot.id}`)).data.questions;

        // TODO: Armar desde skill base (Servicio Templates)
        if (bot.has_skill_base) {
          let frequent_answers = (
            await this.$store.dispatch(
              'dtClientNodesModule/getAnswersCustom',
              bot.id,
            )
          ).custom_nodes;
          this.customFaqs = await Promise.all(
            frequent_answers.map(async customFaq => ({
              intent: customFaq.intent_ui_name,
              intentName: customFaq.node_name,
              disabled: !customFaq.status,
              dialog_node: customFaq.node_id,
              watson_responses: customFaq.output && Object.keys(customFaq.output).length > 0
                  ? await Promise.all(
                    customFaq.output.generic.map(async el =>
                      el.response_type === 'text'
                        ? {
                            text: el.values.length > 0 ? el.values[0]?.text : '',
                            response_type: 'text',
                          }
                        : el.response_type === 'option'
                          ? {
                            title: el.title || '',
                            response_type: 'option',
                            options: await Promise.all(
                              el.options.map(async option => {
                                let answer;
                                const optionType = option.value.input.text.includes(
                                  'http',
                                  )
                                  ? 'url'
                                  : 'text';
                                if (optionType == 'text') {
                                  let answerNode = await this.$store.dispatch(
                                                  'dtClientNodesModule/listByIntentName',
                                                  {
                                                    intentName: "BOTONCUSTOM_" + option.value.input.text.slice(6,),
                                                    botId: bot.id,
                                                    template: this.skill_template.value,
                                                    parent: customFaq.dialog_node
                                                  }
                                                  );
                                  if (answerNode.output && answerNode.output.generic && answerNode.output.generic.length > 0 && answerNode.output.generic[0].values && answerNode.output.generic[0].values.length > 0) {
                                    answer = answerNode.output?.generic[0]?.values[0]?.text;
                                  }
                                }
                                return {
                                  label: option.label,
                                  responsesOnClick: optionType === 'url'
                                    ? []
                                    : [
                                      {
                                        response_type: "text",
                                        text: answer
                                      }
                                    ],
                                  type: optionType,
                                  value: {
                                    input: {
                                      text: option.value.input.text
                                    },
                                  }
                                };
                              })
                            )
                          }
                        : el,
                    ))
                  : [],
              is_custom: true,
              training_phrases: customFaq.intent_examples,
            })
          ));
          this.checkPlanLimits();
          console.log('customFaqs->',this.customFaqs);
        } else {
          let frequent_answers = (await dashboard_api.get(
            `/frequent_answers/by_bot/${bot.id}`,
          )).data;
          if (bot && bot.business_type && bot.business_type.show === true) {
            if (frequent_answers.length > 0) {
              const promisesResponses = await Promise.all([
                dashboard_api.get(`/bot/showedit/${bot.id}`),
              ]);
              // Info sobre límite de preguntas personalizadas
              this.actualQuestions = frequent_answers;
              this.customFaqs = frequent_answers.filter(el => el.is_custom); // filtrando preguntas custom

              this.checkPlanLimits();
              this.botInfo = promisesResponses[0]?.data;
              // asignando dinamiamente a tarjetas de respuesta
              this.filteredFaqs = JSON.parse(JSON.stringify(this.faqs));
              for (const response of frequent_answers) {
                const index = this.faqs.findIndex(
                  faq => faq.intent === response.intent,
                );
                if (index > -1) {
                  this.filteredFaqs[index].active = !response.disabled;
                  this.filteredFaqs[index].id = response.id;
                  this.filteredFaqs[index].response = response.response;
                  this.filteredFaqs[index].bot_id = response.bot.id;
                }
              }
            }
          } else {
            // mostrar FAQs corporate con API Watson Assistant
            const skill_id = bot.business_type.skill_id;
            const api_key = bot.business_type.api_key;
            const promisesResponses = await Promise.all([
              axios.get(
                `${environment.socket_url}/assistantV1/get-formatted-faqs?skill_id=${skill_id}&api_key=${api_key}&assistant_id=${bot.business_type.assistant_id}`,
              ), // trae todas las preguntas faqs
            ]);
            this.customFaqs = promisesResponses[0]?.data.payload.faqs;
            this.isSkillBase =
              (this.customFaqs &&
                this.customFaqs.length > 0 &&
                this.customFaqs[0]?.intentName.includes('FAQ.')) ||
              this.customFaqs[0]?.intentName.includes('CUSTOM.');
            this.parent = promisesResponses[0]?.data.payload.parent; // id del folder FAQ padre en Watson Assistant
          }
        }
        for (const customFaq of this.customFaqs) {
          customFaq.active = !customFaq.disabled;
        }
      } catch (error) {
        console.log('Ups', error);
      } finally {
        this.loading = false;
        this.addACustomAnswer();
      }
    },
    async save(faq) {
      // Al clickear el switch no cambie a vista editar la pregunta predefinida.
      this.showEdit = false;
      this.showFaqs = true;

      const faqToDB = JSON.parse(JSON.stringify(faq));
      faqToDB.disabled = !faqToDB.active; // cambiando valor active a disabled
      delete faqToDB.active;
      delete faqToDB.title;
      delete faqToDB.bot;
      faqToDB.bot_id = this.selected_bot.id; // asignando el bot id
      if (faqToDB.id) {
        // actualizando
        this.updateChildActiveSwitch(faq['active']);
        await dashboard_api.put(`/frequent_answers/${faqToDB.id}`, {
          frequent_answer: faqToDB,
        });
      } else {
        // creando registro
        const faqCreated = (
          await dashboard_api.post('/frequent_answers', {
            frequent_answer: faqToDB,
          })
        ).data;
        faq.active = !faqCreated.disabled;
        faq.id = faqCreated.id;
        faq.response = faqCreated.response;
        faq.bot_id = faqCreated.bot.id;
      }
      this.$swal({
        icon: 'success',
        title: 'Pregunta actualizada',
        timer: 1000,
        showConfirmButton: false,
      });
    },
    async saveCorporateFaq(faq) {
      // actualizar mediante API en Watson Assistant
      faq.disabled = !faq['active'];
      this.updateChildActiveSwitch(faq['active']);
      await axios.put(
        `${environment.socket_url}/assistantV1/faq/${faq.intentName}`,
        {
          assistant_id: this.selectedBotBusinessType.assistant_id,
          api_key: this.selectedBotBusinessType.api_key,
          previousIntentName: faq.intent,
          skill_id: this.selectedBotBusinessType.skill_id,
          faq,
        },
      );
      this.$swal({
        icon: 'success',
        title: 'Pregunta actualizada',
        timer: 1000,
        showConfirmButton: false,
      });
    },
    async saveSkillBaseFaq(customFaq) {
      // console.log('Actualizando faq base');
      let payloadUpdateCustomInteraction = {
        bot_id: this.selected_bot.id,
        node_id: customFaq.dialog_node,
        intent_name: customFaq.intentName,
        status: customFaq.active
      };
      await this.$store.dispatch(
        'dtClientNodesModule/updateCustomInteraction',
        payloadUpdateCustomInteraction
      );
      this.$swal({
        icon: 'success',
        title: 'Pregunta actualizada',
        timer: 4000,
        text: "Actualiza tu chatbot para publicar los cambios.",
        showConfirmButton: false,
      });
    },
    updateChildActiveSwitch(status) {
      if (this.$refs.addFaq) {
        this.$refs.addFaq.updateActiveSwitch(status);
      }
    },
    saveRutSelection(botInfo) {
      if (!this.selected_bot) return; // si no hay bot seleccionado, saltarse esto
      if (!botInfo.bot_logo_url) {
        botInfo.bot_logo_url =
          'https://acomer-images.s3.us-east-2.amazonaws.com/1608584780Rodrigo';
      }
      dashboard_api
        .put(`/bot/${botInfo.id}`, {
          chatbot: botInfo,
        })
        .then(response => {
          this.loading = false;
          this.$swal({
            icon: 'success',
            title: 'El chatbot se actualizó exitosamente',
            timer: 1000,
            showConfirmButton: false,
          });
        })
        .catch(err => console.log(err));
    },
    async deleteCustomFaq(index, customFaq) {
      console.log("customFaq.intentName->", customFaq);
      try {
        // eliminando intent en Watson Assistant
        this.$swal({
          title: '¿Estás seguro?',
          text: 'Estás a punto de eliminar este FAQ',
          icon: 'warning',
          showCancelButton: true,
          buttons: {
            cancel: {
              text: 'Cancelar',
              value: false,
              visible: true,
              className: '',
              closeModal: true,
            },
            confirm: {
              text: 'Confirmar',
              value: true,
              visible: true,
              className: '',
              closeModal: true,
            },
          },
        }).then(async result => {
          if (result.isConfirmed) {
            // se verifica si es bot con skill base o de plan mensual o corporate
            if (this.selected_bot.has_skill_base) {
              // console.log("ELIMINAAAAAAAAAAAAR: ", customFaq);
                await Promise.all([
                  await this.$store.dispatch('dtClientIntentsModule/deleteCustomIntent',
                    {
                      bot_id: this.selected_bot.id,
                      intent: customFaq.intentName
                    }
                  ),
                  await this.$store.dispatch('dtClientNodesModule/deleteCustomNode',
                    {
                      bot_id: this.selected_bot.id,
                      node: customFaq.dialog_node
                    }
                  )
              ]);
            }
            if (this.selectedBotBusinessType.show === true && !this.selected_bot.has_skill_base) {
              // plan mensual
              // eliminando de watson Assistant con API v1
              await axios.delete(
                `${environment.socket_url}/assistantV1/intents/${this.selected_bot.id}/${customFaq.intent}`,
              );
              // eliminando de bd
              await dashboard_api.delete(`/frequent_answers/${customFaq.id}`);
            }

            if (this.selectedBotBusinessType.show !== true && !this.selected_bot.has_skill_base) {
              // eliminando de watson Assistant con API v1
              await axios.delete(`${environment.socket_url}/assistantV1/faq`, {
                data: {
                  skill_id: this.selectedBotBusinessType.skill_id,
                  api_key: this.selectedBotBusinessType.api_key,
                  assistant_id: this.selectedBotBusinessType.assistant_id,
                  dialogNode: customFaq.dialog_node,
                  intentName: customFaq.intentName,
                },
              });
            }
            this.customFaqs.splice(index, 1);
            this.$swal({
              icon: 'success',
              title: 'Pregunta eliminada',
              showConfirmButton: false,
              timer: 1000,
            });
            this.checkPlanLimits();
          }
        });
        this.addACustomAnswer();
      } catch (error) {
        console.log(error);
      }
    },

    createdCustomFaq(faq) {
      this.customFaqs.push(faq);
      this.selectedCustomFaq = faq; // agregando al prop
      this.checkPlanLimits(); // despues del save
    },
    updatedCustomFaq({ previousIntentName, faq } = {}) {
      let index = this.customFaqs.findIndex(
        el => el.intent === previousIntentName,
      );
      // actualizando estado active
      faq['active'] = !faq.disabled;
      this.customFaqs.splice(index, 1, faq);

      this.selectedCustomFaq = faq; // agregando al prop
    },
    /**
     * @description Recibe el state true/false activado desactivado
     */
    activatedCustomFaq({ index, state }) {
      this.customFaqs[index].active = state;
    },
    // Esto controla si se muestra el boton de mojorar plan
    checkPlanLimits() {
      console.log("questionLimit->", this.questionLimit);

      this.messageLimit = this.customFaqs.length >= this.questionLimit;
      // this.billPlan.custom_questions;
    },
  },
};
</script>

<style lang="scss">
.preguntas {
  .switch {
    position: relative !important;
    margin: 0;
    z-index: 10 !important;
    right: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.preguntas {
  width: 100%;

  .custom-col {
    width: 90%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }
  .card {
    position: relative;
    padding: 1rem;
    margin: auto auto 1rem;
    width: 100%;
    z-index: 0;

    &__box {
      padding: 0.5rem;
    }
  }
  .custom-card {
    width: calc(100% - 0.5rem);
    margin: auto 0 1rem;
  }
  .hero_box {
    padding-top: 0;
    margin: 0 0 2rem;
  }
  .scroll_box {
    max-height: 650px;
    padding: 0 5px 0.25rem 0 !important;
    overflow-y: scroll;

    @media (max-width: 1600px) {
      max-height: 490px;
    }
  }
  .no_textarea {
    border-radius: 0.5rem;
    background-color: #f2f2f2;
    box-shadow: none;
    border: none;
    padding: 1.25rem;
    margin: 1rem auto;
    resize: none;

    &:hover {
      color: #181818;
    }
  }

  .slice_scroll {
    border-bottom: 1px solid #e8e8e8;
  }
  .input_search-lg {
    width: calc(100% - 0.5rem);
    margin: 0.5rem 0 2rem;
  }
  .p-absolute {
    right: 1rem;
    z-index: 10;
    position: absolute;
  }
  .hover_edit {
    padding-right: 1.5rem;

    &:hover {
      background-image: url('/img/icons/12-edit.svg');
      background-repeat: no-repeat, repeat;
      background-position: right 0 top 50%, 0 0;
      background-size: 1rem 100%;
    }
  }
  .custom-left,
  .custom-right {
    width: 120px;
  }
}
</style>

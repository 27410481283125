var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{key:_vm.selected_bot.id,class:{
    databot_live: true,
    dlive_item3: _vm.show_client_info,
  }},[_c('div',{class:{
      'item item-1 pb-0': true,
      hidden: _vm.showMobileViewChat || _vm.show_client_info,
      showMobileMode: !_vm.showMobileViewChat && !_vm.show_client_info,
    }},[_c('ChatCards',{attrs:{"conversations":_vm.searchConversations,"tags":_vm.tags,"lead":_vm.lead,"isLoadingConversations":_vm.isLoadingConversations,"toArchive":_vm.toArchive,"itemsPerPage":_vm.itemsPerPage,"page":_vm.page,"pages":_vm.pages},on:{"onSelectedConversation":_vm.selectConversation,"onSelectedState":function($event){return _vm.initialize()},"onAddCategoryFilter":_vm.onAddCategoryFilter,"sendShowArchived":function($event){_vm.showArchived = $event},"onSearchChat":function($event){return _vm.onSearchChat($event, 1)},"changePage":function($event){_vm.page = $event},"changeItemsPerPage":function($event){_vm.itemsPerPage = $event},"sendToArchive":function($event){_vm.toArchive = !_vm.toArchive},"closeViewChat":function($event){_vm.selectedConversation = null}}})],1),_c('div',{class:{
      'item item-2 fadeItemMobile': true,
      hidden: !_vm.showMobileViewChat,
      showMobileMode: _vm.showMobileViewChat,
    }},[_c('ViewChat',{attrs:{"isLoadingMessages":_vm.isLoadingMessages,"modal":_vm.selectedConversation,"lead":_vm.lead,"selectedChat":_vm.selectedConversation,"messages":_vm.$store.state.conversationsModule.messages,"sessions":_vm.sessions},on:{"sendConnectAgent":function($event){$event
          ? _vm.connectAgent(_vm.selectedConversation)
          : _vm.disconnectAgent(_vm.selectedConversation)},"sendMessage":function($event){return _vm.sendMessage($event, 'Agente', 'text')},"sendMessageFile":function($event){return _vm.sendMessage('', 'Agente', $event.type, { url: $event.url })},"sendMessageAudio":function($event){return _vm.sendMessage('', 'Agente', 'audio', { url: $event.url })},"sendDeriveAgent":function($event){_vm.showDeriveAgents = $event},"closeMobile":function($event){_vm.showMobileViewChat = false},"sendToArchive":function($event){_vm.toArchive = !_vm.toArchive},"sendEndIntervention":function($event){_vm.showEndIntervention = $event}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_client_info),expression:"show_client_info"}],class:{
      'item item-3 fadeItemMobile': true,
      hidden: !_vm.show_client_info,
      showMobileMode: _vm.show_client_info,
    }},[_c('InfoChat',{attrs:{"modal":_vm.lead,"leadId":_vm.lead.id,"isDatabotLive3":true,"conversation":_vm.selectedConversation,"files":_vm.sessions.map(obj => obj.session_files).flat()},on:{"sendDeriveAgent":function($event){_vm.showDeriveAgents = $event}}})],1),(_vm.showArchived)?_c('DesarchivarConversacion',{attrs:{"showModal":_vm.showArchived},on:{"closeModal":function($event){_vm.showArchived = false},"updateChats":_vm.updateChats}}):_vm._e(),_c('DerivarAgente',{attrs:{"agents":_vm.agents,"showModal":_vm.showDeriveAgents},on:{"closeModal":function($event){_vm.showDeriveAgents = false},"derivar":_vm.shuntAgent}}),_c('EndIntervention',{attrs:{"showModal":_vm.showEndIntervention},on:{"closeModal":function($event){_vm.showEndIntervention = false},"endIntervention":_vm.endIntervention}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <Modal class="modal-2" ancho="600" alto="500" :show="showModal" @close="$emit('closeModal', false)">
    <template slot="header">
      <h3></h3>
    </template>
    <template slot="content">
      <section class="derivar-agente">
        <div class="col-lg-12">
          <h3 class="mb-2">Terminar Intervención</h3>
          <div class="row justify-content-between">
            <div class="col-md-8 pl-0">
              <p>
                Indicanos un detalle.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center m-auto">
            <div class="col-7">
              <label for="reason">Indica el motivo <span class="text_sm d-flex justify-content-start">a modo de título</span></label>
              <input class="w-100 mb-1" v-model="reason" id="reason" />
              <span class="text_sm d-flex justify-content-start">
                <a @click="reason = 'Asesoria'" style="border: 1px solid #C4C4C4; border-radius: 5px; padding: 1px 5px; margin-right: 5px">Asesoría</a>
                <a @click="reason = 'Solicitud'" style="border: 1px solid #C4C4C4; border-radius: 5px; padding: 1px 5px; margin-right: 5px">Solicitud</a></span>

              <label class="mt-4" for="">Selecciona el resultado</label>
              <select class="w-100 mb-4" v-model="result" name="" id="">
                <option
                  v-for="(result, index) in results"
                  :key="index"
                  :value="result.id"
                >{{ capitalize(result.name) }}</option>
              </select>

              <label for="">Indica la razón</label>
              <textarea class="w-100 mb-1" v-model="comment" name="" id="" cols="10" rows="3" maxlength="200" />
              <span class="text_sm d-flex justify-content-end">{{ comment.length }} / 200</span>
            </div>
          </div>
          <div v-if="showConfirmButton" class="row justify-content-center my-4">
            <button @click="save" class="btn-base m-auto">Derivar conversación</button>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import { capitalizeFirstLetter } from "@/utils/utils.js";

export default {
  props: ["agents", "showModal"],

  data() {
    return {
      reason: "",
      result: 1,
      comment: "",
      showAgentsSelect: false,
      results: [
        {
          id: 1,
          name: 'aprobada',
        },
        {
          id: 2,
          name: 'rechazada',
        },
        {
          id: 3,
          name: 'pendiente',
        },
      ]
    };
  },

  computed: {
    ...mapState(["selected_bot", "user"]),

    getOwnerUser() {
      return this.agents.find(el => !el.category);
    },
    showConfirmButton() {
      if(!this.reason || !this.comment) return false
      else return true
    }
  },

  watch: {
    showModal(val) {
      if (!val) {
        this.resetInfo();
      }
    },
  },

  methods: {
    save() {
      this.$emit('endIntervention', this.reason, this.comment, this.result);
      this.resetInfo();
      this.$emit('closeModal', false);
    },

    resetInfo() {
      this.reason = "";
      this.comment = "";
      this.result = 1;
    },

    capitalize(val) {
      return capitalizeFirstLetter(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.derivar-agente {
  padding: 0 1rem 2rem;
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;
  .info_box {
    text-align: center;
    font-size: 12px;
    padding: 2rem;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .xs {
    width: fit-content;
  }
}
</style>

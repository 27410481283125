<template>
  <section class="edit-agente">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-5">
            <div class="card__box">
              <div class="mb-2">
                <router-link class="btn-text" to="/agentes"
                  >&lt;- Agentes</router-link>
              </div>
              <h2 class="mb-4">Editar agente</h2>
              <template v-if="userbot.user">
                <label for="Nombre">Nombre</label>
                <input
                  placeholder="Ingresa un nombre"
                  v-model="userbot.user.name"
                  disabled
                />
                <label for="Email">Correo</label>
                <input
                  placeholder="nombre@empresa.cl"
                  v-model="userbot.user.email"
                  disabled
                />
                <label for="Teléfono">Teléfono</label>
                <input
                  placeholder="9 1234 5678"
                  v-model="userbot.user.phone"
                  disabled
                />
                <div class="form-row mt-4">
                  <div class="col-6 pl-2">
                    <label for="category">Área asignada</label>
                    <label
                      v-for="(item, index) in categories"
                      :key="index"
                      class="label-category"
                    >
                      <input
                        type="checkbox"
                        :id="item.name"
                        :value="item.name"
                        :disabled="category.includes(item.name) && category == allCategories"
                        v-model="category"
                      />
                      <span>{{ capitalize(item.name) }}</span>
                    </label>
                    <label for="all" class="label-category">
                      <input type="checkbox" :value="allCategories" @change="setAllCategories" id="all">
                      <span>Seleccionar todas</span>
                    </label>
                  </div>
                  <div class="col-6 pr-2">
                    <label for="mostrarrole">Rol asignado</label>
                    <select
                      v-if="model"
                      name="mostrarrole"
                      v-model="model.rol_id"
                      class="capitalize"
                    >
                      <option value disabled>Seleccionar</option>
                      <option
                        v-for="role in roles"
                        :value="role.id"
                        :key="role.id"
                        >{{ role.name }}</option
                      >
                    </select>
                  </div>
                </div>
                <!-- <div class="d-flex">
                  <div class="field buefy col-6 p-0 pr-2">
                    <div class="info_box my-4">
                      <div class="d-flex justify-content-between">
                        <label>Asignación automática</label>
                        <b-switch v-model="automaticAssignments" />
                      </div>
                      <p class="info">
                        Siempre recibir las conversaciones dependiendo del área
                        asignada
                      </p>
                    </div>
                  </div>
                  <div class="field buefy col-6 p-0 pl-2">
                    <div class="info_box my-4">
                      <div class="d-flex justify-content-between">
                        <label>
                          Derivar conversación
                        </label>
                        <b-switch v-model="assignments" />
                      </div>
                      <p class="info">
                        Este agente podrá derivar la conversación a otro
                      </p>
                    </div>
                  </div>
                </div> -->
              </template>
              <button @click="saveEditRole" class="btn btn-base my-5" :disabled="view_permissions.edit != true">
                Guardar
                <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
              </button>
            </div>
          </div>
          <div class="col-md-4 mt-4">
            <label>Foto de perfil</label>
            <div>
              <div v-if="userbot.user" class="img_box">
                <img
                  :src="
                    userbot.user.image
                      ? userbot.user.image
                      : '/img/brand/0-perfil-agente.png'
                  "
                  alt="Foto de perfil"
                />
              </div>
            </div>
            <div class="input-img">
              <input
                type="file"
                @change="processFile($event)"
                id="input-file"
                :disabled="view_permissions.edit != true"
              />
              <p class="text__warning">
                La imagen no puede superar 1 MB de peso. Preferible formato
                cuadrado, 1:1 o similar sin fondo transparente
              </p>
            </div>
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import '../../assets/buefy_class.scss';
import { mapState, mapMutations } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import { capitalizeFirstLetter } from '@/utils/utils.js';
import { FadeTransition } from "vue2-transitions";
import axios from 'axios';
import environment from '../../environment';

export default {
  components: {
    FadeTransition
  },
  data() {
    return {
      userbot: [],
      model: {
        rol_id: '',
        foto_url: '',
      },
      error: '',
      loading: true,
      automaticAssignments: false,
      assignments: false,
      category: [],
      categories: [
        {
          id: 1,
          name: 'ventas',
        },
        {
          id: 2,
          name: 'facturación',
        },
        {
          id: 3,
          name: 'post-venta',
        },
        {
          id: 4,
          name: 'soporte',
        },
        {
          id: 5,
          name: 'general'
        }
      ],
      imageAvatar: null,
    };
  },

  methods: {
    ...mapMutations(['changeImage']),

    // Al seleccionar General se deseleccionan los otros campos
    setAllCategories() {
      if (this.category == this.allCategories) this.category = [];
      else this.category = this.allCategories;
    },
    async getUserBot() {
      const self = this;
      self.userbot = (
        await dashboard_api.get(`/userbot/show/${self.$route.params.id}`)
      ).data;
      console.log("self.userbot->", self.userbot);
      self.model.rol_id = self.userbot.role_id;
      self.category = self.userbot.category
      self.assignments = self.userbot.user.assignments;
      self.automaticAssignments = self.userbot.user.automatic_assignments;
      self.loading = false;
    },
    formDataBody() {
      const data = new FormData();
      data.append('agent[user_id]', this.userbot.user_id);
      data.append('agent[name]', this.userbot.user.name);
      data.append('user_avatar', this.imageAvatar);
      data.append('agent[assignments]', this.assignments);
      data.append('agent[automatic_assignments]', this.automaticAssignments);

      return data;
    },

    // Update del rol del agente
    saveEditRole() {
      const self = this;
      self.error = '';

      self.$swal({
        icon: 'info',
        title: 'Estamos guardando los cambios...',
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      self.$swal.showLoading();
      const formatData = self.formDataBody();
      if (self.selected_bot.id === '') {
        dashboard_api.put('/relief/usereditphoto/', formatData).then(result => {
          console.log('primero', result);
          self.$swal({
            icon: 'success',
            title: 'Se han guardado los cambios',
            showConfirmButton: false,
            timer: 1000,
          });
        });
      } else if (self.model.rol_id === '') {
        self.$swal({
          icon: 'error',
          title: 'El rol no es opcional',
        });
      } else {
        dashboard_api
          .put(`/userbot/userbotedit/${self.userbot.id}`, {
            user_id: self.userbot.user_id,
            bot_id: self.selected_bot.id,
            role_id: self.model.rol_id,
            category: self.category,
          })
          .then(result1 => {
            // if (self.model.foto_url != '') {
            dashboard_api
              .put(`/agents/${self.userbot.user_id}`, formatData, {
                headers: { Accept: 'application/vnd.v2+json' },
              })
              .then(result2 => {
                const selected_rol = this.roles.find(el => el.id == self.model.rol_id);
                axios.put(`${environment.url_redis}/update_counter_for_agent`, {
                  "agent_id": result1.data.user_id,
                  "categories": result1.data.category,
                  "role": selected_rol.name,
                  "bot_id": this.selected_bot.id
                }).then(res3 => {
                  self.$swal({
                    icon: 'success',
                    title: 'Se han guardado los cambios',
                    showConfirmButton: false,
                    timer: 1000,
                  });
                }).catch(err => console.log("update_counter_for_agent",err))
              })
              .catch(err => {
                console.log(err);
                self.$swal({
                  icon: 'error',
                  title: 'Error al editar el rol del agente',
                });
              });
            // }
            // if (resp.statusText == 'OK') {
            //   console.log('respuesta rol editado');
            //   self.$swal({
            //     icon: 'success',
            //     title: 'Rol del agente Editado',
            //     text: '¡Rol del Agente Editado Correctamente!',
            //   });
            // }
          })
          .catch(err => {
            if (err == 'Error: Not Acceptable') {
              console.log('cuatro');
              self.$swal({
                icon: 'error',
                title:
                  'El agente ya esta asignado a este bot o ya tiene este mismo rol',
              });
            } else {
              console.log('cinco');
              self.$swal({
                icon: 'error',
                title: 'Error al editar el rol del agente',
              });
            }
          });
      }
    },

    processFile(event) {
      const self = this;

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      self.imageAvatar = event.target.files[0];
      reader.onload = function () {
        // console.log(reader.result);
        self.model.foto_url = reader.result;
        self.userbot.user.image = reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },

    capitalize(val) {
      return capitalizeFirstLetter(val);
    }
  },

  watch: {
    selected_bot(val) {
      this.getUserBot();
    },
  },

  computed: {
    ...mapState(['user', 'selected_bot', 'view_permissions', 'all_roles']),

    roles() {
      if (this.all_roles?.length > 0) {
        return this.all_roles.filter(el => el.name != 'propietario');
      }
    },
    allCategories() {
      return this.categories.map(el => el.name);
    }
  },

  created() {
    window.scrollTo(0, 0);
    this.getUserBot();
  },
};
</script>

<style lang="scss" scoped>
.edit-agente {
  .card__box {
    padding: 0 2rem;
  }
  input {
    &[type='file']::-webkit-file-upload-button {
      align-items: center;
      background: #b2b2b2;
      color: #fff;
      border: none;
      border-radius: 3px;
      font-size: 12px;
    }
  }
  select {
    width: 100%;

    @media (max-width: 1024px) {
      margin: 0 auto;
    }
  }
  .img_box {
    width: 300px;
    height: 300px;
    flex-grow: inherit !important;
    margin-bottom: 1rem;
    
    img {
      object-fit: cover;
      object-position: center;
      border: 1px solid #f2f2f2;
      height: 100%;
      width: 300px;
      border-radius: 1rem;
    }
  }
  i {
    margin-right: 0.5rem;
    opacity: 0.6;
  }
  .text__warning {
    font-size: 12px;
  }
}
.input-img {
  width: 300px;
}
.label-category {
  font-weight: normal;
  width: 100%;
  display: flex;

  input {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  span {
    margin-left: 5px;
  }
}
</style>

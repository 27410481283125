<template>
  <section class="preguntas">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container">
        <FadeTransition :duration="200">
          <div class="row justify-content-center">
            <div class="col-lg-6 mt-6 mb-5">
              <div class="label-box mb-3">
                <h2 class="">Respuestas rápidas</h2>
                <FadeTransition :duration="200" v-show="selected.title">
                  <button
                    v-if="view_permissions.add == true"
                    @click="addAnswer"
                    class="text_sm btn-select static m-0"
                  >
                    + Crear nueva
                  </button>
                </FadeTransition>
              </div>
              <p class="mb-5">
                Aquí podrás crear y administrar respuestas rápidas para ser usadas en las conversaciones con tus clientes desde databot live.
              </p>
              <FadeTransition :duration="200" v-show="show_fields">
                <section>
                  <div class="label-box mb-2">
                    <label class="mb-0" for="">Título</label>
                    <span class="text_sm">{{ new_title.length }} / 100</span>
                  </div>
                  <input class="mb-4" type="text" placeholder="Título de la respuesta" maxlength="100" v-model="new_title" />
                  <label for="">Mensaje</label>
                  <textarea type="text" rows="4" v-model="new_body" placeholder="Escribe el mensaje aquí" />
                  <div class="row justify-content-between my-5">
                    <button
                      class="btn btn-base"
                      @click="save"
                      :disabled="save_loading"
                    >
                      Guardar
                      <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
                    </button>
                    <button
                      class="btn btn-base outline"
                      @click="reset"
                      >Cancelar
                    </button>
                  </div>
                </section>
              </FadeTransition>
              <div
                v-if="!show_fields"
                class="info_box py-5 bg_grey d-flex flex-column align-items-center justify-content-center mb-4"
                @click="addAnswer"
              >
                <img class="mb-4" src="/img/icons/50-i-answers.svg" alt="">
                <button class="btn-select static">+ Crear respuesta</button>
              </div>
              <router-link class="btn-text" to="/dashboard">&lt;- Volver</router-link>
            </div>
            <div class="col-lg-4 justify-content-end d-flex mb-5">
              <div class="custom-col mt-6">
                <div class="label-box mb-4">
                  <label class="mb-0">Actuales</label>
                  <input
                    v-if="answers.length > 0"
                    type="search"
                    class="input_search-lg mb-0"
                    placeholder="Buscar preguntas"
                    v-model="search_item"
                  />
                </div>
                <SpinnerLoading v-if="loading" />
                <div
                  v-else-if="searchedAnswers.length > 0"
                  :class="{ scroll_box: true, slice_scroll: answers.length > 8 }"
                >
                  <div
                    v-for="(answer, index) in searchedAnswers"
                    :key="index"
                    :class="{ 'card card-click btn-show-sm': true, selected: selected.title == answer.title }"
                    @click="editAnswer(answer)"
                  >
                    <div class="d-flex justify-content-between">
                      <div class="col-lg-8 pl-0">
                        <label class="mb-0">{{ answer.title }}</label>
                      </div>
                      <div class="col-lg-2 pr-0">
                        <span
                          @click.stop="deleteItem(index, answer)"
                          class="btn-dash btn-dash-sm btn-hover"
                          ><i class="fas fa-trash-alt m-0" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="info_box text-center bg_grey">
                  <span class="text_sm mb-0">Sin respuestas</span>
                </div>
                <div v-if="answers.length > 0" class="row justify-content-end my-2">
                  <p class="text_sm pr-3 mb-0">
                    {{ searchedAnswers.length }}
                    {{
                      searchedAnswers.length == 1 ? 'Respuesta' : 'Respuestas'
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </FadeTransition>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && loading == false"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import axios from 'axios';
import dashboard_api from '@/dashboard_api.js';
import environment from '@/environment';
import '../../assets/buefy_class.scss';
import { FadeTransition } from 'vue2-transitions';
// import AddFaq from './AddFaq';
// import ChangeBusinessType from './ChangeBusinessType';
import { mapState } from 'vuex';

export default {
  components: {
    FadeTransition,
    // AddFaq,
    // ChangeBusinessType,
  },
  data() {
    return {
      answers: [
        // {
        //   title: 'Respuesta 1',
        //   text: 'Este es el mensaje de prueba',
        //   created_at: '23 de sep 2024'
        // }
      ],
      view_permissions: {
        read: true,
        add: true,
        delete: true,
        edit: true,
        export: true,
        'send-wsp': true,
        'shunt-agent': true
      },
      new_title: '',
      new_body: '',
      search_item: '',
      loading: true,
      selected: {},
      selected_index: 0,
      show_fields: false,
      save_loading: false
    };
  },
  async created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(['selected_bot']),

    searchedAnswers() {
      if (!this.search_item) return this.sortedAnswers;

      return this.answers.filter(item => {
        return item.title
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            this.search_item
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
              .trim()
          )
      })
    },
    sortedAnswers() {
      return this.answers.sort((a, b) => b.id - a.id)
    }
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getFastAnswers();
      } 
    } 
  },
  methods: {
    async getFastAnswers() {
      try {
        this.answers = (await dashboard_api.get(`/speed_notes/speed_notes_by_bot/${this.selected_bot.id}`)).data;
        console.log("answers->", this.answers);
      } catch (error) {
        console.log("speed_notes_by_bot-error->", error);
      } finally {
        this.loading = false;
      }
    },
    save() {
      this.save_loading = true;
      this.$swal({
        icon: 'info',
        title: 'Guardando..',
        showConfirmButton: false,
      });
      this.$swal.showLoading();
      try {
        // edit
        if (this.selected.title) {
          dashboard_api
            .put(`/speed_notes/${this.selected.id}`, {
              title: this.new_title.trim(),
              body: this.new_body.trim(),
              bot_id: this.selected_bot.id
            })
            .then(async (resp) => {
              console.log("edit->", resp);
              this.$swal({
                icon: 'success',
                title: 'Respuesta modificada',
                showConfirmButton: true,
              });
              await this.getFastAnswers();
            });
        } else {
          // create
          dashboard_api
            .post(`/speed_notes`, {
              title: this.new_title.trim(),
              body: this.new_body.trim(),
              bot_id: this.selected_bot.id
            })
            .then(async (resp) => {
              console.log("create->", resp);
              this.$swal({
                icon: 'success',
                title: 'Listo',
                showConfirmButton: true,
              });
              await this.getFastAnswers();
            });
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Ocurrió un error',
          text: 'No se pudo guardar, comunícate con nuestro soporte.',
          showConfirmButton: true,
        });
      } finally {
        this.reset();
      }
    },
    deleteItem(index, answer) {
      this.$swal({
          icon: 'warning',
          title: '¿Eliminar respuesta?',
          showCancelButton: true,
          buttons: {
            cancel: {
              text: 'Cancelar',
              value: false,
              visible: true,
              className: '',
              closeModal: true,
            },
            confirm: {
              text: 'Confirmar',
              value: true,
              visible: true,
              className: '',
              closeModal: true,
            },
          },
        }).then(async result => {
          if (result.isConfirmed) {
            dashboard_api
              .delete(`/speed_notes/${answer.id}`)
              .then(async resp => {
                console.log("delete->", resp);

                this.$swal({
                  icon: 'success',
                  title: 'Respuesta eliminada'
                });
                await this.getFastAnswers();
              })
          }})
    },
    addAnswer() {
      this.show_fields = true;
      this.selected = {};
      this.new_title = '';
      this.new_body = '';
    },
    editAnswer(answer) {
      this.show_fields = true;
      this.selected = answer;
      this.new_title = this.selected.title;
      this.new_body = this.selected.body;
    },
    reset() {
      this.show_fields = false;
      this.save_loading = false;
      this.selected = {};
      this.new_title = '';
      this.new_body = '';
      this.search_item = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.preguntas {
  width: 100%;

  .custom-col {
    width: 90%;

    @media (max-width: 1600px) {
      width: 100%;
    }
  }
  .card {
    position: relative;
    padding: 1rem;
    margin: auto auto 1rem;
    width: 100%;
    z-index: 0;

    &__box {
      padding: 0.5rem;
    }
  }
  .custom-card {
    width: calc(100% - 0.5rem);
    margin: auto 0 1rem;
  }
  .hero_box {
    padding-top: 0;
    margin: 0 0 2rem;
  }
  .scroll_box {
    max-height: 650px;
    padding: 0 5px 0.25rem 0 !important;
    overflow-y: scroll;

    @media (max-width: 1600px) {
      max-height: 490px;
    }
  }
  .no_textarea {
    border-radius: 0.5rem;
    background-color: #f2f2f2;
    box-shadow: none;
    border: none;
    padding: 1.25rem;
    margin: 1rem auto;
    resize: none;

    &:hover {
      color: #181818;
    }
  }

  .slice_scroll {
    border-bottom: 1px solid #e8e8e8;
  }
  .input_search-lg {
    width: 60%;
  }
  .p-absolute {
    right: 1rem;
    z-index: 10;
    position: absolute;
  }
  .hover_edit {
    padding-right: 1.5rem;

    &:hover {
      background-image: url('/img/icons/12-edit.svg');
      background-repeat: no-repeat, repeat;
      background-position: right 0 top 50%, 0 0;
      background-size: 1rem 100%;
    }
  }
  .custom-left,
  .custom-right {
    width: 120px;
  }
}
</style>

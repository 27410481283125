<template>
  <section class="mensajes-plantilla">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-11">
            <div class="row my-4 align-items-center justify-content-between">
              <div>
                <h2 class="mb-0">Mensajes de plantilla</h2>
                <p>{{ TemplateMessages || 0 }} Mensajes de plantilla enviados este mes.</p>
              </div>
              <div class="row aling-items-center">
                <span @click="showFilterModal = true" class="btn-filtro">Filtrar fechas</span>
              </div>
            </div>
            <template v-if="searchMessages.length > 0">
              <section class="tabla__clientes buefy">
                <b-table
                  :data="searchMessages"
                  :hoverable="isHoverable"
                  :selected.sync="selectedRow"
                  ref="table"
                  detailed
                  custom-detail-row
                  detail-key="message_name"
                  :key="tableKey"
                >
                  <template slot-scope="props">
                    <b-table-column
                      field="name"
                      label="Nombre plantilla"
                      class="hover-item"
                    >
                      {{ props.row.message_name }}
                      <span class="total-send hover-item px-2">{{
                        props.row.sendTemplate
                      }}</span>
                      <span @click="directExport(props.row.mids)" class="i-btn i-sm i-download total-send hover-item click custom_sm_btn" />
                    </b-table-column>
                    <b-table-column field="agent" label="Enviado por">
                      {{ props.row.authorization }}
                    </b-table-column>
                    <b-table-column field="cost" label="Fecha">
                      {{ $moment(props.row.date).format('DD/MM/YYYY') }}
                    </b-table-column>
                    <b-table-column field="messages" label="Total mensajes">
                      {{ props.row.total_messages }}
                      <span
                        v-show="props.row.showDetailIcon"
                        :class="{
                          'i-btn': true,
                          'i-eye': true,
                          'ml-2': true,
                          active: hoverAction == props.row.message,
                        }"
                        @click="showModalTags(props.row, 0, false)"
                      ></span>
                    </b-table-column>
                    <b-table-column field="sendPersentage" label="En tránsito">
                      <span v-if="props.row.sended.total_sended_true > 0">
                        {{ props.row.sended.total_sended_true }} ({{
                          getPorcent(
                            props.row.total_messages,
                            props.row.sended.total_sended_true,
                          ) + '%'
                        }})
                        <span
                          v-show="props.row.showDetailIcon"
                          :class="{
                            'i-btn': true,
                            'i-eye': true,
                            'ml-2': true,
                            active: hoverAction == props.row.message,
                          }"
                          @click="showModalTags(props.row, 1, false)"
                        ></span>
                      </span>
                      <span v-else>
                        -
                      </span>
                    </b-table-column>
                    <b-table-column field="sendPersentage" label="Recibidos">
                      <span v-if="props.row.received.total_received_true > 0">
                        {{ props.row.received.total_received_true }} ({{
                          getPorcent(
                            props.row.total_messages,
                            props.row.received.total_received_true,
                          )
                        }}%)
                        <span
                          v-show="props.row.showDetailIcon"
                          :class="{
                            'i-btn': true,
                            'i-eye': true,
                            'ml-2': true,
                            active: hoverAction == props.row.message_name,
                          }"
                          @click="showModalTags(props.row, 2, false)"
                        ></span>
                      </span>
                      <span v-else>
                        -
                      </span>
                    </b-table-column>
                    <b-table-column field="sendPersentage" label="Abiertos">
                      <span v-if="props.row.read.total_read_true > 0">
                        {{ props.row.read.total_read_true }} ({{
                          getPorcent(
                            props.row.total_messages,
                            props.row.read.total_read_true,
                          )
                        }}%)
                        <span
                          v-show="props.row.showDetailIcon"
                          :class="{
                            'i-btn': true,
                            'i-eye': true,
                            'ml-2': true,
                            active: hoverAction == props.row.message_name,
                          }"
                          @click="showModalTags(props.row, 3, false)"
                        ></span>
                      </span>
                      <span v-else>
                        -
                      </span>
                    </b-table-column>
                    <b-table-column field="sendPersentage" label="Fallidos">
                      <span v-if="props.row.failed.total_with_failed > 0">
                        {{ props.row.failed.total_with_failed }} ({{
                          getPorcent(
                            props.row.total_messages,
                            props.row.failed.total_with_failed,
                          )
                        }}%)
                        <span
                          v-show="props.row.showDetailIcon"
                          :class="{
                            'i-btn': true,
                            'i-eye': true,
                            'ml-2': true,
                            active: hoverAction == props.row.message_name,
                          }"
                          @click="showModalTags(props.row, 4, false)"
                        ></span>
                      </span>
                      <span v-else>
                        -
                      </span>
                    </b-table-column>
                  </template>
                  <template slot="detail" slot-scope="props">
                    <tr
                      v-for="(item, index) in props.row.resumen"
                      :key="props.row.message_name + index"
                      class="detail-template"
                      @mouseover="hoverAction = item.message_name + index"
                      @mouseleave="hoverAction = false"
                    >
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>
                        {{ item.message_name }}
                      </td>
                      <td>
                        {{ item.authorization }}
                      </td>
                      <td>
                        {{ $moment(item.date).format('DD/MM/YYYY') }}
                      </td>
                      <td>
                        {{ item.total_messages }}
                        <span
                          :class="{
                            acciones: true,
                            active: hoverAction == item.message_name + index,
                          }"
                        >
                          <span
                            :class="{
                              'i-btn': true,
                              'i-eye': true,
                              'ml-2': true,
                              active: hoverAction == item.message_name + index,
                            }"
                            @click="showModalTags(item, 0, true)"
                          ></span>
                        </span>
                      </td>
                      <td>
                        <span v-if="item.sended.total_sended_true > 0">
                          {{ item.sended.total_sended_true }} ({{
                            getPorcent(
                              item.total_messages,
                              item.sended.total_sended_true,
                            ) + '%'
                          }})
                          <span
                            :class="{
                              acciones: true,
                              active: hoverAction == item.message_name + index,
                            }"
                          >
                            <span
                              :class="{
                                'i-btn': true,
                                'i-eye': true,
                                'ml-2': true,
                                active:
                                  hoverAction == item.message_name + index,
                              }"
                              @click="showModalTags(item, 1, true)"
                            ></span>
                          </span>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                      <td>
                        <span v-if="item.received.total_received_true > 0">
                          {{ item.received.total_received_true }} ({{
                            getPorcent(
                              item.total_messages,
                              item.received.total_received_true,
                            )
                          }}%)
                          <span
                            :class="{
                              acciones: true,
                              active: hoverAction == item.message_name + index,
                            }"
                          >
                            <span
                              :class="{
                                'i-btn': true,
                                'i-eye': true,
                                'ml-2': true,
                                active:
                                  hoverAction == item.message_name + index,
                              }"
                              @click="showModalTags(item, 2, true)"
                            ></span>
                          </span>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                      <td>
                        <span v-if="item.read.total_read_true > 0">
                          {{ item.read.total_read_true }} ({{
                            getPorcent(
                              item.total_messages,
                              item.read.total_read_true,
                            )
                          }}%)
                          <span
                            :class="{
                              acciones: true,
                              active: hoverAction == item.message_name + index,
                            }"
                          >
                            <span
                              :class="{
                                'i-btn': true,
                                'i-eye': true,
                                'ml-2': true,
                                active:
                                  hoverAction == item.message_name + index,
                              }"
                              @click="showModalTags(item, 3, true)"
                            ></span>
                          </span>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                      <td>
                        <span v-if="item.failed.total_with_failed > 0">
                          {{ item.failed.total_with_failed }} ({{
                            getPorcent(
                              item.total_messages,
                              item.failed.total_with_failed,
                            )
                          }}%)
                          <span
                            :class="{
                              acciones: true,
                              active: hoverAction == item.message_name + index,
                            }"
                          >
                            <span
                              :class="{
                                'i-btn': true,
                                'i-eye': true,
                                'ml-2': true,
                                active:
                                  hoverAction == item.message_name + index,
                              }"
                              @click="showModalTags(item, 4, true)"
                            ></span>
                          </span>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </td>
                    </tr>
                  </template>
                </b-table>
              </section>
              <div class="row justify-content-between align-items-center my-3">
                <p class="text_sm mb-0">
                  Clientes en esta página: {{ searchMessages.length }}
                </p>
                <select
                  class="select_text mr-0"
                  v-model="itemsPerPage"
                  @change="getData('', '', 1)"
                >
                  <option value="15">15 por página</option>
                  <option value="30">30 por página</option>
                  <option value="50">50 por página</option>
                </select>
              </div>
            </template>
            <template v-else>
              <div class="info_box">
                <img
                  class="icon"
                  src="/img/icons/15-not_found.svg"
                  alt="databot"
                />
                <p class="text-center">No se encontraron mensajes</p>
              </div>
            </template>
            <div class="row justify-content-between my-3">
              <template v-if="searchMessages.length > 0">
                <div v-if="total_pages > 1" class="pagination">
                  <button
                    v-if="actual_page > 1"
                    class="btn-pagination"
                    @click="
                      actual_page = 1;
                      getData('', '', 1);
                    "
                  >
                    1
                  </button>
                  <button
                    v-if="actual_page != 1"
                    class="btn-pagination"
                    @click="
                      actual_page = actual_page - 1;
                      getData('', '', actual_page);
                    "
                  >
                    <i class="fas fa-caret-left" />
                  </button>
                  <button
                    v-if="actual_page > 2"
                    class="btn-pagination"
                    @click="
                      actual_page = actual_page - 1;
                      getData('', '', actual_page);
                    "
                  >
                    {{ actual_page - 1 }}
                  </button>
                  <button
                    class="btn-pagination active_page"
                    @click="
                      actual_page = actual_page;
                      getData('', '', actual_page);
                    "
                  >
                    {{ actual_page }}
                  </button>
                  <button
                    v-if="total_pages != total_pages"
                    class="btn-pagination"
                    @click="
                      actual_page = actual_page + 1;
                      getData('', '', actual_page);
                    "
                  >
                    {{ actual_page + 1 }}
                  </button>
                  <button
                    v-if="actual_page != total_pages"
                    class="btn-pagination"
                    @click="
                      actual_page = actual_page + 1;
                      getData('', '', actual_page);
                    "
                  >
                    <i class="fas fa-caret-right" />
                  </button>
                  <button
                    v-if="actual_page != total_pages"
                    class="btn-pagination"
                    @click="
                      actual_page = total_pages;
                      getData('', '', actual_page);
                    "
                  >
                    {{ total_pages }}
                  </button>
                </div>
              </template>
            </div>
            <!-- <div class="row justify-content-end">
              <button
                class="btn btn-base sm third-outline mr-0"
                @click="showExportaciones = true"
              >
                Exportar datos
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
    <TemplateResumen
      :key="idKey + number"
      :show="showPlantillas"
      :number="number"
      :leads="leadsTemplateResumen"
      :mids="mids"
      @close="showPlantillas = false"
    />
    <DateFilterModal
      :showFilterModal="showFilterModal"
      @closeModal="showFilterModal = false"
      @filterDate="filterDatePush"
    />
    <UnAnswered
      :showUnAnswered="showUnAnswered"
      @closeModal="showUnAnswered = false"
      :un_answered="un_answered"
    />
    <!-- <DataExports
      :showModal="showExportaciones"
      @closeModal="showExportaciones = false"
      @export="exportar"
    /> -->
  </section>
</template>

<script>
import DateFilterModal from './widgetWhatsapp/components/DateFilterModal.vue';
import UnAnswered from './widgetWhatsapp/components/UnAnswered.vue';
import dashboard_api from '@/dashboard_api.js';
import { FadeTransition } from 'vue2-transitions';
import environment from '@/environment';
import TemplateResumen from '@/components/TemplateResumen.vue';
import '@/assets/buefy_class.scss';
import '@/assets/main.scss';
import DataExports from '@/components/builders/DataExports.vue';
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  components: {
    FadeTransition,
    DateFilterModal,
    UnAnswered,
    environment,
    TemplateResumen,
    DataExports
  },
  data() {
    return {
      loading: false,
      showFilterModal: false,
      showUnAnswered: false,
      showExportaciones: false,
      isHoverable: true,
      filter_search: '',
      un_answered: [],
      messages: [],
      selectedRow: {},
      failedNumbers: [],
      actual_page: 1,
      total_pages: 0,
      itemsPerPage: 15,
      date_init: '',
      date_end: '',
      showDetailIcon: true,
      showPlantillas: false,
      number: '',
      hoverAction: false,
      tableKey: 0,
      activeItem: null,
      leadsTemplateResumen: {
        message_name: '',
        date: '',
        mids: [],
      },
      idKey: null,
      TemplateMessages: null,
      mids: []
    };
  },
  created() {
    this.getData('', '', 1);
  },
  computed: {
    ...mapState(['selected_bot', 'user', 'view_permissions']),

    searchMessages() {
      let itemSearch = this.messages;

      if (!this.filter_search) return itemSearch;

      return itemSearch.filter(item => {
        return [item.message_name, item.agent].find(field => {
          return field
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(
              this.filter_search
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .trim(),
            );
        });
      });
    },
  },

  watch: {
    selectedRow(val, oldVal) {
      val.showDetailIcon = true;

      if (oldVal.showDetailIcon) {
        oldVal.showDetailIcon = false;
      }
    },
    leadsTemplateResumen(val) {
      this.leadsTemplateResumen = val;
    },
    selected_bot: {
      immediate: true,
      handler(val) {
        this.MessageCounter(val.id);
      },
    },
  },
  methods: {
    directExport(mids) {
      this.$swal({
        title: '¿Deseas exportar los datos de esta plantilla?',
        text: 'Se enviará un archivo a tu correo',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Exportar',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          const url = environment.url_metrics;
          axios.post(`${url}/export_leads_payroll_messages/export_detail_resume_messages`, {
            bot_id: this.selected_bot.id,
            user_name: localStorage.getItem('name'),
            email: localStorage.getItem('email'),
            mids,
          }).then(response => {
            if (response) {
              this.$swal({
                title: '¡Listo!',
                text: 'El archivo será enviado a tu correo',
                icon: 'success',
                showConfirmButton: true,
                allowOutsideClick: false,
              });
            }
          }).catch(error => {
            console.log('error', error);
            this.$swal({
              title: '¡Error!',
              text: 'Ocurrió un error al exportar los datos',
              icon: 'error',
              showConfirmButton: true,
              allowOutsideClick: false,
            });
          });
        }
      });
    },
    empty() {
      this.date_init = '';
      this.date_end = '';
    },
    decimalFilter(price) {
      let value = parseFloat(price);
      let index = value
        .toFixed(3)
        .toString()
        .split('')
        .findIndex(el => el == '.');
      let filter = value.toFixed(3).slice(index, index + 4);

      return filter.includes('.000') || filter.includes('.0')
        ? value.toFixed(0)
        : filter.includes('00')
        ? value.toFixed(1)
        : value.toFixed(3);
    },
    exportar(init = null, end = null) {
      console.log("event->", init, end);
      const payload = {
        bot_id: this.selected_bot.id,
        user_name: this.user.name,
        email: this.user.email,
      };
      if (init && end) {
        payload.from = init;
        payload.to = end;
      }
      this.$swal({
        title: '¿Deseas exportar los datos?',
        text: 'Se enviará un archivo a tu correo',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Exportar',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          const url = environment.url_metrics;
          axios
            .post(`${url}/export_leads_payroll_messages/export_resume_messages`, payload)
            .then(response => {
              if (response) {
                this.$swal({
                  title: '¡Listo!',
                  text: 'El archivo será enviado a tu correo',
                  icon: 'success',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              }
            })
            .catch(error => {
              console.log('error', error);
              this.$swal({
                title: '¡Error!',
                text: 'Ocurrió un error al exportar los datos',
                icon: 'error',
                showConfirmButton: true,
                allowOutsideClick: false,
              });
            });
        }
      });
    },

    async MessageCounter(botId) {
      try {
        this.TemplateMessages = (
          await dashboard_api.get(
            `/payroll_message_counters/by_bot/360Dialog/${botId}/template_message`,
          )
        ).data.outcome_messages;
        console.log('1-TemplateMessages', this.TemplateMessages);
      } catch (error) {
        console.log("counter error", error);
        this.TemplateMessages = 0;
      }
    },
    onFailedNumbers(failedNumbers) {
      this.failedNumbers = failedNumbers;
    },
    async getUnAnswered() {
      this.un_answered = (
        await dashboard_api.get(
          '/lead_payroll_messages/resume_for_lead_not_answered',
        )
      ).data;
    },
    filterDatePush(date) {
      this.showFilterModal = false;
      this.date_init = date.init;
      this.date_end = date.end;
      this.getData(date.init, date.end);
    },

    async getData(init, end, pageNumber) {
      this.loading = true;
      if (init && end) {
        // desfase horario de servidor
        let dateServer = this.$moment(end).add(1, 'day');

        await dashboard_api
          .get(
            `/lead_payroll_messages/resume_messages?from=${init}&to=${dateServer}?items_per_page=${
              this.itemsPerPage
            }&page=${pageNumber || 1}&bot_id=${this.selected_bot.id}`,
          )
          .then(async resp => {
            let detailResume = await resp.data.data;
            await detailResume.forEach(async (e, index) => {
              let resumenData = await this.getResumeData(
                e.authorization,
                e.payroll_message_id,
              );
              e.resumen = await resumenData;
              resumenData.forEach((el, index) => {
                el.id = el.message_name + '-' + index;
              });
              e.sendTemplate = await resumenData.length;
              e.id = index;
              this.tableKey += 1;
            });
            this.messages = await detailResume;
            this.total_pages = resp.data.pages;
            this.loading = false;
          });
      } else {
        await dashboard_api
          .get(
            `/lead_payroll_messages/resume_messages?items_per_page=${
              this.itemsPerPage
            }&page=${pageNumber || 1}&bot_id=${this.selected_bot.id}`,
          )
          .then(async resp => {
            console.log('res', resp.data.data);
            let detailResume = await resp.data.data;
            await detailResume.forEach(async (e, index) => {
              let resumenData = await this.getResumeData(
                e.authorization,
                e.payroll_message_id,
              );
              e.resumen = await resumenData;
              resumenData.forEach((el, index) => {
                el.id = el.message_name + '-' + index;
              });
              e.sendTemplate = await resumenData.length;
              e.id = index;
              this.tableKey += 1;
            });
            this.messages = await detailResume;
            this.total_pages = resp.data.pages;
            this.loading = false;
          });
      }
      this.getUnAnswered();
    },
    getPorcent(calA, calB) {
      return (this.result = Math.round((calB * 100) / calA));
    },
    getResumeData(agent, payroll) {
      return dashboard_api
        .get(
          `/lead_payroll_messages/detail_resume_messages/${agent}/${payroll}`,
        )
        .then(res => res.data);
    },
    showModalTags(item, number, subitem) {
      this.mids = [];
      switch (number) {
        // ALL
        case 0:
          if (subitem) this.mids = item.mids;
          else {
            item.resumen.map(e => {
              this.mids = this.mids.concat(e.mids);
            });
          }
          break;
        // SENT
        case 1:
          if (subitem) this.mids = item.sended.total_sended_true_mids;
          else {
            item.resumen.map(e => {
              this.mids = this.mids.concat(e.sended.total_sended_true_mids);
            });
          }
          break;
        // RECEIVED
        case 2:
          if (subitem) this.mids = item.received.total_received_true_mids;
          else {
            item.resumen.map(e => {
              this.mids = this.mids.concat(e.received.total_received_true_mids);
            });
          }
          break;
        // READ
        case 3:
          if (subitem) this.mids = item.read.total_read_true_mids;
          else {
            item.resumen.map(e => {
              this.mids = this.mids.concat(e.read.total_read_true_mids);
            });
          }
          break;
        // FAILED
        case 4:
          if (subitem) this.mids = item.failed.total_with_failed_mids;
          else {
            item.resumen.map(e => {
              this.mids = this.mids.concat(e.failed.total_with_failed_mids);
            });
          }
          break;
        default:
          break;
      }
      this.showPlantillas = true;
      this.activeItem = item.id;
      this.number = number;
      this.leadsTemplateResumen = item;
      this.idKey = item.id;
    },
  },
};
</script>

<style lang="scss">
.mensajes-plantilla {
  .buefy {
    .table-wrapper {
      @media (max-width: 768px) {
        overflow-x: inherit;
      }
    }
    .b-table .table {
      border: 0px solid transparent;

      th {
        margin-left: 0px;
      }
      th:nth-child(5) {
        padding-right: 1.5rem;
      }
      th:nth-child(6) {
        background-image: url('/img/icons/widgetWhatsapp/25-en-transito.svg');
        background-repeat: no-repeat, repeat;
        background-size: 0.6rem 100%;
        padding: 0 1.2rem;
        background-position: left;
        margin-left: -2px;
      }
      th:nth-child(7) {
        background-image: url('/img/icons/widgetWhatsapp/25.2-recibido.svg');
        background-repeat: no-repeat, repeat;
        background-size: 0.75rem 100%;
        padding: 0 1.2rem;
        background-position: left;
        margin-left: -2px;
      }
      th:nth-child(8) {
        background-image: url('/img/icons/widgetWhatsapp/25.3-abierto.svg');
        background-repeat: no-repeat, repeat;
        background-size: 0.75rem 100%;
        padding: 0 1.2rem;
        background-position: left;
        margin-left: -2px;
      }
      th:nth-child(9) {
        background-image: url('/img/icons/widgetWhatsapp/25.4-fails.svg');
        background-repeat: no-repeat, repeat;
        background-size: 0.7rem 100%;
        padding: 0 1.2rem;
        background-position: left;
        margin-left: -2px;
      }
      th.th-wrap:nth-child(n + 6) {
        margin-left: 8px;
      }
      td:nth-child(n + 6) {
        padding: 0 1.2rem !important;
      }
    }
    .b-table .table .chevron-cell {
      padding: 0 0 0 0.5rem !important;
    }
    .icon {
      border-radius: 0.5rem;
      background-position: right 50% top 50%, 0 0;
      background-size: 0.75rem 100%;

      background-image: url('/img/icons/1-arrow-2.svg');
      background-repeat: no-repeat, repeat;
      transform: rotate(-90deg);
    }
    .is-expanded {
      background-image: url('/img/icons/1-arrow-2-rotate.svg');
      background-repeat: no-repeat, repeat;
      transform: rotate(0deg) !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.mensajes-plantilla {
  padding-bottom: 3.5rem;
  width: 100%;

  .container {
    max-width: 100vw;
  }

  .tabla__clientes {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    overflow-y: scroll !important;
    max-height: 560px;
    height: 520px;
    background-color: #fff;
    // scroll
    flex-grow: 1;
    margin-bottom: 0;
    animation-name: first-fade-y-up;
    animation-duration: 0.5s;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      border-radius: 2rem;
      margin-bottom: 1rem;
      visibility: visible;
      background: #cdcdcd;
      transition: .25s;

      &:hover, &:active {
        background: #afafaf;
      }
    }
    @media (max-width: 1600px) {
      max-height: 420px;
      overflow-y: scroll !important;
    }
  }
  .btn-date {
    height: auto;
    padding: 0.25rem 0.5rem;
  }
  #input__date {
    margin: auto 0;
    width: auto;
    padding: 0.5rem 1.25rem;
    border-radius: 0 0.5rem 0.5rem 0;
  }
  .info_box {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;

    @media (max-width: 991px) {
      height: 100%;
    }
  }
  .icon {
    width: 100%;
    margin: 1rem auto;
  }
}
span.total-send {
  margin: 0 5px;
  font-size: 12px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 2px 5px;
}
.hover-item:hover {
  color: #2981ef;
  &span.total-send {
    color: #2981ef;
    border: 1px solid #2981ef;
  }
}
.hover-item:hover span.total-send {
  color: #2981ef;
  border: 1px solid #2981ef;
}
tr.detail-template {
  background-color: #fafafa !important;

  &:hover {
    background-color: #f2f2f2 !important;

    td {
      color: #181818 !important;
    }
  }
}
.detail-template td {
  font-size: 12px;
}

table.table.has-mobile-cards.is-hoverable thead tr th:first-child {
  padding: 0 !important;
  width: 0 !important;
}
.acciones {
  visibility: hidden;
}
.active {
  visibility: visible;
}
.custom_sm_btn {
  margin: 0 0.25rem !important;
  padding: .125rem .65rem !important;
}
</style>

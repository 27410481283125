<template>
  <section class="chats botton-line position-relative h-100">
    <div class="menu">
      <h3 class="my-3">Seguimiento de agentes</h3>
      <div class="d-flex justify-content-between" :class="{ search_menu: true }">
        <input
          type="search"
          placeholder="Buscar nombre"
          v-model="searchAgent"
          class="m-0"
        />
        <!-- <span
          v-if="tags.length"
          @click="showDropdownFilter = !showDropdownFilter"
          class="ml-3 filter"
          :class="{
            'i-btn': true,
            'i-filter': !showDropdownFilter,
            'i-btn-active i-close i-st': showDropdownFilter,
          }"
        ></span>
        <MultiselectTags
          @sendCategoryFilter="categoryFilter = $event"
          :showDropdown="showDropdownFilter"
          :items="tags"
        /> -->
      </div>
    </div>
    <spinnerLoading v-if="isLoadingAgents" />
    <template v-else>
      <template v-if="agents?.length > 0">
        <FadeTransition :duracion="200" v-show="!isLoadingAgents">
          <div class="scroll_box position-relative">
            <div
              :class="{
                to_card: true,
                selected_card: selectedAgent?.name === agent.name,
              }"
              v-for="(agent, idx) in filteredAgents"
              :key="idx"
              @click="$emit('onSelectedAgent', agent)"
            >
              <div class="d-flex align-items-center">
                <img
                  :src="agent.image || '/img/brand/0-perfil-agente.png'"
                  class="agent_image mr-2"
                />
                <div class="row align-items-center">
                  <label class="mb-0 ml-2 mr-3">
                    {{ agentName(agent.name) }}
                  </label>
                </div>
                <div
                  class="agent_status_circle"
                  :style="{ backgroundColor: colorCircle(agent.state) || 'grey' }"
                >
                </div>
              </div>
            </div>
          </div>
        </FadeTransition>
      </template>
      <template v-else>
        <FadeTransition :duracion="200" v-show="agents.length == 0">
          <div class="info_box mx-3 my-4">
            <p class="text-center">Sin agentes</p>
          </div>
        </FadeTransition>
      </template>
    </template>
  </section>
</template>

<script>
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import { capitalizeFirstLetter } from '@/utils/utils.js';

export default {
  components: {
    FadeTransition
  },
  props: {
    selectedAgent: {
      type: Object,
      default: () => {}
    },
    agents: {
      type: Array,
      default: () => [],
    },
    isLoadingAgents: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchAgent: '',
      delayTimer: null,
    };
  },
  computed: {
    filteredAgents() {
      return this.agents.filter(agent => agent.name.toLowerCase().includes(this.searchAgent.toLowerCase()));
    }
  },

  // watch: {
  //   searchAgent(val) {
  //     this.$emit('onSearchChat', val)
  //   },
  // },
  methods: {
    agentName(agent) {
      return capitalizeFirstLetter(agent)
    },
    colorCircle(status) {
      const colors = [
        {
          status: 'active',
          color: '#00D100'
        },
        {
          status: 'absent',
          color: '#efb810'
        },
        {
          status: 'inactive',
          color: '#d44646'
        },
      ]
      const colorFound = colors.find(e => e.status == status)?.color
      return colorFound
    }
  },
};
</script>

<style lang="scss" scoped>
.chats {
  z-index: 0;
  transition: 0.25s;
  background-color: #fff;
  .menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin: 0 auto 1rem;

    @media (max-width: 375px) {
      padding: 0;
    }
  }
  .search_menu {
    width: 100%;
    margin-top: 0.75rem;
    // display: grid;
    // grid-template-columns: 6fr 45px;
    // column-gap: 1rem;

    input[type='search'] {
      border: 1px solid #cdcdcd;
      border-radius: 0.5rem;
      width: 100%;
      padding: 0.5rem 0.75rem;
      margin-right: 0.75rem;
      margin-bottom: 0;
      background-size: 1.125rem 100%;
    }
  }
  .to_card {
    position: relative;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 1rem 0;
    transition: 0.25s;
    border-bottom-color: #f2f2f2;
    cursor: pointer;

    &:hover {
      border-radius: 1rem;
      padding: 1rem 1.25rem;
      background-color: #f2f2f2;
      .archive_chat {
        right: 1.25rem;
      }
    }
    .card-box {
      display: grid;
      grid-template-columns: 30px 1fr 30px;
      column-gap: 0.75rem;
    }
    .archive_chat {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      span {
        background-color: white;
        padding: 9px 21.5px;
      }
    }
  }
  .selected_card {
    background-color: #f2f2f2;
    border-color: #cdcdcd;
    border-radius: 1rem;
    padding: 1rem 1.25rem;
    transition: 0.25s;
  }
  .user-img,
  .user-img-wsp,
  .user-img-instagram {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50rem;
    background-color: #2981ef;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }
  .user-img-messenger {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50rem;
    background-color: #2981ef;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }
  .user-img-wsp {
    background-color: #9edd63;
    background-image: url('/img/icons/18-i-whatsapp-b.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .user-img-instagram {
    background-color: #f5365c;
    background-image: url('/img/icons/31-i-instagram-b.svg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 1rem 100%;
  }
  .etiqueta {
    text-transform: capitalize;
    border-color: #cdcdcd;
    margin: auto;
    cursor: default;

    &:hover {
      color: #767676;
      background-color: #fff;
    }
  }
  .message_notification {
    display: flex;
    align-items: center;
    background-color: #2981ef;
    padding: 0.025rem 0.5rem;
    padding-right: calc(0.5rem - 8px);
    border-radius: 2rem;
    color: #fff;
  }
  .pagination-container {
    bottom: 0;
    background-color: white;
  }
  .scroll_box {
    height: 80%;
    transition: 0.25s;
    padding: 0 0.5rem 3rem 1rem;
    overflow-y: scroll !important;

    @media (max-width: 1600px) {
      height: 80%;
    }
    @media (max-width: 375px) {
      padding: 0;
      padding-bottom: 25rem;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;

      &:hover,
      &:active {
        visibility: visible;
      }
    }
  }
  .user-message {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .text-state {
    font-size: 95%;
    background-color: #fff;
  }
  .not-viewed {
    background-color: #f5365c;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    padding: 0.05rem 0.5rem;
    border-radius: 0.5rem;
    margin-left: 0.5rem;
  }
  .filter {
    width: 45px
  }
  .agent_image {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50rem;
    justify-content: center;
    align-items: center;
  }
  .agent_status_circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
}
</style>

<template>
  <div class="data-float-menu">
    <span class="i-btn i-close i-btn-sm px-2 m-0" @click="$emit('close')"></span>
    <SpinnerLoading v-if="loading_answers" />
    <template v-else-if="items.length > 0">
      <ul class="p-0">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="item"
          @click="$emit('selected', item)"
        >
          {{ item.title }}
        </li>
      </ul>
    </template>
    <template v-else>
      <div class="info_box py-4 text-center w-100">
        <span class="text_sm">Sin datos</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading_answers: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return{
      search: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.data-float-menu {
  position: absolute;
  bottom: 4.5rem;
  left: 5.5rem;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  min-width: 250px;
  max-height: 300px;
  border-radius: 1.5rem;
  background-color: #fff;
  box-shadow: 0 0 30px #D8EAFF;
  padding: 1.25rem;
  margin: auto 0;
  z-index: 100;

  .bg-spinner {
    position: relative;
    height: 100%;
    transform: scale(0.6);
  }
  ul {
    list-style: none;
    margin-bottom: 0;
    width: 100%;

    .item {
      padding: 0.5rem;
      margin-block: .25rem;
      transition: 0.25s;
      border-radius: .5rem;
      cursor: pointer;

      &:hover {
        background-color: #D8EAFF;
      }
    }
  }
  .input_search {
    border-radius: .5rem;
    padding: 0.25rem 0.5rem;
  }
  .i-close {
    position: absolute;
    top: .75rem;
    right: .75rem;
    background-size: .5rem;
    background-color: #fff;
    z-index: 10;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',_vm._l((_vm.groupedMessages),function(group,groupIndex){return _c('div',{key:groupIndex},_vm._l((group.grouped),function(message,messageIndex){return _c('div',{key:messageIndex,class:{
        'd-flex justify-content-end': (group.from ? group.from !== 'Cliente' : message.sender !== 'Cliente') && message.message_type !== 'alert',
      }},[_c('div',{class:{
          'from-client': group.from ? group.from === 'Cliente' : message.sender === 'Cliente',
          'from-agent': group.from ? group.from != 'Cliente' : message.sender != 'Cliente',
          rich_element: message.message_type == 'audio',
        }},[_c('div',{class:{
            'd-flex align-items-end': true,
            'justify-content-end': group.from ? group.from !== 'Cliente' : message.sender !== 'Cliente',
          }},[(group.from ? group.from === 'Cliente' : message.sender === 'Cliente')?[(
                message.text ===
                  group.grouped[group.grouped.length - 1].text &&
                _vm.conversation.channel === 'instagram' &&
                _vm.conversationMetadata.profile_pic
              )?_c('img',{staticClass:"mr-2 msg-profile",attrs:{"src":_vm.conversationMetadata.profile_pic,"alt":""}}):(
                message.text === group.grouped[group.grouped.length - 1].text
              )?_c('span',{class:{
                'profile-client desktop-xs mr-3': true,
                'profile-wsp': _vm.conversation.channel == 'Whatsapp Bot',
                'profile-instagram': _vm.conversation.channel == 'instagram',
                'profile-messenger': _vm.conversation.channel == 'messenger',
              }},[_vm._v(_vm._s((_vm.conversation.lead_name && _vm.conversation.lead_name[0]) || 'C'))]):_c('span',{staticClass:"space-left"})]:_vm._e(),_c('div',{class:{ 
              'message-inner': _vm.validateFormatMessage(group, message),
              'supervisor': message.sender == 'Supervisor' || message.from == 'Supervisor',
            },on:{"mouseover":function($event){_vm.showIcon = messageIndex},"mouseleave":function($event){_vm.showIcon = -1}}},[(message.reply_to)?_c('div',[_vm._v(" Usuario respondió a: "),_c(_vm.getDynamicComponent(
                    _vm.getMessageByPlatformId(message.reply_to)?.message_type,
                  ),{tag:"component",attrs:{"message":_vm.getMessageByPlatformId(message.reply_to),"is_reply":true}}),_c('div',{staticClass:"horizontal-line"})],1):_vm._e(),(!message.message_type || message.message_type == 'text')?_c('MessageText',{attrs:{"message":message}}):_vm._e(),(
                message.message_type == 'template_wsp' &&
                message.payload &&
                message.payload.template
              )?_c('MessageTemplateWhatsApp',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'option')?_c('MessageOption',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'audio')?_c('MessageAudio',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'video')?_c('MessageVideo',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'sticker')?_c('MessageSticker',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'file')?_c('MessageFile',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'image')?_c('MessageImage',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'carousel')?_c('MessageCarousel',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'rating')?_c('MessageRating',{attrs:{"message":message}}):_vm._e(),(message.message_type == 'location')?_c('MessageLocation',{attrs:{"message":message}}):_vm._e(),(!message.text.includes('Ahora estas conversando con'))?_c('p',{staticClass:"text_xs mt-2"},[(message.sender == 'Supervisor')?_c('span',{staticClass:"link"},[_vm._v("Supervisor -")]):_vm._e(),_vm._v(" "+_vm._s(_vm.messageTime(message.created_at))+" ")]):_vm._e()],1),(
              (group.from ? group.from != 'Cliente' : message.sender != 'Cliente') &&
              !message.text.includes('Ahora estas conversando con')
            )?[(
                message.created_at === group.grouped[group.grouped.length - 1].created_at
              )?_c('img',{staticClass:"profile-bot desktop-xs ml-3",attrs:{"src":(group.from ? group.from == 'Watson' : message.sender == 'Watson')
                  ? _vm.getImgBot
                  : _vm.user.image || 'img/brand/0-default.svg',"alt":""}}):_c('span',{staticClass:"space-right"})]:_vm._e()],2)]),(message.message_type == 'alert')?_c('MessageAlert',{attrs:{"message":message,"user":_vm.user}}):_vm._e()],1)}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section v-if="selected_bot" class="tabla-clientes">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else-if="selected_bot.id" :duration="200" v-show="view_permissions.read == true">
      <div class="tabla-clientes">
        <div class="row justify-content-between align-items-center b-bottom">
          <h3 class="my-2">Leads de {{ selected_bot.bot_name }}</h3>
          <FadeTransition :duration="100">
            <div class="row align-items-center">
              <FadeTransition :duration="100">
                <div
                  v-if="checkedRows?.length > 0"
                  class="d-flex align-items-center my-0 mx-2"
                >
                  <span
                    class="btn-text text-danger p-relative my-1 mr-3"
                    v-if="view_permissions.delete != true"
                  >
                    <i class="fas fa-times" />Eliminar {{ checkedRows.length }}
                    <span class="tooltip_text-sm for_disabled" v-if="view_permissions.delete != true">Lo sentimos, no tienes permisos para eliminar datos.</span>
                  </span>
                  <span
                    v-else
                    @click="deletedLead"
                    class="btn-text text-danger p-relative my-1 mr-3"
                  >
                    <i class="fas fa-times" />Eliminar {{ checkedRows.length }}
                  </span>
                </div>
              </FadeTransition>
              <!-- Listas -->
              <select
                v-if="listTags.length > 0"
                :class="{
                  'select_text list-w my-1 mr-3': true,
                  active: filter_list_text > 0
                }"
                @change="getLeadsPage2(1)"
                v-model="filter_list_text"
              >
                <option value>Filtrar por Tags</option>
                <option
                  v-for="(tag, tagIndex) in listTags"
                  :key="tagIndex"
                  :value="tag.id"
                  >{{ tag.label_name }}</option
                >
              </select>

              <!-- Estados -->
              <select
                :class="{
                  'select_text my-1 mr-3': true,
                  active: filter_state_text.length > 0
                }"
                v-model="filter_state_text"
                @change="getLeadsPage2(1)"
              >
                <option value>Filtrar por Estado</option>
                <option value="pendiente">Nuevo</option>
                <option value="contactado">En proceso</option>
                <option value="venta cerrada">Con venta</option>
                <option value="perdido">Sin venta</option>
                <option value="resuelto">Resuelto</option>
              </select>

              <!-- canal -->
              <select
                :class="{
                  'select_text my-1 mr-3': true,
                  active: filter_channel_text.length > 0
                }"
                v-model="filter_channel_text"
                @change="getLeadsPage2(1)"
              >
                <option value>Filtrar por Canal</option>
                <option value="Manual">Carga manual</option>
                <option value="Carga Masiva">Carga masiva</option>
                <option value="Chatbot">Chatbot web</option>
                <option value="Whatsapp Bot">Chatbot WhatsApp</option>
                <option value="instagram">Instagram</option>
                <option value="messenger">Messenger</option>
                <option value="Vtex Automatización">Vtex Automatización</option>
              </select>

              <!-- El Multiselect -->
              <div class="multiselect">
                <div
                  v-if="!expanded"
                  class="selectBox"
                  @click="showCheckboxes()"
                >
                  <select :class="{'select_text my-1 mr-3': true, active: selected_columns && selected_columns.length > 0}">
                    <option>Ocultar Columna</option>
                  </select>
                  <div class="overSelect" />
                </div>
                <div v-else class="selectBox" @click="showCheckboxes()">
                  <select class="select_text my-1 mr-3">
                    <option>Ocultar Columna</option>
                  </select>
                  <div class="overSelect" />
                </div>
                <div>
                  <div class="checkbox-id" id="checkboxes">
                    <label for="one" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Nombre"
                        id="one"
                      />Nombre
                    </label>
                    <label for="two" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Email"
                        id="two"
                      />Correo
                    </label>
                    <label for="three" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Telefono"
                        id="three"
                      />Teléfono
                    </label>
                    <label for="four" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Horario de contacto"
                        id="four"
                      />Preferencia contacto
                    </label>
                    <label for="five" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Pregunta inicial"
                        id="five"
                      />Pregunta inicial
                    </label>
                    <label for="seven" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Calificación"
                        id="seven"
                      />Calificación
                    </label>
                    <label for="eight" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Estado"
                        id="eight"
                      />Estado
                    </label>
                    <label for="nine" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Channel"
                        id="nine"
                      />Canal
                    </label>
                    <label for="ten" class="checkboxes-item">
                      <input
                        type="checkbox"
                        v-model="selected_columns"
                        value="Agente asignado"
                        id="ten"
                      />Agente asignado
                    </label>
                  </div>
                </div>
              </div>
              <div class="input_box">
                <span class="btn-date" @click="empty">
                  <i class="fas fa-times mb-0 mr-0" />
                </span>
                <input
                  type="date"
                  class="input_date sm my-1 mr-3"
                  @change="getLeadsPage2(1)"
                  v-model="filter_time_text"
                />
              </div>
              <div class="input_box">
                <span class="btn-date px-2" @click="empty">
                  <i class="fas fa-search mr-0" />
                </span>
                <input
                  placeholder="Buscar correo, nombre, teléfono o nº de identificación"
                  v-model="filter_email_text"
                  @change="searchEmail"
                  type="search"
                  class="input_search sm mb-0 mr-3"
                />
              </div>
              <template v-if="plan_name == 'Plan Basic-web0'">
                <!-- TODO Sistema de planes con flow deprecado -->
                <!-- <router-link
                  v-if="templates && templates.length > 0"
                  class="btn-select static sm disabled my-1 mr-3"
                  :to="{
                    name: 'dashboard-planes',
                    query: {
                      seccion: 'mejorar-plan',
                      bot_id: selected_bot.id,
                    },
                  }"
                  ><i class="fab fa-whatsapp grey mr-2" />Enviar plantilla<span
                    class="upgrade-label"
                    >Mejorar</span
                  >
                </router-link> -->
                <span
                  v-if="templates?.length > 0"
                  class="btn-select static sm disabled my-1 mr-3"
                  ><i class="fab fa-whatsapp grey mr-2" />Enviar plantilla<span
                    class="upgrade-label"
                    >Mejorar</span
                  >
                </span>
              </template>
              <template v-else>
                <button
                  v-if="templates?.length > 0"
                  @click="showTagsTemplate = true"
                  class="btn-select static sm my-1 mr-3"
                  :disabled="view_permissions.edit != true"
                >
                  <i class="fab fa-whatsapp mr-2" />Enviar plantilla
                  <span class="tooltip_text-sm for_disabled" v-if="view_permissions['send-wsp'] != true">Lo sentimos, no tienes accesos a este módulo.</span>
                </button>
              </template>
              <button
                @click="show_modal_addClient = true"
                class="btn-select static sm my-1"
                :disabled="view_permissions.add != true"
              >
              <span class="i-base i-client i-st mr-2" />Agregar cliente
              <span class="tooltip_text-sm for_disabled" v-if="view_permissions.add != true">Lo sentimos, no tienes accesos a este módulo.</span>
              </button>
            </div>
          </FadeTransition>
        </div>
        <SpinnerLoading v-if="table_loading" />
        <template v-else-if="pages > 0">
          <section class="table buefy">
            <b-table
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :data="tableDatafiltered"
              :per-page="perPage"
              :current-page.sync="currentPage"
              :pagination-simple="true"
              :pagination-position="'bottom'"
              :default-sort-direction="'desc'"
              :sort-icon="'caret-up'"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              icon-pack="fas"
              :key="tableKey"
              :checked-rows.sync="checkedRows"
              :selected.sync="selectedRow"
              :sticky-header="stickyHeaders"
              height="500"
              checkable
              focusable
              v-click-outside="showModalTags"
              @click="closeGlobalTags($event)"
            >
              <template slot-scope="props">
                <b-table-column
                  field="name"
                  label="Nombre"
                  :visible="columnsVisible.name.display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">
                    <input
                      v-if="newedit.name"
                      class="column-input"
                      name="nombre"
                      @blur="handleBlur(props.row.id)"
                      v-model="props.row.name"
                      @dblclick="changen('name')"
                    />
                    <p v-else @dblclick="changen('name')" class="e-show">
                      <router-link
                        :to="'/clients/detail-lead/' + props.row.id"
                        class="mr-2"
                      >
                        {{ props.row.name }}
                      </router-link>
                      <span
                        @click="setShowChat(props.row)"
                        :class="{
                          'i-btn i-chat-2 i-btn-sm i-st i-btn-hover': true,
                          active: props.row.id == selectedRow.id
                        }"
                      ></span>
                    </p>
                  </template>
                  <template v-else>
                    <p class="e-show">
                      {{ props.row.name }}
                      <span
                        @click="setShowChat(props.row)"
                        :class="{
                          'i-btn i-chat-2 i-btn-sm i-st i-btn-hover': true,
                          active: props.row.id == selectedRow.id
                        }"
                      ></span>
                    </p>
                  </template>
                </b-table-column>

                <b-table-column
                  field="email"
                  label="Correo"
                  :visible="columnsVisible['email'].display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">
                    <div v-if="props.row.email">
                      <input
                        v-if="newedit.email"
                        class="column-input"
                        name="email"
                        @blur="handleBlur(props.row.id)"
                        v-model="props.row.email"
                        @dblclick="changen('email')"
                      />
                      <span class="" v-else @dblclick="changen('email')">{{
                        props.row.email
                      }}</span>
                    </div>
                    <div v-else>
                      <input
                        class="column-input"
                        name="email"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.email"
                        v-model="props.row.email"
                        @dblclick="changen('email')"
                      />
                      <span v-else @dblclick="changen('email')">--</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{ props.row.email || "--" }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  pack="fab"
                  icon="whatsapp"
                  field="phone"
                  label="Teléfono"
                  :visible="columnsVisible['phone'].display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">

                  </template>
                  <template v-else>

                  </template>
                  <div class="d-flex align-items-center" v-if="props.row.phone">
                    <i
                      v-if="
                        props.row.phone.toString().length < 8 ||
                          props.row.phone.toString().length > 11
                      "
                      class="fab fa-whatsapp red p-1"
                    />
                    <i
                      v-else
                      target="_blank"
                      @click="whatsapp(props.row.phone)"
                      class="fab fa-whatsapp p-1"
                      :class="
                        failedNumbers.includes(props.row.phone)
                          ? 'wsap_number_error'
                          : ''
                      "
                    />
                    <template v-if="view_permissions.edit == true">
                      <input
                        class="column-input"
                        name="phone"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.phone"
                        v-model="props.row.phone"
                        @dblclick="changen('phone')"
                      />
                      <span
                        :class="
                          failedNumbers.includes(props.row.phone)
                            ? 'wsap_number_error'
                            : ''
                        "
                        v-else
                        @dblclick="changen('phone')"
                        >{{ props.row.phone }}
                      </span>
                    </template>
                    <template v-else>
                      <span>{{ props.row.phone || "--" }}</span>
                    </template>
                    <IconFlag
                      v-if="props.row.phone"
                      classes="ml-3 flag-w"
                      :phoneNumber="props.row.phone"
                      :key="props.row.phone"
                    />
                  </div>
                  <div v-else>
                    <i class="fab fa-whatsapp red p-1" />
                    <template v-if="view_permissions.edit == true">
                      <input
                        class="column-input"
                        name="phone"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.phone"
                        v-model="props.row.phone"
                        @dblclick="changen('phone')"
                      />
                      <span v-else @dblclick="changen('phone')">--</span>
                    </template>
                    <template v-else>
                      <span>{{ props.row.phone || "--" }}</span>
                    </template>
                  </div>
                </b-table-column>
                <b-table-column
                  field="rut"
                  label="Nº de identificación"
                  :visible="columnsVisible['rut'].display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">
                    <div v-if="props.row.rut">
                      <input
                        class="column-input"
                        name="rut"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.rut"
                        v-model="props.row.rut"
                        @dblclick="changen('rut')"
                      />
                      <span v-else @dblclick="changen('rut')">{{
                        props.row.rut
                      }}</span>
                    </div>
                    <div v-else>
                      <input
                        v-if="newedit.rut"
                        class="column-input"
                        name="rut"
                        @blur="handleBlur(props.row.id)"
                        v-model="props.row.rut"
                        @dblclick="changen('rut')"
                      />
                      <span v-else @dblclick="changen('rut')">--</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{ props.row.phone || "--" }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="contact_time"
                  label="Preferencia de contacto"
                  :visible="columnsVisible['contact_time'].display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">
                    <div v-if="props.row.contact_time">
                      <input
                        class="column-input"
                        name="contact_time"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.contact_time"
                        v-model="props.row.contact_time"
                        @dblclick="changen('contact_time')"
                      />
                      <span v-else @dblclick="changen('contact_time')">{{
                        props.row.contact_time
                      }}</span>
                    </div>
                    <div v-else>
                      <input
                        class="column-input"
                        name="contact_time"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.contact_time"
                        v-model="props.row.contact_time"
                        @dblclick="changen('contact_time')"
                      />
                      <span v-else @dblclick="changen('contact_time')">--</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{ props.row.contact_time || "--" }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="question"
                  label="Pregunta inicial"
                  :visible="columnsVisible['question'].display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">
                    <div class="width-column" v-if="props.row.question">
                      <input
                        class="column-input"
                        name="question"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.question"
                        v-model="props.row.question"
                        @dblclick="changen('question')"
                      />
                      <span v-else :title="props.row.question" @dblclick="changen('question')">{{
                        props.row.question
                      }}</span>
                    </div>
                    <div v-else>
                      <input
                        class="column-input"
                        name="question"
                        @blur="handleBlur(props.row.id)"
                        v-if="newedit.question"
                        v-model="props.row.question"
                        @dblclick="changen('question')"
                      />
                      <span v-else @dblclick="changen('question')">--</span>
                    </div>
                  </template>
                  <template v-else>
                    <span>{{ props.row.question || "--" }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  field="channel"
                  label="Canal"
                  :visible="columnsVisible['channel'].display"
                  sortable
                >
                  <div v-if="props.row.channel">
                    <span>{{ setChannelLabel(props.row.channel) || "--" }}</span>
                  </div>
                </b-table-column>
                <b-table-column field="etiquetas" label="Tags" v-click-outside="closeModal">
                  <template v-if="view_permissions.edit == true">
                    <div v-if="props.row.label_leads && props.row.label_leads.length == 0">
                      <span
                        class="i-btn px-2"
                        @click.stop="showModalTags(props.row.id)"
                        >+</span
                      >
                    </div>
                    <div v-else>
                      <span
                        class="tag-text text-state text-state-fill"
                        :style="[
                          { backgroundColor: tags.label_color || '#767676' },
                        ]"
                        @click.stop="showModalTags(props.row.id)"
                        v-for="(tags, tagIndex2) in props.row.label_leads"
                        :key="tagIndex2"
                        >{{ tags.label_name }}</span
                      >
                    </div>
                    <template v-if="props.row.label_leads">
                      <ModalEtiquetas
                        v-show="props.row.id == activeItem"
                        :listGeneralTags="listTags"
                        :listGlobalProps="tagsGlobal"
                        :listTagsProps="props.row.label_leads"
                        :id="props.row.id"
                        :positionLead="props.row.position"
                        :botId="selected_bot.id"
                        @update="listTagsList($event)"
                        @deleteGlobal="deleteTagsList($event)"
                        @closeModalTags="showModalTags()"
                        @addNewTag="addNewTag($event)"
                        @addExistingTag="addExistingTag($event)"
                        @removeTagFromElement="removeTagFromLead($event)"
                      />
                    </template>
                  </template>
                  <template v-else>
                    <div v-if="props.row.label_leads && props.row.label_leads.length == 0">
                      <span>--</span>
                    </div>
                    <span
                      v-else
                      class="tag-text text-state text-state-fill"
                      :style="[
                        { backgroundColor: tags.label_color || '#767676' },
                      ]"
                      v-for="(tags, tagIndex2) in props.row.label_leads"
                      :key="tagIndex2"
                      >{{ tags.label_name }}</span
                    >
                  </template>
                </b-table-column>
                <b-table-column
                  field="custom"
                  label="URLs"
                  sortable
                >
                  <div v-if="props.row" class="width-column">
                    <span :title="props.row.custom?.special_routes">
                      {{ props.row.custom?.special_routes?.join('\n') }}
                    </span>
                  </div>
                  <div v-else><span></span>--</div>
                </b-table-column>
                <b-table-column
                  field="opcion_inicial"
                  label="Opción inicial"
                  v-if="tableDatafiltered.some(el => el.opcion_inicial)"
                  sortable
                >
                  <div v-if="props.row.opcion_inicial">
                    {{ props.row.opcion_inicial }}
                  </div>
                  <div v-else><span></span>--</div>
                </b-table-column>
                <b-table-column
                  v-for="(property, customIndex) in customProperties"
                  :key="customIndex"
                  :field="property"
                  :label="property"
                >
                  <div class="width-column" v-if="props.row.custom[property]" :title="props.row.custom[property]">
                    {{
                      props.row.custom[property] === true
                        ? 'Sí'
                        : props.row.custom[property]
                    }}
                  </div>
                  <span v-else><span>--</span></span>
                </b-table-column>
                <!-- <b-table-column
                  v-if="props.row.contact_date_lead"
                  field="contact_date_lead"
                  label="Fecha de contacto"
                  :visible="columnsVisible['contact_date'].display"
                  sortable
                >
                  <span>{{ props.row.contact_date_lead }}</span>
                </b-table-column> -->
                <b-table-column
                  field="created"
                  label="Fecha de creación"
                  sortable
                >
                  <span>{{ $moment(props.row.created_at).format("DD/MM/YYYY - hh:mm a") }}</span>
                </b-table-column>
                <b-table-column
                  field="updated"
                  label="Fecha de modificación"
                  sortable
                >
                  <span>{{ $moment(props.row.updated_at).format("DD/MM/YYYY - hh:mm a") }}</span>
                </b-table-column>

                <b-table-column
                  field="conversation.calification"
                  label="Calificación"
                  :visible="columnsVisible['calification'].display"
                  sortable
                >
                  <div v-if="props.row.conversation">
                    <template v-if="changeCalification">
                      <span>
                        <i class="far fa-star" />
                      </span>
                      {{ props.row.conversation.calification }}/5
                    </template>
                    <template v-else>
                      <span
                        v-if="props.row.conversation.calification == 1"
                        class="no-state no-state_custom px-0"
                      >
                        <img
                          class="icon_rating mr-2"
                          src="/img/icons/rating/1.1-bad.svg"
                          alt=""
                        />
                        Malo
                      </span>
                      <span
                        v-if="props.row.conversation.calification == 2"
                        class="no-state no-state_custom px-0"
                      >
                        <img
                          class="icon_rating mr-2"
                          src="/img/icons/rating/2.1-meh.svg"
                          alt=""
                        />
                        Regular
                      </span>
                      <span
                        v-if="props.row.conversation.calification == 3"
                        class="no-state no-state_custom px-0"
                      >
                        <img
                          class="icon_rating mr-2"
                          src="/img/icons/rating/3.1-what.svg"
                          alt=""
                        />
                        Útil
                      </span>
                      <span
                        v-if="props.row.conversation.calification == 4"
                        class="no-state no-state_custom px-0"
                      >
                        <img
                          class="icon_rating mr-2"
                          src="/img/icons/rating/4.1-nice.svg"
                          alt=""
                        />
                        Bueno
                      </span>
                      <span
                        v-if="props.row.conversation.calification == 5"
                        class="no-state no-state_custom px-0"
                      >
                        <img
                          class="icon_rating mr-2"
                          src="/img/icons/rating/5.1-love.svg"
                          alt=""
                        />
                        Buenísimo
                      </span>
                      <span
                        v-if="props.row.conversation.calification == 0"
                        class="no-state no-state_custom px-0"
                        >Sin calificar
                      </span>
                    </template>
                  </div>
                  <div v-else><span></span>--</div>
                </b-table-column>
                <!-- Solicitudes -->
                <b-table-column
                  field="databot_lives[0].chat_rooms[0].client_state"
                  label="Solicitudes"
                  class="td_tooltip"
                >
                  <template v-if="props.row.conversation">
                    <template v-if="selected_bot.is_databot_live_3">
                      <div
                        v-if="props.row.client_state"
                        :class="{
                          'tooltip-custom no-state': true,
                          'text-state': props.row.client_state
                        }"
                      >
                        {{ props.row.client_state }}
                        <p
                          v-if="props.row.client_state == 'tomado'"
                          class="tooltip_text"
                        >
                          {{ tooltip_messaje.tomado }}
                        </p>
                        <p
                          v-if="
                            props.row.client_state == 'pendiente'
                          "
                          class="tooltip_text"
                        >
                          {{ tooltip_messaje.pendiente }}
                        </p>
                        <p
                          v-if="
                            props.row.client_state == 'terminado'
                          "
                          class="tooltip_text"
                        >
                          {{ tooltip_messaje.terminado }}
                        </p>
                        <p v-else class="tooltip_text">
                          {{ tooltip_messaje.sin_solicitar }}
                        </p>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        v-if="props.row.databot_lives[0]?.chat_rooms"
                        :class="{
                          'tooltip-custom no-state': true,
                          'text-state': props.row.databot_lives[0]?.chat_rooms[0]?.client_state
                        }"
                      >
                        {{
                          selected_bot.is_databot_live_3 ? 
                            props.row.client_state :
                            props.row.databot_lives[0]?.chat_rooms[0]?.client_state || 'Sin solicitar'
                        }}
                        <p
                          v-if="
                            props.row.databot_lives[0].chat_rooms[0]
                              .client_state == 'tomado'
                          "
                          class="tooltip_text"
                        >
                          {{ tooltip_messaje.tomado }}
                        </p>
                        <p
                          v-if="
                            props.row.databot_lives[0].chat_rooms[0]
                              .client_state == 'pendiente'
                          "
                          class="tooltip_text"
                        >
                          {{ tooltip_messaje.pendiente }}
                        </p>
                        <p
                          v-if="
                            props.row.databot_lives[0].chat_rooms[0]
                              .client_state == 'terminado'
                          "
                          class="tooltip_text"
                        >
                          {{ tooltip_messaje.terminado }}
                        </p>
                        <p
                          v-if="
                            !props.row.databot_lives[0].chat_rooms[0].client_state
                          "
                          class="tooltip_text"
                        >
                          {{ tooltip_messaje.sin_solicitar }}
                        </p>
                      </div>
                    </template>
                  </template>
                  <template v-else><span></span>--</template>
                </b-table-column>
                <!--Estados-->
                <b-table-column
                  label="Estado"
                  field="estado"
                  :visible="columnsVisible['estado'].display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">
                    <select
                      name="estado"
                      class="select-css"
                      :style="{ 'background-color': props.row.color || '#181818' }"
                      :value="formatedValueStateLabel(props.row.estado)"
                      :id="props.row.id"
                      :key="props.row.id"
                      @change="updateState"
                    >
                      <option value="pendiente">Nuevo</option>
                      <option value="contactado">En proceso</option>
                      <option value="venta cerrada">Con venta</option>
                      <option value="perdido">Sin venta</option>
                      <option value="resuelto">Resuelto</option>
                    </select>
                  </template>
                  <template v-else>
                    <span
                      name="estado"
                      class="select-css"
                      :style="{ 'background-color': props.row.color || '#181818' }"
                      :id="props.row.id"
                      :key="props.row.id"
                    >{{
                      formatedValueStateLabel(props.row.estado) == 'pendiente'
                        ? 'Nuevo'
                        : formatedValueStateLabel(props.row.estado) == 'contactado'
                          ? 'En proceso'
                          : formatedValueStateLabel(props.row.estado) == 'venta cerrada'
                            ? 'Con venta'
                            : formatedValueStateLabel(props.row.estado) == 'perdido'
                              ? 'Sin venta'
                              : props.row.estado
                    }}</span>
                  </template>
                </b-table-column>
                <b-table-column
                  v-if="props.row.type_of_operation"
                  field="type_of_operation"
                  label="tipo de operacion"
                  sortable
                  searchable
                >
                  <div v-if="props.row.type_of_operation">
                    {{ props.row.type_of_operation }}
                  </div>
                  <div v-else><span></span>--</div>
                </b-table-column>

                <b-table-column
                  v-if="props.row.type_of_housing"
                  field="type_of_housing"
                  label="tipo de casa"
                  :visible="columnsVisible2['type_of_housing'].display"
                  sortable
                  searchable
                >
                  <div v-if="props.row.type_of_housing">
                    {{ props.row.type_of_housing }}
                  </div>
                  <div v-else><span>--</span></div>
                </b-table-column>
                <b-table-column
                  v-if="props.row.number_of_bedrooms"
                  field="number_of_bedrooms"
                  label="numero de dormitorios"
                  :visible="columnsVisible2['number_of_bedrooms'].display"
                  sortable
                  searchable
                >
                  <div v-if="props.row.number_of_bedrooms">
                    {{ props.row.number_of_bedrooms }}
                  </div>
                  <div v-else><span>--</span></div>
                </b-table-column>

                <b-table-column
                  v-if="props.row.sqare_meter"
                  field="sqare_meter"
                  label="metro cuadrados"
                  :visible="columnsVisible2['sqare_meter'].display"
                  sortable
                  searchable
                >
                  <div v-if="props.row.sqare_meter">
                    {{ props.row.sqare_meter }}
                  </div>
                  <div v-else><span>--</span></div>
                </b-table-column>

                <b-table-column
                  v-if="props.row.comuna"
                  field="comuna"
                  label="comuna"
                  :visible="columnsVisible2['comuna'].display"
                  sortable
                  searchable
                >
                  <div v-if="props.row.comuna">
                    {{ props.row.comuna }}
                  </div>
                  <div v-else><span>--</span></div>
                </b-table-column>

                <b-table-column
                  v-if="props.row.region"
                  field="region"
                  label="region"
                  :visible="columnsVisible2['region'].display"
                  sortable
                  searchable
                >
                  <div v-if="props.row.region">
                    {{ props.row.region }}
                  </div>
                  <div v-else><span>--</span></div>
                </b-table-column>
                <b-table-column
                  field="agent"
                  label="Agente asignado"
                  :visible="columnsVisible['agent'].display"
                  sortable
                >
                  <template v-if="view_permissions.edit == true">
                    <select
                      class="select_sm"
                      :id="props.row.id"
                      :value="props.row.agent"
                      @change="updateAgent"
                      :key="props.row.id"
                    >
                      <option value>Seleccionar</option>
                      <option v-if="findUnlinkAgent(props.row.agent)" :value="props.row.agent" disabled>{{ props.row.agent + " (ex)" }}</option>
                      <option
                        v-for="userb in userbot"
                        :value="userb.user.name"
                        :key="userb.user.name"
                      >
                        {{ userb.user.name }}
                      </option>
                    </select>
                  </template>
                  <template v-else>
                    <span>{{ props.row.agent || '--' }}</span>
                  </template>
                </b-table-column>
                <!-- <b-table-column
                  field="whatsapp-archivado" label="Conversaciones"
                  sortable
                >
                  <div
                    :class="props.row.channel == 'Whatsapp Bot' ? 'btn btn-base third-outline xs': 'text-state no-state'"
                    >{{ props.row.channel == 'Whatsapp Bot' ? 'Desarchivar' : 'Sin archivar' }}
                  </div>
                </b-table-column> -->

                <!-- <b-table-column label="Acciones" class="acciones">
                  <div
                    v-if="
                      props.row.channel == 'Manual' ||
                      props.row.channel == 'Whatsapp'
                    "
                  >
                    --
                  </div>
                  <router-link
                    v-else
                    class="btn-text"
                    :to="
                      '/conversaciones/' +
                      props.row.session_id +
                      '?bot_id=' +
                      selected_bot.id +
                      '&client=' +
                      props.row.id +
                      '&client_name=' +
                      props.row.name
                    "
                    ><i class="far fa-eye" /> Ver detalle
                  </router-link>
                </b-table-column> -->
              </template>
            </b-table>
          </section>
        </template>
        <template v-else>
          <div class="no_data">
            <img class="icon" src="/img/icons/15-not_found.svg" alt="databot" />
            <p class="text-center text_sm my-3">
              No hay leads registrados.
            </p>
          </div>
        </template>
        <div class="table-footer">
          <div class="col-md-4 pl-0">
            <span class="text_sm mr-3 mb-0">
              Visibles: {{ tableDatafiltered?.length == 1 ? tableDatafiltered?.length + " Lead." : tableDatafiltered?.length + " Leads." }}
            </span>
            <template v-if="totalLeadsId && listTags.length > 0">
              <button
                @click="(showMassTags = !showMassTags), checkedRows = []"
                class="btn-select static sm my-0 mr-3"
                :disabled="view_permissions.edit != true"
              >
                Etiquetar todos
                <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes accesos a este módulo.</span>
              </button>
            </template>
            <FadeTransition :duration="200">
              <button
                v-if="checkedRows && checkedRows.length > 0"
                @click="showMassTags = !showMassTags"
                class="btn-select static sm my-1"
                :disabled="view_permissions.edit != true"
                >Etiquetar {{ checkedRows.length }}
                {{ checkedRows.length == 1 ? ' lead.' : ' leads.' }}
                <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes accesos a este módulo.</span>
              </button>
            </FadeTransition>
          </div>
          <div class="col-md-4 d-flex flex-column justify-content-center">
            <div class="d-flex justify-content-center">
              <p
                class="pagination__firstPage my-auto mx-4"
                :class="actual_page != 1 && 'allowed'"
                :style="`cursor: ${actual_page == 1 ? 'not-allowed' : 'pointer'}`"
                @click="actual_page != 1 && getLeadsPage2(1)"
              >
                1
              </p>
              <div class="number-input my-auto">
                <button
                  class="btn-left left-arrow py-1 px-3"
                  type="button"
                  id="button-addon1"
                  @click="
                    actual_page != 1 && getLeadsPage2(Number(actual_page) - 1)
                  "
                  :disabled="actual_page == 1"
                ></button>
                <div>
                  <input
                    class="input_page m-0"
                    :class="{ input_error: errorInputPage }"
                    type="number"
                    :max="pages"
                    v-model="input_page"
                    @keyup="inputPageSubmit"
                    error="true"
                  />
                </div>
                <button
                  class="btn-right right-arrow py-1 px-3"
                  type="button"
                  id="button-addon2"
                  :disabled="actual_page == pages"
                  @click="
                    actual_page != pages && getLeadsPage2(Number(actual_page) + 1)
                  "
                ></button>
              </div>
              <p
                class="pagination__lastPage my-auto mx-4"
                :class="actual_page != pages && 'allowed'"
                :style="
                  `cursor: ${actual_page == pages ? 'not-allowed' : 'pointer'}`
                "
                @click="actual_page != pages && getLeadsPage2(pages)"
              >
                {{ pages }}
              </p>
            </div>
            <span
              v-if="errorInputPage"
              class="text_sm text_error text-center mx-auto mt-2"
            >
              El valor ingresado debe ser entre 1 y {{ pages }}
            </span>
          </div>
          <div class="col-md-4 d-flex align-items-center justify-content-end pr-0">
            <span v-if="totalLeadsId" class="text_sm mb-0 mr-3">{{ totalLeadsId.length }} leads en total.</span>
            <select
              name="items"
              class="select_text my-1 mr-3"
              @change="getLeadsPage2(1)"
              v-model="itemsPerPage"
            >
              <option value="15">15 leads por página</option>
              <option value="30">30 leads por página</option>
              <option value="50">50 leads por página</option>
            </select>
            <template v-if="plan_name == 'Plan Basic-web0'">
              <!-- TODO Sistema de planes con flow deprecado -->
              <!-- <router-link
                class="btn-select static sm my-0 disabled sm d-flex align-items-center"
                :to="{
                  name: 'dashboard-planes',
                  query: { seccion: 'mejorar-plan', bot_id: selected_bot.id },
                }"
                >Exportar datos
                <span class="upgrade-label">Mejorar</span>
              </router-link> -->
              <span class="btn-select static sm my-0 disabled sm d-flex align-items-center">Exportar datos
                <span class="upgrade-label">Mejorar</span>
              </span>
            </template>
            <template v-else>
              <button
                class="btn-select static sm my-0"
                @click="showExportaciones = true"
                :disabled="view_permissions.export != true"
              >
                Exportar datos
                <span class="tooltip_text-sm for_disabled" v-if="view_permissions.export != true">Lo sentimos, no tienes acceso a este módulo.</span>
              </button>
            </template>
          </div>
        </div>
        <AgregarCliente
          :showModal="show_modal_addClient"
          :userbot="userbot"
          :plan_name="plan_name"
          :newClient="newClient"
          :error="error"
          @closeModal="(show_modal_addClient = false), (error = '')"
          @endMassiveLoad="show_modal_addClient = false"
          @updateAgent="updateAgent"
          @addNewClient="addNewClient"
        />
        <EnviarTemplateTags
          v-if="templates.length > 0"
          :user="user"
          :show="showTagsTemplate"
          :templates="templates"
          :listTags="listTags"
          :dataUser="auxfilterData"
          :checkedRows="checkedRows"
          @close="showTagsTemplate = false"
          @cleanSelectedLeads="checkedRows = []"
          @onFailedNumbers="onFailedNumbers"
        />
        <Exportaciones
          :showModal="showExportaciones"
          @closeModal="showExportaciones = false"
          @getLeadsPage2="getLeadsPage2(2)"
          @exportExcel="exportExcel()"
          :botIds="botIds"
        />
        <EtiquetaMasiva
          v-if="totalLeadsId"
          :show="showMassTags"
          :ids="totalLeadsId"
          :selectedIds="checkedRows"
          :listTags="listTags"
          :rows="tableDatafiltered"
          :bot="selected_bot"
          @close="showMassTags = false"
        />
        <ChatPreviewXL
          v-show="showChatPreview"
          :show="showChatPreview"
          :lead="selectedRow"
          :user="user"
          :bot="selected_bot"
          @close="showChatPreview = false"
        />
      </div>
    </FadeTransition>
    <NoAccessView
      :show="view_permissions.read != true && loading == false"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import axios from "axios";
import environment from "../../environment";
import { SlideYUpTransition, FadeTransition } from 'vue2-transitions';
import { mapState, mapMutations } from 'vuex';
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import ValidationCrm from '../Chatbot/components/validationCrm.vue';
import dashboard_api from '../../dashboard_api.js';
import dashboard_api_v2 from '../../dashboard_api_v2.js';
import '../../assets/buefy_class.scss';
import '../../assets/main.scss';
import AgregarCliente from './components/AgregarCliente.vue';
import ModalEtiquetas from '@/components/ModalEtiquetas.vue';
import Exportaciones from './components/Exportaciones.vue';
import EnviarPlantilla from '@/components/EnviarPlantilla.vue';
import EnviarTemplateTags from '@/components/EnviarTemplateTags.vue';
import EtiquetaMasiva from '@/components/EtiquetaMasiva.vue';
import moment from 'moment-strftime';
import Table from "@/components/builders/Table";
import DesarchivarConversacion from '@/components/DesarchivarConversacion.vue';
import Multiselect from "@/components/Multiselect";
import IconFlag from "@/components/IconFlag";
import ChatPreviewXL from "@/components/ChatPreviewXL";

export default {
  name: 'clients-id',
  components: {
    AgregarCliente,
    Exportaciones,
    EnviarPlantilla,
    EnviarTemplateTags,
    ValidationCrm,
    FadeTransition,
    SlideYUpTransition,
    DatePick,
    DesarchivarConversacion,
    ModalEtiquetas,
    Table,
    Multiselect,
    IconFlag,
    EtiquetaMasiva,
    ChatPreviewXL
  },
  data() {
    return {
      error: '',
      ratings: [
        {
          value: 1,
          url_img: '/img/icons/rating/1.1-bad.svg',
        },
        {
          value: 2,
          url_img: '/img/icons/rating/2.1-meh.svg',
        },
        {
          value: 3,
          url_img: '/img/icons/rating/3.1-what.svg',
        },
        {
          value: 4,
          url_img: '/img/icons/rating/4.1-nice.svg',
        },
        {
          value: 5,
          url_img: '/img/icons/rating/5.1-love.svg',
        },
      ],
      showConversaciones: false,
      showExportaciones: false,
      tooltip_messaje: {
        tomado: 'El agente respondió al usuario.',
        pendiente: 'El usuario espera respuesta.',
        terminado: 'El agente terminó la conversación.',
        sin_solicitar: 'El usuario no solicitó agente.',
      },
      leadValuesItem: [],
      templates: [],
      showPlantillas: false,
      checkable: false,
      checkedRows: [],
      selectedRow: {},
      show: false,
      tableData: [],
      auxfilterData: [],
      tableDatafiltered: [],
      expanded: false,
      edit: true,
      newedit: {
        name: false,
        email: false,
        phone: false,
        contact_time: "",
        question: false,
        rut: false,
      },

      newClient: {
        name: '',
        email: '',
        phone: '',
        contact_time: 'Temprano',
        estado: 'Pendiente',
        question: '',
        agent: '',
        rut: '',
      },

      filter_search_text: '',
      filter_state_text: '',
      filter_contact_hour_text: '',
      filter_channel_text: '',
      filter_list_text: '',
      filter_time_text: '',
      filter_email_text: '',
      loading: true,
      table_loading: true,
      selected_id: 0,
      nombre_estado: '',
      currentPage: 1,
      perPage: 5,
      show_modal_addClient: false,
      columnsVisible: {
        name: { title: 'Nombre', display: true },
        email: { title: 'Email', display: true },
        phone: { title: 'Telefono', display: true },
        rut: { title: 'Número de identificación', display: true },
        contact_time: { title: 'Horario de contacto', display: true },
        question: { title: 'Pregunta inicial', display: true },
        contact_date: { title: 'Horario de contacto', display: true },
        calification: { title: 'Calificación', display: true },
        estado: { title: 'Estado', display: true },
        channel: { title: 'Channel', display: true },
        agent: { title: 'Agente asignado', display: true },
      },
      columnsVisible2: {
        type_of_operation: { title: 'Tipo de operación', display: true },
        type_of_housing: { title: 'Tipo de casa', display: true },
        number_of_bedrooms: { title: 'Número de dormitorios', display: true },
        sqare_meter: { title: 'Metros cuadrados', display: true },
        comuna: { title: 'Comuna', display: true },
        region: { title: 'Región', display: true },
      },
      tableExport: [],
      validation: [],
      selected_columns: [],
      userbot: '',
      lead_agent: '',
      qvo: [],
      pages: 1,
      actual_page: 1,
      input_page: this.actual_page,
      errorInputPage: false,
      rangeBefore: 1,
      rangeAfter: 1,
      customProperties: [],
      filter_agent_id: '',
      tableKey: 0,
      stickyHeaders: true,
      failedNumbers: [], // numeros de telefono fallidos al enviar plantilla
      itemsPerPage: 15, // numeros de leads por pagina
      listTags: [],
      idLeadList: '',
      botIDLead: '',
      showTagsTemplate: false,
      tagsGlobal: [],
      color: [
        {
          nombre: 'Rojo',
          hex: '#F5365C',
        },
        {
          nombre: 'Verde',
          hex: '#89E585',
        },
        {
          nombre: 'Amarillo',
          hex: '#FFCD38',
        },
        {
          nombre: 'Azul',
          hex: '#2A81EF',
        },
        {
          nombre: 'Negro',
          hex: '#181818',
        },
        {
          nombre: 'Pink',
          hex: '#ffc0cb',
        },
      ],
      activeItem: null,
      totalLeadsId: [],
      showMassTags: false,
      showChatPreview: false,

      state: [
      {
        state: "venta cerrada",
        color: "#2981EF"
      },
      {
        state: "perdido",
        color: "#FF6565"
      },
      {
        state: "contactado",
        color: "#b2b2b2"
      }
      ]
    };
  },

  created() {
    window.scrollTo(0, 0);
  },

  async mounted() {
    this.failedNumbers = sessionStorage.getItem('failed_numbers')
      ? JSON.parse(sessionStorage.getItem('failed_numbers'))
      : [];
    await this.getLabelBot();
  },

  computed: {
    ...mapState(['user', 'selected_bot', 'active_view', 'plan_name', 'view_permissions', 'user_role']),

    botIds() {
      return this.user.botIds;
    },
    changeCalification() {
      return [1204, 1158, 1005, 1184].includes(this.selected_bot.id);
    },
  },

  methods: {
    ...mapMutations(['setActiveChatRooms']),

    formatedValueStateLabel(value) {
      if (value) return value.toLowerCase();
      else return 'pendiente';
    },
    findUnlinkAgent(agent) {
      if (agent) {
        return !this.userbot.some(el => el.user.name == agent);
      }
    },
    setShowChat(lead) {
      if (lead.id == this.selectedRow.id) this.showChatPreview = !this.showChatPreview;
      else {
        this.showChatPreview = true;
        this.selectedRow = lead;
      }
    },
    setChannelLabel(name) {
      switch (name) {
        case "Chatbot":
          return "Chatbot web";
        case "Whatsapp Bot":
          return "Chatbot WhatsApp";
        case "Manual":
          return "Carga manual";
        case "Carga Masiva":
          return "Carga masiva";
        default:
          return name;
      }
    },
    searchEmail() {
      this.getLeadsPage2(1);
    },

    resetInfo() {
      this.checkedRows = [];
      this.selected_columns = [];
      this.filter_time_text = '';
      this.filter_state_text = '';
      this.filter_channel_text = '';
      this.filter_list_text = '';
      this.filter_search_text = '';
    },

    async getUserBot(bot_id) {
      let userbots = (
        await dashboard_api.get(`/userbot/getallAgent/${bot_id}`)
      ).data;
      // console.log("userbots->", userbots);
      let agents = userbots.filter(el => el.role_id);
      this.userbot = agents;
    },
    deletedLead() {
      this.$swal({
        title: '¿Eliminar contactos?',
        text:
          'Los contactos seleccionados no volverán a aparecer en tu tabla de leads.',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: 'Eliminar',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          this.$swal({
            title: 'Eliminando..',
          });
          this.$swal.showLoading();

          dashboard_api
            .post('/lead/delete_lead', { leads: this.checkedRows })
            .then(result => {
              this.$swal({
                icon: 'success',
                title: 'Contactos eliminados',
                timer: 1000,
                showConfirmButton: false,
              });
            });
          let ids = this.checkedRows.map(e => e.id);
          let filter = this.tableDatafiltered.filter(
            item => !ids.includes(item.id));

          this.tableDatafiltered = filter;

          if (filter && filter.length == 0) {
            this.empty();
          }
          this.checkedRows = [];
        }
      });
    },
    async initialize(bot_id) {
      try {
        let whatsappBot = (
          await dashboard_api.get(`/bot_credential/get_by_bot_id/${bot_id}`)
        ).data;

        if (whatsappBot.length > 0) {
          this.templates = (
            await dashboard_api.get(`/payroll_messages/by_bot/${bot_id}`)
          ).data;
          this.templates = this.templates.filter(template => !template.archived);
          // sincronizando con api 360dialog - en esta vista genera error en consola
          // this.templates = await sync360DialogTemplateMessages(this.templates, bot_id);
        }
      } catch (error) {}
      // this.$swal({
      //   icon: "error",
      //   title: "Verifica tus credenciales de WhatsApp API",
      //   showConfirmButton: true
      // });
    },
    // metodo para validar el numero y poder ingresar a whatsapp
    whatsapp(phone) {
      const res = phone
        .toString()
        .substring(phone.toString().length - 8, phone.toString().length);
      window.open(`https://wa.me/569${res}`, '_blank');
    },

    // metodo para cambiar de pagina
    async getLeadsPage2(pageNumber) {
      this.actual_page = pageNumber;
      this.input_page = pageNumber;
      this.table_loading = true;

      dashboard_api_v2
        // .post('/leads/filtered_leads', {
        .post('/leads/new_filtered_leads', {
          items: this.itemsPerPage,
          page: pageNumber,
          lead: {
            contact_datetime: this.filter_time_text
              ? moment(this.filter_time_text).format('YYYY-MM-DD')
              : null,
            email: this.filter_email_text || null,
            estado: this.filter_state_text || null,
            channel: this.filter_channel_text || null,
            bot_id: this.selected_bot.id,
            // label_name: this.filter_list_text || null,
            label_id: this.filter_list_text || null,
          },
        })
        .then(async filteredLeadsResponse => {
          this.auxfilterData = await filteredLeadsResponse.data.leads;
          this.pages = filteredLeadsResponse.data.pages;
          this.auxfilterData.forEach(lead => {
            if (lead.estado != null) {
              const findState = this.setColorLead(this.formatedValueStateLabel(lead.estado));
              lead.color = findState.length > 0 ? findState[0].color : '#181818';
            }
            if (lead.contact_time == "Temprano") lead.contact_time = "AM";
            if (lead.contact_time == "Tarde") lead.contact_time = "PM";
            if (!lead.session_id) delete lead.conversation;
          });
          this.checkedRows = [];
          this.tableDatafiltered = await this.filterDataForLeads(this.auxfilterData);
          this.totalLeadsId = filteredLeadsResponse.data.ids_leads;
          // creando array de propiedades custom (si es que existen) para mostrar en CRM
          this.getCustomProperties(this.tableDatafiltered);
          this.tableKey += 1; // esto hace que el componente de la tabla se renderice de nuevo para envitar bugs
        })
        .catch(error => {
          console.log("error->", error);
        })
    },

    async filterDataForLeads(data) {
      try {
        if (this.selected_bot.is_databot_live_3) {
          const excludeConversation = data.map(({ conversation, status, ...rest }) => ({ ...rest }));
          const leadsIds = excludeConversation.map(({ id }) => id);
          const resp = await axios.post(`${environment.conversations}/conversations/by_leads`, { lead_ids: leadsIds });

          for (let lead of excludeConversation) {
            const findConversationData = resp.data.find((c) => c.conversation.lead_id == lead.id);
            lead.conversation = lead.conversation || {};

            if (findConversationData) {
              lead.conversation.calification = findConversationData.last_session?.bot_calification;
              lead.client_state = await this.findLeadState(findConversationData.last_session?.state);
            } else {
              lead.conversation.calification = 0;
              lead.client_state = "sin solicitar";
            };
          };
          if (resp.status == 200) this.table_loading = false;

          return excludeConversation; // si es databot live 3 retorno la data con la injeccion de dt live 3
        } else {
          this.table_loading = false;
          console.log("here->", data);
          return data; // si es databot live 2 retorno la data entrante
        };
      } catch (error) {
        console.log("error en filterDataForLeads", error);
        this.table_loading = false;
      };
    },

    async findLeadState(state) {
      const status = {
        pending: 'pendiente',
        taken: 'tomado',
        closed: 'terminado',
        active: 'sin solicitar'
      };
      return status[state];
    },

    inputPageSubmit(e) {
      this.errorInputPage = false;
      if (e.key === 'Enter' || e.keyCode === 13) {
        if (this.input_page >= 1 && this.input_page <= this.pages) {
          this.getLeadsPage2(Number(this.input_page));
        } else this.errorInputPage = true;
      }
    },
    // metodo que al hacer doble click permita editar los campos
    handleBlur(id, e) {
      if (!e) e = window.event;
      const self = this;
      const { value } = e.target;

      const lead_params = {};
      if (e.target.name == 'nombre') lead_params.name = value;
      if (e.target.name == 'email') lead_params.email = value;
      if (e.target.name == 'phone') lead_params.phone = value;
      if (e.target.name == 'rut') lead_params.rut = value.rut;
      if (e.target.name == 'contact_time') lead_params.contact_time = value;
      if (e.target.name == 'question') lead_params.question = value;

      dashboard_api_v2
        .put(`/leads/${id}`, { lead: lead_params })
        .then(result => {
        self.newedit.name = false;
        self.newedit.email = false;
        self.newedit.phone = false;
        self.newedit.rut = false;
        self.newedit.contact_time = false;
        self.newedit.question = false;
      }).catch(err => {
        console.log("err", err);
      });
    },

    changen(data) {
      const self = this;
      if (data === 'name' && self.newedit.name != false) {
        self.newedit.name = false;
      } else if (data === 'name' && self.newedit.name != true) {
        self.newedit.name = true;
      } else if (data === 'email' && self.newedit.email != false) {
        self.newedit.email = false;
      } else if (data === 'email' && self.newedit.email != true) {
        self.newedit.email = true;
      } else if (data === 'phone' && self.newedit.phone != false) {
        self.newedit.phone = false;
      } else if (data === 'phone' && self.newedit.phone != true) {
        self.newedit.phone = true;
      } else if (data === 'rut' && self.newedit.rut != false) {
        self.newedit.rut = false;
      } else if (data === 'rut' && self.newedit.rut != true) {
        self.newedit.rut = true;
      } else if (
        data === 'contact_time' &&
        self.newedit.contact_time != false
      ) {
        self.newedit.contact_time = false;
      } else if (data === 'contact_time' && self.newedit.contact_time != true) {
        self.newedit.contact_time = true;
      } else if (data === 'question' && self.newedit.question != false) {
        self.newedit.question = false;
      } else if (data === 'question' && self.newedit.question != true) {
        self.newedit.question = true;
      }
    },
    empty() {
      this.filter_time_text = '';
      this.filter_email_text = '';
      this.getLeadsPage2(1);
    },
    showCheckboxes() {
      const checkboxes = document.getElementById('checkboxes');
      checkboxes.style.display = this.expanded ? 'none' : 'block';
      this.expanded = !this.expanded;
    },

    setColorLead(state) {
      return this.state.filter(s => s.state == state);
    },

    addNewClient(newData) {
      const self = this;
      const session2 =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);
      const objecto = {
        conversation: {},
      };

      console.log('newClient >', self.newClient);
      const { name, phone, email } = self.newClient;

      console.log('name >', name);
      console.log('email >', email);
      console.log('phone >', phone);

      if (!name) self.error = 'empty_name';
      else if (name.length > 0 && phone.length == 0 && email.length == 0) self.error = 'empty_phone';
      else if (email.length > 0 && !email.includes('@')) self.error = 'invalid_mail';
      else {
        self.error = '';
        self.$swal({
          icon: "info",
          title: "Agregando lead...",
          showConfirmButton: false
        });
        self.$swal.showLoading();
        dashboard_api_v2
          .post('/leads/existing_lead', {
            lead: {
              bot_id: self.selected_bot.id,
              email: self.newClient.email,
              phone: self.newClient.phone.toString().includes(" ") ? self.newClient.phone.replace(/\s/g, "") : self.newClient.phone,
            }
          })
          .then(resp => {
            if (!resp.data) {
              console.log("en validacion entro al if");
              dashboard_api_v2
                .post('/leads', {
                  name: self.newClient.name,
                  email: self.newClient.email,
                  phone: self.newClient.phone.toString().includes(" ") ? self.newClient.phone.replace(/\s/g, "") : self.newClient.phone,
                  rut: self.newClient.rut,
                  contact_time: self.newClient.contact_time,
                  question: self.newClient.question,
                  bot_id: self.selected_bot.id,
                  estado: self.newClient.estado,
                  agent_id: self.newClient.agent ? self.newClient.agent : null,
                  session_id: session2,
                  channel: 'Manual',
                })
                .then(result => {
                  if (result.status == 201) {
                    const findState = self.setColorLead(result.data.estado.toLowerCase());
                    result.data.color = findState.length > 0 ? findState[0].color : '#181818';
                    self.tableDatafiltered.unshift(result.data);
                    self.getLeadsPage2(1);

                    dashboard_api
                      .post('/activity_leads', {
                        activity: {
                          agent_name: self.newClient.agent
                            ? self.userbot.filter(
                                e => e.user.id === self.newClient.agent,
                              )[0].user.name
                            : self.user.name,
                          calification: 0,
                          talk_time: 0,
                          channel: result.data.channel,
                          lead_id: result.data.id,
                        },
                      })
                      .then(_ => {
                        console.log("resp activity->", _);
                      })
                      .catch(err => {
                        console.log("err activity_leads", err);
                      });
                    self.show_modal_addClient = false;
                    self.showModalTags();
                    self.$swal({
                      icon: 'success',
                      title: 'Cliente guardado',
                      timer: 1000,
                      showConfirmButton: false,
                    });
                  }
                  self.newClient.name = '';
                  self.newClient.email = '';
                  self.newClient.phone = '';
                  self.newClient.rut = '';
                  self.newClient.contact_time = 'Temprano';
                  self.newClient.question = '';
                  self.newClient.estado = 'Pendiente';
                  self.newClient.agent = '';
                  self.getLeadsPage2(1);
                  self.showModalTags();
                })
                .catch(err => {
                  console.log("err relief addlead->", err);
                  self.$swal({
                    icon: 'error',
                    title: 'Error al crear un nuevo lead',
                });
              });
            } else {
              console.log("en validacion entro al else");
              console.log("lead->", resp);
              self.$swal({
                icon: 'error',
                title: 'El lead ya existe prueba ingresando uno distinto o actualiza el existente',
              });
            }
          }).catch(err => {
            console.log("errr existing_lead->", err);
            self.$swal({
              icon: 'error',
              title: 'Error al crear un nuevo lead',
            });
          });
      }
    },
    csvExport(arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,';
      csvContent += [
        Object.keys(arrData[0]).join(';'),
        ...arrData.map(item => Object.values(item).join(';')),
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '');

      const data = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', 'export.csv');
      link.click();
    },
    updateState(e) {
      console.log("e->", e);
      this.tableDatafiltered.forEach(lead =>
        lead.id == e.target.id ? (lead.estado = e.target.value) : '',
      );
      this.selected_id = e.target.id;
      this.nombre_estado = e.target.value;
      this.tableDatafiltered.forEach(lead => {
        const findState = this.setColorLead(this.formatedValueStateLabel(lead.estado));
        console.log("findState->", findState);

        lead.color = findState.length > 0 ? findState[0].color : '#181818';
      });

      dashboard_api
        .put(`/relief/upestado/${this.selected_id}`, {
          estado: this.nombre_estado,
        })
        .then(result => {
          this.$swal({
            icon: 'success',
            toast: true,
            position: "top-right",
            text: 'Estado cambiado',
            timer: 1000,
            showConfirmButton: false,
          });
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            title: 'Error al editar el estado del cliente',
          });
        });
    },

    updateAgent(e) {
      console.log("id->", e.target.id);
      console.log("value->", e.target.value);
      if (e) {
        this.tableDatafiltered.forEach(lead =>
          lead.id == e.target.id ? (lead.agent = e.target.value) : '',
        );
        this.selected_id = e.target.id;
        this.lead_agent = e.target.value;
        // Cambiar el agente relacionado , no se puede hacer en el watch por que si no solo cambia el select 1 vez
        dashboard_api
          .put(`/relief/upagent/${this.selected_id}`, {
            agent: this.lead_agent,
          })
          .then(result => {
            console.log('cambie en la api');
            this.$swal({
              icon: 'success',
              toast: true,
              position: "top-right",
              text: 'Agente cambiado',
              timer: 1000,
              showConfirmButton: false,
            });
          })
          .catch(err => {
            this.$swal({
              icon: 'error',
              title: 'Error al editar el estado del cliente',
            });
          });
      }
    },

    // Exportar datos del crm
    csvData() {
      return this.tableExport.map(item => {
        let toExport = {
          Nombre: item.name,
          Correo: item.email,
          Telefono: item.phone,
          Numero_de_identificacion: item.rut,
          Horario_Contacto: item.contact_time,
          Fecha_Contacto: item.contact_date_lead,
          Pregunta_Inicial: item.question,
          Calificacion: item.calification,
          Estado: item.estado,
          Canal: item.channel,
        };
        // verificando si cuenta con campos personalizados
        if (this.customProperties.length > 0) {
          // quitando posibles observers
          const raw = JSON.parse(JSON.stringify(item.custom));
          const notAllowed = ['0', '1'];
          const filtered = Object.keys(raw)
            .filter(key => !key.includes('special_'))
            .filter(key => !notAllowed.includes(key))
            .reduce((obj, key) => {
              obj[key] = raw[key];
              return obj;
            }, {});

          // combinando
          toExport = { ...toExport, ...filtered };
        }
        return toExport;
      });
    },

    exportExcel() {
      const self = this;
      let tmp_leads = [];
      const delay = ms => new Promise(res => setTimeout(res, ms));
      this.$swal({
        icon: 'info',
        title: 'Estamos generando el archivo...',
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      this.$swal.showLoading();

      dashboard_api_v2
        .post('/leads/export', {
          // page: 1,
          bot_id: this.selected_bot.id,
        })
        .then(response => {
          this.$swal({
            icon: 'info',
            title: 'El archivo será enviado a tu correo',
            showConfirmButton: false,
            allowOutsideClick: false,
            timer: 1000,
          });
        })
        .catch();
    },
    // Valida que se cumplan las 4 condiciones, solo en caso de que esten activos los filtros en cuestion
    filterOneByOneFilter(value) {
      this.tableDatafiltered = []; // Para cada evaluación, inicializa la tabla como vacía
      // Inicializa todas las funciones en true
      let isFilterTextAproved = true;
      let isFilterContactHourAprobed = true;
      let isFilterChannelAprobed = true;
      let isFilterListAprobed = true;
      let isFilterStateAproved = true;
      let isFilterDateTextAproved = true;
      //

      // Recorre todos los leads de la tabla con todos los contactos
      this.auxfilterData.forEach(lead => {
        // Valida que el filtro esté activo (No vacío)
        if (this.filter_search_text != '') {
          // Guarda en la aprobación, si el lead que se está iterando cumple o no con el filtro activo
          isFilterTextAproved = this.validateFilterInTableData(
            lead,
            this.filter_search_text.toLowerCase(),
          );
        }
        if (this.filter_contact_hour_text != '') {
          isFilterContactHourAprobed = this.validateFilterInTableData(
            lead,
            this.filter_contact_hour_text.toLowerCase(),
          );
        }
        if (this.filter_channel_text != '') {
          isFilterChannelAprobed = this.validateFilterInTableData(
            lead,
            this.filter_channel_text.toLowerCase(),
          );
        }
        if (this.filter_list_text != '') {
          isFilterListAprobed = this.validateFilterInTableData(
            lead,
            this.filter_list_text.toLowerCase(),
          );
        }
        if (this.filter_state_text != '') {
          isFilterStateAproved = this.validateFilterInTableData(
            lead,
            this.filter_state_text.toLowerCase(),
          );
        }
        if (this.filter_time_text != '') {
          // Desconstruye la fecha para armar la variable day to filter, que iguala el valor a como está en la base de datos
          const todayTime = this.filter_time_text.split('-');
          const day = todayTime[2].toString();
          const month = todayTime[1].toString();
          const year = todayTime[0].toString();

          const day_to_filter = `${day}/${month}/${year.substring(2, 4)}`;
          isFilterDateTextAproved = this.validateFilterInTableData(
            lead,
            day_to_filter,
          );
        }

        // Valida que todas las condiciones se cumplan, para agregar el elemento al array a mostrar
        if (
          isFilterTextAproved &&
          isFilterContactHourAprobed &&
          isFilterChannelAprobed &&
          isFilterListAprobed &&
          isFilterStateAproved &&
          isFilterDateTextAproved
        ) {
          this.tableDatafiltered.push(lead);
        }
      });
    },

    // Valida el cumplimiento de alguno de los campos con el valor que se pasa por parametro
    validateFilterInTableData(element, value_low) {
      return (
        (element.name
          ? element.name.toLowerCase().includes(value_low) // Valida que el elemento, en minuscula, incluya el valor a comparar
          : false) ||
        (element.email
          ? element.email.toLowerCase().includes(value_low)
          : false) ||
        (element.phone
          ? element.phone
              .toString()
              .toLowerCase()
              .includes(value_low)
          : false) ||
        (element.contact_time
          ? element.contact_time.toLowerCase().includes(value_low)
          : false) ||
        (element.question
          ? element.question.toLowerCase().includes(value_low)
          : false) ||
        (element.contact_date
          ? element.contact_date.toLowerCase().includes(value_low)
          : false) ||
        (element.estado
          ? element.estado.toLowerCase().includes(value_low)
          : false)
      );
    },
    getCustomProperties(data) {
      // se verifica si existen campos en la propiedad custom de los objetos
      let customPropertiesToShow = [];
      for (const el of data) {
        // extrayendo array de campos de propiedad "custom"
        let customProperties = Object.keys(el.custom);
        if (customProperties.length > 0) {
          // significa que si existen propiedades custom para este CRM
          // filtrando 0 y 1
          customProperties = customProperties.filter(
            el => el != '0' && el != '1',
          );
          for (const customProperty of customProperties) {
            if (
              !customPropertiesToShow.includes(customProperty) &&
              !customProperty.includes('special_')
            ) {
              customPropertiesToShow.push(customProperty);
            }
          }
        }
      }

      // eliminando posibles observers
      customPropertiesToShow = JSON.parse(
        JSON.stringify(customPropertiesToShow),
      );
      // eliminando 0 y 1
      customPropertiesToShow = customPropertiesToShow.filter(
        el => el != '0' && el != '1',
      );
      // asignando resultado para CRM
      if (customPropertiesToShow.length > 0) {
        this.customProperties = customPropertiesToShow;
      }
    },
    onFailedNumbers(failedNumbers) {
      this.failedNumbers = failedNumbers;
    },
    showModalTags(item) {
      this.activeItem = item;
    },
    closeGlobalTags(event) {
      this.activeItem = null;
    },
    async getLabelBot() {
      await dashboard_api
        .get(`/leads_label_bots/leads_label_by_bot/${this.selected_bot.id}`)
        .then(resp => {
          let data = resp.data;
          const uniqueArr = [];
          if (!data.message) {
            data.forEach(item => {
              if (!uniqueArr.find(e => e.id === item.id)) {
                uniqueArr.push(item);
              }
            });
            this.listTags = [...new Set(uniqueArr)].sort();
            // console.log('GLOBAL TAGS LISTTAGS', this.listTags);
            this.tagsGlobal = resp.data;
          }
        })
        .catch(err => console.log(err));
    },
    listTagsList(data) {
      const { tag, color, tagSelect } = data;
      dashboard_api
        .patch(
          `/label_leads/${ tagSelect.label_lead_id || tagSelect.id }`,
          {
            label_lead: {
              label_name: tag,
              label_color: color,
            },
          },
        )
        .then(res => {
          this.$swal({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text: 'Tag editado',
            timer: 1000,
            showConfirmButton: false,
          });
          for (let i = 0; i < this.listTags.length; i++) {
            if (this.listTags[i].id == res.data.id) {
              this.listTags[i].label_name = res.data.label_name;
              this.listTags[i].label_color = res.data.label_color;
            }
          }
          this.tableDatafiltered.forEach(e => {
            for (let i = 0; i < e.label_leads.length; i++) {
              if (e.label_leads[i].id == res.data.id) {
                e.label_leads[i].label_name = res.data.label_name;
                e.label_leads[i].label_color = res.data.label_color;
              }
            }
          });
        });
    },
    async deleteTagsList(tag) {
      const leads = await this.getLeadsByLabel(tag.id);
      const leadsId = [];
      leads.map(lead => {
        leadsId.push(lead.id);
      });
      const response = await this.getLabelsByLead(leadsId, this.selected_bot.id);
      const dataFiltered = [];
      response.map(e => {
        e.labels = e.labels.filter(x => x !== tag.label_name);
        dataFiltered.push(e);
      });

      dashboard_api
      .delete(
          `/label_leads/${tag.id}`,
        )
        .then(async res => {
          this.$swal({
            icon: 'success',
            title: 'Tag eliminado',
            timer: 1000,
            showConfirmButton: false,
          });
          const filterGlobalDelete = this.listTags.filter(e => e.id != tag.id);
          this.listTags = filterGlobalDelete;
          this.tableDatafiltered.forEach(e => {
            for (let i = 0; i < e.label_leads.length; i++) {
              const filterTagDelete = e.label_leads.filter(e => e.id != tag.id);
              e.label_leads = filterTagDelete;
            }
          });
          console.log('label borrado--->', res);
          await this.updateMasiveConversationLabels(dataFiltered);
        });
    },
    addNewTag(data) {
      const { tag, color, id } = data;
      dashboard_api
        .post('/label_leads', {
          label_lead: {
            label_name: tag,
            label_color: color,
            bot_id: this.selected_bot.id,
          },
        })
        .then(resp => {
          dashboard_api
            .post('/leads_label_bots/create_leads_label_bot', {
              leads_label_bot: {
                bot_id: this.selected_bot.id,
                lead_id: id,
                label_lead_id: resp.data.id,
              },
            })
            .then(async resp2 => {
              let newItem = resp2.data;
              newItem.label_name = tag;
              newItem.label_color = color;
              newItem.id = newItem.label_lead_id;
              this.tableDatafiltered.find(e => e.id === id).label_leads.push(newItem);
              this.listTags.push(newItem);
              this.tagsGlobal.push(newItem);
              const labels = await this.getLabelsByLead([id], this.selected_bot.id);
              await this.updateConversationLabels(id, labels);
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    },
    addExistingTag(data) {
      const { tag, color, id, label_element_id } = data;
      dashboard_api
        .post('/leads_label_bots/create_leads_label_bot', {
          leads_label_bot: {
            bot_id: this.selected_bot.id,
            lead_id: id,
            label_lead_id: label_element_id,
          },
        })
        .then(async resp2 => {
          let newItem = resp2.data;
          newItem.label_name = tag;
          newItem.label_color = color;
          newItem.id = newItem.label_lead_id;
          this.tableDatafiltered.find(e => e.id === id).label_leads.push(newItem);
          const response = await this.getLabelsByLead([id], this.selected_bot.id);
          await this.updateConversationLabels(id, response);
        });
    },
    async getLabelsByLead(arrayLeadId, botId) {
      try {
        const labels = await dashboard_api.post(`/leads_label_bots/leads_by_label/labels_of_lead/${ botId }`, {
          lead_id: arrayLeadId
        });
        return labels.data;
      } catch (error) {
        console.log("getLabelsByLead error=>", error);
        return [];
      }
    },
    async updateConversationLabels(leadId, labels) {
      if (!this.selected_bot.is_databot_live_3) return;
      let tags_lead = labels.map(el => el.labels).flat();

      try {
        axios.put(`${environment.URL_MICRO_CONVERSATIONS}/conversations/update_lead_tags/${ leadId }`, {
           tags_lead
        });
      } catch (error) {
        console.log("updateConversationLabels error=>", error);
      }
    },
    async getLeadsByLabel(labelId) {
      try {
        const leads = await dashboard_api.get(`/leads_label_bots/leads_by_label/${ labelId }`);
        return leads.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async removeTagFromLead(data) {
      const { id, tag, index } = data;
      dashboard_api
        .delete(
          `/leads_label_bots/destroy_leads_label_by_lead/${id}/${tag.label_lead_id || tag.id}`,
        )
        .then(async res => {
          this.tableDatafiltered.find(e => e.id === id).label_leads.splice(index, 1);
          const labels = await this.getLabelsByLead([id], this.selected_bot.id);
          await this.updateConversationLabels(id, labels);
        });
    },
    async updateMasiveConversationLabels(data) {
      console.log("bot 2", this.selected_bot);
      try {
        axios.put(`${environment.URL_MICRO_CONVERSATIONS}/conversations/update_masive_labels`, {
           data
        });
      } catch (error) {
        console.log(error);
      }
    },
    closeModal() {
      this.activeItem = null;
    }
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getLeadsPage2(1);
        this.initialize(val.id);
        this.getUserBot(val.id);
        // reset de todos los campos y filtros
        this.resetInfo();
      },
    },
    itemsPerPage(val) {
      return (this.checkedRows = []);
    },
    // funcion para el multiselect que cambia las columnas
    selected_columns(values) {
      Object.values(this.columnsVisible).forEach(element => {
        if (values.find(el => el === element.title)) {
          element.display = false;
        } else {
          element.display = true;
        }
      });
    },
    checkedRows(leadValues) {
      // console.log("checkedRows->", leadValues);
      for (let i = 0; i < leadValues.length; i++) {
        this.leadValuesItem = leadValues[i];
      }
    },
  },
};
</script>

<style lang="scss">
.tabla-clientes {
  .buefy .b-table .table {
    border: none;
  }
}
</style>

<style lang="scss" scoped>
.tabla-clientes {
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  grid-template-rows: auto 2fr 90px;

  .bg-spinner {
    position: relative;
    height: calc(80vh - 90px);
  }
  .table {
    height: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;
      margin-bottom: 1rem;
      transition: 0.5s;

      /* Cambiamos el fondo en hover */
      &:hover {
        transition: 0.5s;
        background: #cdcdcd;
      }
      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }
  .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-top: 1px solid #e8e8e8;
  }
  .icon_rating {
    width: 1.5rem;
  }
  .select-css {
    background-color: #767676;
    display: block;
    font-size: 12px !important;
    outline: none;
    font-weight: 700;
    color: #fff;
    text-align: center;
    text-align-last: center;
    letter-spacing: none;
    padding: 0.25rem 0.5rem;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: none;
    text-transform: capitalize;
    border-radius: 0.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: none !important;
    box-shadow: none !important;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 30%;
    }
  }
  .fa-whatsapp {
    font-size: 0.9rem;
    margin-right: 0.2rem;
    color: #00bb2d;

    &:hover {
      color: #767676;
      transition: 0.5s;
    }
  }
  .fa-whatsapp.red {
    color: #ff6565;
  }
  .input_page {
    width: 80px;
  }
  .no-state {
    text-transform: capitalize;
    font-size: 12px;
    color: #b2b2b2;
    display: flex;
    justify-content: center;
    border: none;
    padding: 0.25rem 0.5rem;
    margin: auto 0;
    border-radius: 0;
  }
  .tooltip_text {
    background-color: #fafafa;
    border: 1px solid #cdcdcd;
    color: #767676;
    text-transform: none;
    text-align: center;
    white-space: normal;
    font-size: 10px;
    width: 113px;
    padding: 0.25rem;
    bottom: -0.44rem;
    left: -1rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: 0;
      border-width: 0;
      border-style: none;
      border-color: none;
    }
  }
  .no-state_custom {
    width: 100px;
    justify-content: left;
  }
  .select_sm {
    font-size: 12px;
    color: #767676;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
  .column-input {
    width: auto;
    appearance: none;
    padding: 0.25rem 0.5rem;
    margin: 0;
    border: 1px solid #cdcdcd;
    box-shadow: none;
    font-family: 'Manrope', 'sans-serif';
  }
  .multiselect {
    @media (max-width: 1024px) {
      max-width: 1000px;
      width: 100%;
      font-size: 12px;
    }
    .checkbox-id {
      width: 190px;
      top: 40px;
      left: 0.5rem;
      border: 1px solid #e8e8e8;
      border-radius: 0.5rem;
      padding-bottom: 0;

      .checkboxes-item {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
        color: #767676;
        font-weight: 400;
        padding: 0.25rem 0.75rem;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
  .no_data {
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  span.tag-text {
    padding: 1px 10px 1px 10px;
  }
  .text-state-fill {
    display: inline-block;
    margin: 0 0.5rem 0 0;
  }
  .tooltip-custom {
    z-index: 4;
  }
  .width-column {
    max-width: 390px;
    overflow: hidden;
	  text-overflow: ellipsis;
  }
  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .upgrade-label {
    font-size: 10px;
    display: flex;
    align-items: center;
    border: none;
    padding: .2rem .75rem !important;
    top: 1.5rem;
    right: 0;
    height: 22px;
    cursor: pointer;
  }
  .i-btn-hover {
    opacity: 0;
    transition: .25s;
    &:hover, &.active {
      opacity: 1;
    }
  }
  .e-show:hover {
    .i-btn-hover {
      opacity: 1;
    }
  }
  .btn-text:hover {
    .tooltip_text-sm {
      width: 120px;
    }
  }
}
</style>

<template>
  <section class="table-view">
    <DataTable
      v-if="leads?.length > 0"
      :data="leads"
    />
  </section>
</template>

<script>
import DataTable from '../components/builders/table/DataTable.vue';

export default {
  components: {
    DataTable
  },
  data() {
    return {
      leads: [
        {
          id: 110,
          data: {
            checkbox: true,
            name: 'Pedro Juan Francisco',
            email: 'pedro.juan.francisco@gmail.com',
            phone: '56912345678'
          }
        },
        {
          id: 111,
          data: {
            checkbox: true,
            name: 'Pedro Juan Francisco',
            email: 'pedro.juan.francisco@gmail.com',
            phone: '56912345678'
          }
        },
        {
          id: 112,
          data: {
            checkbox: true,
            name: 'Martin Pardo',
            email: 'margin.pardo@gmail.com',
            phone: '56912345678'
          }
        },
        {
          id: 113,
          data: {
            checkbox: true,
            name: 'Ana Jorquera',
            email: 'ana.jorquera@gmail.com',
            phone: '56912345678'
          }
        },
        {
          id: 114,
          data: {
            checkbox: false,
            name: 'Pedrito José Juaquin',
            email: 'pjg@gmail.com',
            phone: '56912345678'
          }
        },
      ]
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.table-view {
  width: 100%;
  overflow: scroll;
  height: 100vh;
}
</style>
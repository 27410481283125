const { origin } = window.location;
import { localStorageGetItem } from '@/utils/utils';

let url_env = '';
let socket_url = '';
let url_env_bot = '';
let url_env_bot_front = '';
let url_env_self = '';
let RASA_URL = '';
let DATABOT_TEMPLATES = '';
let url_massive_tags = '';
let payment = '';
let jobs = '';
let URL_MICRO_CONVERSATIONS = '';
let conversations = '';
let url_automations = '';
let integrations = '';
let url_metrics = '';
let url_redis = ''

const emails = [
  'sergio@databot.cl',
  'nicolas@databot.cl',
  'victor@databot.cl',
  'camila@databot.cl',
  'matias@databot.cl',
  'fabian@databot.cl',
  'luis@databot.cl',
  'gabriela@databot.cl',
  'francisco.arancibia@unitedrentacar.cl',
  'mileydis.delacruz@clicar.cl',
  'andres.c@unitedrentacar.cl',
  'chris188p@gmail.com',
  'arodriguez@salfa.cl',
  'douglas@supletech.cl',
  'marcela.aguirre@chileanrentacar.cl',
  'maria.velazquez@chileanrentacar.cl',
  'raraya@mininuts.cl',
  'pedro@mininuts.cl',
  'taller@mininuts.cl',
  'digital@ryk.cl',
  'edwin.armijo@firmaschile.cl',
  'carla.peralta@chileanrentacar.cl',
  'databot_paola@cardinale.zendesk.com',
  'ventasonline@donacarne.cl',
  'ventasonline@darsa.cl',
  'whitney.lara@unitedrentacar.cl',
  'jorgebruno@josimar.com.ar',
  'alejandro.cortes@yumbolon.com',
  'mariab@imahe.cl',
  'callcenter2@sack.cl',
  'marcela.aguirre@chileanrentacar.cl',
  'rosita@ferreteriasweitzler.cl',
  'claudio.lemus@unitedrentacar.cl',
  'nicolas.weitzler@weitzler.cl',
  'francisco.arancibia@unitedrentacar.cl'
];

if (origin == 'https://dashboard-front-qa.herokuapp.com') {
  url_env = 'https://qapi.databot.cl';
  // url_env = "https://databot-dashboard-api-qa.herokuapp.com";
  url_env_bot = 'https://chatbot-front-qa.herokuapp.com';
  socket_url = 'https://chatbot-front-qa.herokuapp.com';
  url_env_bot_front = 'http://bot-front-qa.s3-website-us-east-1.amazonaws.com';
  url_env_self = 'https://dashboard-front-qa.herokuapp.com';
  RASA_URL = 'https://rasaia2.databot.cl';
  DATABOT_TEMPLATES = 'https://databot-templates-144c5a974b97.herokuapp.com';
  url_massive_tags = '';
  payment = 'http://localhost:3100';
  url_automations = 'https://databot-automatizaciones.rj.r.appspot.com';
  URL_MICRO_CONVERSATIONS = 'https://conversations-api-test.databot.cl';
  conversations = 'https://conversations-api-test.databot.cl';
  url_metrics = 'https://metrics-test.databot.cl';
  // jobs = 'https://sidekiqserver.databot.cl';
  integrations = 'https://integrations-app-prod.niceglacier-347ce6fc.southcentralus.azurecontainerapps.io';
  url_redis = 'https://redis-manager-442419.rj.r.appspot.com';
} else if (process.env.NODE_ENV === 'development') {
  url_env = 'http://localhost:3000';
  // url_env = emails.includes(localStorageGetItem('uid'))
  //   ? 'https://api.databot.cl'
  //   : 'https://export.databot.cl';
  socket_url = 'http://localhost:5000';
  url_env_bot = 'http://localhost:5000';
  url_env_bot_front = 'http://localhost:8000';
  url_env_self = 'https://ia.databot.cl';
  // RASA_URL = 'http://localhost:9090'; // prod to test new register
  // DATABOT_TEMPLATES = 'http://localhost:3010'; // prod to test new register
  RASA_URL = 'https://rasaia2.databot.cl';
  DATABOT_TEMPLATES = 'https://databot-templates-144c5a974b97.herokuapp.com';
  url_massive_tags = 'http://localhost:3200';
  payment = 'http://localhost:3100';
  URL_MICRO_CONVERSATIONS = 'http://localhost:3001';
  jobs = 'http://localhost:3000';
  url_automations = 'http://localhost:5500';
  conversations = 'http://localhost:3001';
  integrations = 'http://localhost:3200';
  url_metrics = 'http://localhost:3400';
  url_redis = 'http://localhost:3500';
} else {
  url_env = emails.includes(localStorageGetItem('uid'))
    ? 'https://api.databot.cl'
    : 'https://export.databot.cl';
  socket_url = 'https://chatbot-api.databot.cl';
  url_env_bot = 'https://chatbot-api.databot.cl';
  url_env_bot_front = 'https://databot-api.herokuapp.com';
  url_env_self = 'https://ia.databot.cl';
  RASA_URL = 'https://rasaia2.databot.cl';
  DATABOT_TEMPLATES = 'https://databot-templates-144c5a974b97.herokuapp.com';
  url_massive_tags = 'https://api-wsp.databot.cl';
  payment = 'http://localhost:3100';
  jobs = 'https://api.databot.cl';
  url_automations = 'https://databot-automatizaciones.rj.r.appspot.com';
  URL_MICRO_CONVERSATIONS = 'https://conversations.databot.cl';
  conversations = 'https://conversations.databot.cl';
  integrations = 'https://integrations-app-prod.niceglacier-347ce6fc.southcentralus.azurecontainerapps.io';
  // url_metrics = 'https://metrics.databot.cl';
  url_metrics = 'https://metrics-test.databot.cl';
  url_redis = 'https://redis-manager-442419.rj.r.appspot.com';
}

RASA_URL = 'https://rasaia2.databot.cl';

// url_env = 'https://fenix.databot.cl';
// url_metrics = 'https://metrics-test.databot.cl';

export default {
  url_env,
  socket_url,
  url_env_bot,
  url_env_self,
  RASA_URL,
  url_env_bot_front,
  DATABOT_TEMPLATES,
  url_massive_tags,
  payment,
  jobs,
  URL_MICRO_CONVERSATIONS,
  conversations,
  url_automations,
  integrations,
  url_metrics,
  url_redis
};

<template>
  <table class="data-table">
    <thead class="table-head-box">
      <DataColumnHead
        ref="column_border_check"
        @is_drag="is_drag"
        is_checkbox
      />
      <DataColumnHead
        label="Nombre"
        ref="column_border_name"
        @is_drag="is_drag"
      />
      <DataColumnHead
        label="Correo"
        ref="column_border_email"
        @is_drag="is_drag"
      />
      <DataColumnHead
        label="Telefono"
        ref="column_border_phone"
        @is_drag="is_drag"
      />
    </thead>
    <tbody>
      <template v-if="data.length > 0">
        <DataRow
          v-for="(item, index) in data"
          :key="index"
          :data="item.data"
        />
      </template>
      <!-- <tr class="lead_row">
        <td class="column item" column_id="name" item_id="lead_id_3331" :draggable="is_drag">
          <input type="checkbox">
        </td>
        <td class="column item" column_id="name" item_id="lead_id_3331" :draggable="is_drag">Nombre 1</td>
        <td class="column item" column_id="email" item_id="lead_id_3331">email 1</td>
        <td class="column item" column_id="phone" item_id="lead_id_3331">telefono 1</td>
      </tr>
      <tr class="lead_row">
        <td class="column item" column_id="name" item_id="lead_id_3331" :draggable="is_drag">
          <input type="checkbox">
        </td>
        <td class="column item" column_id="name" item_id="lead_id_3332">Nombre 2</td>
        <td class="column item" column_id="email" item_id="lead_id_3332">email 2</td>
        <td class="column item" column_id="phone" item_id="lead_id_3332">telefono 2</td>
      </tr> -->
    </tbody>
  </table>
</template>

<script>
import DataColumnHead from './DataColumnHead.vue';
import DataRow from './DataRow.vue';

export default {
  components: {
    DataColumnHead,
    DataRow
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      is_drag: false
    }
  },  
  computed: {
    height_size() {
      return this.width_size / 1.618;
    },
  },
  methods: {
    handleMouseDrag() {
      this.is_drag = true;
    }
  }
}
</script>

<style scoped>
.data-table {
  width: auto;
  max-width: 100vw;
  border-bottom: 1px solid #e8e8e8;
  overflow: scroll;
}
.data-table .table-head-box {
  position: sticky;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
}
</style>

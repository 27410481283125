<template>
  <section class="dashboard">
    <ValidatePlatform />
    <ValidateDefaulter />
    <FadeTransition :duration="200">
      <div class="col">
        <div class="row">
          <DataCardWelcome
            :title="formated_name"
            :model="welcome_data"
            :columns="9"
          />
          <DataCardChart
            type="banner"
            :label="'¿Más datos?'"
            :title="`Revisa todos los datos de tu chatbot aquí.`"
            :path="`/metrics`"
            :columns="3"
          />
        </div>
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="row">
              <DataCardChart
                type="number"
                title="Conversaciones"
                chart_id="current_conversations"
                :label="`En ${current_month}`"
                :current_month_value="current_month_conversations"
                :last_month_value="last_month_conversations"
                :columns="6"
                :loading="all_loadings"
              />
              <DataCardChart
                type="number"
                title="Leads"
                chart_id="current_leads"
                :label="`En ${current_month}`"
                :current_month_value="current_month_lead"
                :last_month_value="last_month_lead"
                :columns="6"
                :loading="all_loadings"
              />
            </div>
          </div>
          <div class="col-md-6 px-0">
            <div class="row">
              <DataCardChart
                type="number"
                lang="es"
                title="Calificación promedio"
                chart_id="califications_average"
                :label="`En ${current_month}`"
                :current_month_value="current_month_calification_average"
                :last_month_value="last_month_calification_average"
                :columns="6"
                :loading="all_loadings"
              />
              <DataCardChart
                type="number"
                title="Leads fuera de horario"
                chart_id="out_hour_leads"
                :label="`En ${current_month}`"
                :current_month_value="current_lead_out_hour"
                :last_month_value="last_lead_out_hour"
                :columns="6"
                :loading="all_loadings"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <DataChart
            :disabled="no_data"
            id="get_conversations"
            name="Conversaciones mensuales"
            label="Últimos 6 meses"
            type="bar"
            :column="6"
            tooltip="Conteo de conversaciones realizadas dentro del periodo seleccionado, agrupadas por mes."
          />
          <DataChart
            :disabled="no_data"
            id="get_leads"
            name="Leads mensuales"
            type="bar"
            :column="6"
            tooltip="Conteo de leads calificados (Con nombre y número de teléfono o correo) obtenidos dentro del periodo seleccionado. Incluye registros obtenidos desde el chatbot, cargas masivas etc., agrupados por mes."
          />
        </div>
        <div class="row justify-content-center my-5">
          <router-link to="/metrics" class="link">
            Ver todas las métricas ->
          </router-link>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>

<script>
import axios from 'axios';
import enviroment from '@/environment';
import dashboard_api from '@/dashboard_api.js';
import { FadeTransition } from "vue2-transitions";
import ValidatePlatform from "@/components/ValidatePlatform";
import ValidateDefaulter from "@/components/ValidateDefaulter";
import { mapState, mapMutations } from "vuex";
import DataCardWelcome from '../../components/builders/DataCardWelcome';
import DataCardChart from '../../components/builders/DataCardChart';
import DataChart from '../../components/builders/DataChart';
import BarChart from './../Metrics/charts/BarChart';
import donutChartSm from './../Metrics/charts/donutChartSm';

export default {
  components: {
    FadeTransition,
    DataCardWelcome,
    DataCardChart,
    DataChart,
    ValidatePlatform,
    ValidateDefaulter
  },
  mixins: [
    BarChart,
    donutChartSm
  ],
  data() {
    return {
      no_data: [],
      all_loadings: [],
      current_month_conversations: 0,
      last_month_conversations: 0,
      current_month_lead: 0,
      last_month_lead: 0,
      current_lead_out_hour: 0,
      last_lead_out_hour: 0,
      current_month_calification_average: 0,
      last_month_calification_average: 0,
      welcome_data: []
    }
  },
  created() {
    window.scrollTo(0, 0);
    const self = this;
    self.actual_date = new Date();
    self.cleanRegisterData();
    self.cleanCheckoutData();

    if (!self.logged == "true" || self.user.id == 0) {
      self.$swal({
        icon: "info",
        title: "Aquí puedes entrar a tu dashboard",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false
      });
      self.$router.push("/login");
    }

    if (this.queries && this.queries.status == "successCreditCard") {
      this.$swal({
        icon: "success",
        title: "Tarjeta agregada con éxito"
      });
    }

    if (this.queries && this.queries.status == "failureCreditCard") {
      this.$swal({
        icon: "error",
        title: "Ha ocurrido un problema con la tarjeta",
        text: "Tarjeta rechazada"
      });
    }
    this.getProviderCredential();
  },
  computed: {
    ...mapState(["logged", "user", "active_view", "selected_bot", "platform_name", "skip"]),

    formated_name() {
      const split_name = this.user.name.split(' ');
      let message = '';
      split_name.pop();

      if (split_name[split_name.length - 1] == 'a') message = 'Bienvenida';
      else if (split_name[split_name.length - 1] == 'o') message = 'Bienvenido';
      else message = 'Bienvenida/o';

      return message + ' ' + split_name.join(' ');
    },
    current_month() {
      return this.$moment().locale('es').format('MMMM')
    },
    add_month_format() {
      let month = this.$moment().format('MMM');
      if (this.$moment().format('MMM') == 'Dec' ) month = 'Dic';
      if (this.$moment().format('MMM') == 'Jan' ) month = 'Ene';
      if (this.$moment().format('MMM') == 'Apr' ) month = 'Abr';

      return month + ' ' + this.$moment().format('YYYY');
    }
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(bot) {
        this.getConversations();
        this.getLeads()
        this.getCalificationsAverage();
        this.getLeadOutTime();
        if (bot.is_databot_live_3) this.getTodayData();
        else this.getDlive2TodayData();
      }
    }
  },
  methods: {
    ...mapMutations([
      "cleanRegisterData",
      "cleanCheckoutData",
      "setRegisterProcess",
      "toggleSkipGuide"
    ]),
    getTodayData() {
      axios
        .get(`${enviroment.url_metrics}/api_conversations/dashboard_home/${this.selected_bot.id}`)
        .then(resp => {
          if (resp.data.status != false) {
            if (this.selected_bot.id == resp.data.data.bot_id) {
              this.welcome_data.push(
                { label: 'Conversaciones hoy', value: resp.data.data.total_conversations },
                { label: 'Leads hoy', value: resp.data.data.total_leads }
              )
            }
          } else {
            this.welcome_data.push(
              { label: 'Conversaciones hoy', value: 0 },
              { label: 'Leads hoy', value: 0 }
            )
          }
        })
        .catch(err => {
          console.log("errro", err);
        })
    },
    getConversations() {
      this.all_loadings.push('current_conversations');
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/sessions_data' : 'conversation_metrics/conversations';

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: 3,
          chart: 'get_conversations'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data;
            const months = new_data.map(el => el.month).reverse();
            const data = new_data.map(el => el.quantity).reverse();

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              data.push(0);
              new_data.unshift({month: this.add_month_format, quantity: 0, id: null});
            };

            this.current_month_conversations = new_data[0]?.quantity;
            this.last_month_conversations = new_data[1]?.quantity;

            this.barChart(months, data, 'get_conversations', 'Conversaciones mensuales', 'Conversaciones');
            this.donutChartSm('current_conversations', this.last_month_conversations, this.current_month_conversations);
          } else {
            this.no_data.push('get_conversations', 'current_conversations');
          };
        })
        .catch(err => {
          this.no_data.push('get_conversations', 'current_conversations');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'current_conversations');
          // this.getConversationsResolvedByBot();
        })
    },
    getLeads() {
      this.all_loadings.push('current_leads');

      axios
        .post(`${enviroment.url_metrics}/lead_metrics/count_leads`, {
          bot_id: this.selected_bot.id,
          filter: 3,
          chart: 'get_leads'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data;
            const months = new_data.map(el => el.month).reverse();
            const data = new_data.map(el => el.quantity).reverse();

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              data.push(0);
              new_data.unshift({month: this.add_month_format, quantity: 0, id: null});
            };

            this.current_month_lead = new_data[0]?.quantity;
            this.last_month_lead = new_data[1]?.quantity;

            this.barChart(months, data, 'get_leads', 'Leads mensuales', 'Leads');
            this.donutChartSm('current_leads', this.last_month_lead, this.current_month_lead);
          } else {
            this.no_data.push('get_leads', 'current_leads');
          };
        })
        .catch(err => {
          this.no_data.push('get_leads', 'current_leads');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'current_leads');
        })
    },
    getCalificationsAverage() {
      const url = this.selected_bot.is_databot_live_3 ? 'api_conversations/session_calification_received' : 'conversation_metrics/califications_received';
      this.all_loadings.push('califications_average');

      axios
        .post(`${enviroment.url_metrics}/${url}`, {
          bot_id: this.selected_bot.id,
          filter: 3,
          chart: 'bot_average_calification'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data.reverse();
            const months = new_data.map(el => el.month);
            const data = new_data.map(el => el.average_calification);

            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              new_data.push({ month: this.add_month_format, average_calification: 0 });
              data.push(0);
            };

            this.current_month_calification_average = Number(data[data.length - 1]);
            this.last_month_calification_average = Number(data[data.length - 2]);

            this.donutChartSm('califications_average', this.last_month_calification_average, this.current_month_calification_average);
          } else {
            this.no_data.push('califications_average', 'bot_average_calification');
          };
        })
        .catch(err => {
          this.no_data.push('califications_average', 'bot_average_calification');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'califications_average');
        })
    },
    getLeadOutTime() {
      this.all_loadings.push('out_hour_leads');
      axios
        .post(`${enviroment.url_metrics}/lead_metrics/leads_out_time`, {
          bot_id: this.selected_bot.id,
          filter: 3,
          chart: 'leads_out_time'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const new_data = resp.data.data.reverse();
            const months = new_data.map(el => el.month);
            const first_series = new_data.map(el => el.leads_out_schedule_count);
            const second_series = new_data.map(el => el.leads_in_schedule_count);
            const names_series = ['Fuera de horario', 'Dentro de horario'];

            let add_total_in = 0;
            let add_total_out = 0;
            let totals = [];

            resp.data.data.forEach(ele => {
              add_total_in = add_total_in + ele.leads_in_schedule_count;
              add_total_out = add_total_out + ele.leads_out_schedule_count;
            });
            totals.push(
              { name: 'Fuera de horario', value: add_total_out },
              { name: 'Dentro de horario', value: add_total_in }
            );
            // this.donutChart('leads_and_hours', 'Leads y horarios de atención', 'En porcentaje', totals);

            // valida si no existe data en mes actual lo agrega con valor 0
            if (!months.includes(this.add_month_format)) {
              months.push(this.add_month_format);
              first_series.push(0);
              second_series.push(0);
              new_data.push({ month: this.add_month_format, leads_out_schedule_count: 0, leads_in_schedule_count: 0 })
            };
            this.current_lead_out_hour = new_data[new_data.length - 1]?.leads_out_schedule_count;
            this.last_lead_out_hour = new_data[new_data.length - 2]?.leads_out_schedule_count;

            this.donutChartSm('out_hour_leads', this.last_lead_out_hour, this.current_lead_out_hour);
          } else {
            this.no_data.push('leads_and_hours');
          };
        }).catch(err => {
          this.no_data.push('leads_and_hours');
          console.log("errro", err);
        })
        .finally(() => {
          this.all_loadings = this.all_loadings.filter(el => el != 'out_hour_leads');
        });
    },
    show() {
      this.toggleSkipGuide();
    },
    async getProviderCredential() {
      try {
        let resp = (
          await dashboard_api.get(`/user_credentials/search_for_bot/${this.selected_bot.id}`, { headers: { BOT_TOKEN: this.selected_bot.token, BOT_ID: this.selected_bot.id } })
        ).data;
        resp.length > 0 ? (this.providerSite = resp[0].provider) : (this.providerSite = false);
        if (this.providerSite) {
          switch (this.providerSite) {
            case "vtex":
              this.statisticsEcommerce = true;
              break;
            case "shopify":
              this.statisticsEcommerce = true;
              break;
            case "woocommerce":
              if (this.selected_bot.id != 756) {
                this.statisticsEcommerce = true;
              }
              break;
            default:
              break;
          }
        } else {
          this.statisticsEcommerce = false;
        }
      } catch (error) {
        console.log("PROVIDER WRONG", error);
      }
    },
    getDlive2TodayData() {
      const date = this.$moment().format("YYYY-MM");

      if (bot?.id) {
        dashboard_api
          .post("/dashboards/current_month_data", {
            dashboard: {
              period: date,
              bot_id: bot.id
            },
            owner: this.active_view
          })
          .then(({ data }) => {
            this.welcome_data.push(
              { label: 'Conversaciones hoy', value: data?.daily?.conversations || 0 },
              { label: 'Leads hoy', value: data?.daily?.leads || 0 }
            );
          })
          .catch(error => console.log("error -> ", error));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  margin: 1rem 0 5rem;
}
</style>

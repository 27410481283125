<template>
  <section
    :key="selected_bot.id"
    :class="{
      history_conversations: true,
      history_item3: show_client_info,
    }"
  >
    <div
      :class="{
        'item item-1 pb-0': true,
        hidden: showMobileViewChat || show_client_info,
        showMobileMode: !showMobileViewChat && !show_client_info,
      }"
    >
      <ChatCards
        :conversations="conversations"
        :tags="tags"
        :lead="lead"
        :isLoadingConversations="isLoadingConversations"
        :itemsPerPage="itemsPerPage"
        :page="page"
        :pages="pages"
        :selectedConversation="selectedConversation"
        @onSelectedConversation="selectConversation"
        @onAddCategoryFilter="selectedTags = $event"
        @onSearchChat="onSearchChat($event, 1)"
        @changePage="page = $event"
        @changeItemsPerPage="itemsPerPage = $event"
        @sendDate="getFilterDate"
      />
    </div>
    <div
      :class="{
        'item item-2 fadeItemMobile': true,
        hidden: !showMobileViewChat,
        showMobileMode: showMobileViewChat,
      }"
    >
      <ViewConversation
        :selectedConversation="selectedConversation"
        :sessions="sessions"
        :isLoadingSessions="isLoadingSessions"
        :itemsPerPageSession="itemsPerPageSession"
        :pageSession="pageSession"
        :pagesSession="pagesSession"
        :totalSessions="totalSessions"
        @changePage="pageSession = $event"
        @changeItemsPerPage="itemsPerPageSession = $event"
        @openItem3="openItem3"
        @changeDate="selectedDate = $event"
        @closeMobile="showMobileViewChat = false"
      />
    </div>
    <div
      v-show="show_client_info"
      :class="{
        'item item-3 fadeItemMobile': true,
        hidden: !show_client_info,
        showMobileMode: show_client_info,
      }"
    >
      <InfoSession
        :isLoadingInfoSession="isLoadingInfoSession"
        :show="true"
        :selectedConversation="selectedConversation"
        :type="sessionType"
        :sessionData="sessionData"
        :sessionId="sessionId"
      />
    </div>
  </section>
</template>

<script>
import axios from "axios"
import { mapState, mapMutations } from 'vuex';
import ChatCards from './components/ChatCards';
import ViewConversation from './components/ViewConversation';
import InfoSession from './components/InfoSession';
import dashboard_api from '@/dashboard_api.js';
import environment from '@/environment.js';

export default {
  components: {
    ChatCards,
    ViewConversation,
    InfoSession
  },
  data() {
    return {
      showMobileViewChat: false,
      conversations: [],
      tags: [],
      lead: { id: null },
      isLoadingConversations: false,
      isLoadingSessions: false,
      itemsPerPage: '15',
      page: 1,
      pages: 0,
      searchText: '',
      sessions: [],
      selectedConversation: null,
      itemsPerPageSession: '15',
      pageSession: 1,
      pagesSession: 0,
      totalSessions: 0,
      sessionType: null,
      sessionData: null,
      sessionId: null,
      selectedTags: [],
      selectedDate: null,
      isLoadingInfoSession: false,
      dateInit: "",
      dateEnd: ""
    }
  },
  async mounted() {
    await this.initialize();
  },
  computed: {
    ...mapState([
      'user',
      'selected_bot',
      'show_client_info'
    ]),
  },
  watch: {
    async page() {
      try {
        this.isLoadingConversations = true;
        await this.getConversations()
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async itemsPerPage() {
      try {
        this.isLoadingConversations = true;
        await this.getConversations()
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async searchText() {
      try {
        this.isLoadingConversations = true;
        await this.getConversations()
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async pageSession() {
      await this.getSessions(this.selectedConversation.id)
    },
    async itemsPerPageSession() {
      await this.getSessions(this.selectedConversation.id)
    },
    async selectedTags(){
      try {
        this.isLoadingConversations = true;
        await this.getConversations()
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async selectedDate(){
      await this.getSessions(this.selectedConversation.id)
    }
  },
  methods: {
    ...mapMutations(['setShowClientInfo']),

    async getFilterDate(event) {
      this.dateInit = event.init;
      this.dateEnd = event.end;

      try {
        let baseUrl = `${environment.URL_MICRO_CONVERSATIONS}/history_conversations/conversations`;
        let payload = {
          "items": this.itemsPerPage,
          "page": this.page,
          "bot_id": this.selected_bot.id,
          "from": event.init,
          "to": event.end
        }
        if(this.searchText) payload['search_text'] = this.searchText
        if(this.selectedTags.length > 0) payload['tags_lead'] = this.selectedTags
        const response = await axios.post(baseUrl, payload)
        this.conversations = response.data.data
        this.pages = response.data.pages
      } catch (error) {
        console.log("Error getConversations => ", error);
      }
    },
    async openItem3(event){
      try {
        const {type, value} = event
        this.isLoadingInfoSession = true
        if(!this.show_client_info) {
          this.setShowClientInfo(!this.show_client_info)
        }
        this.sessionId = value
        if(type == 'messages') {
          this.sessionData = await this.getSessionMessages(value)
        }
        if(type == 'files') {
          this.sessionData = await this.getSessionFiles(value)
        }
        if(type == 'recommender') {
          this.sessionData = await this.getSessionRecommenders(value)
        }
        this.sessionType = type
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingInfoSession = false
      }
    },
    async getSessionMessages(id) {
      try {
        let baseUrl = `${environment.URL_MICRO_CONVERSATIONS}/history_conversations/logs_by_sessions/${id}`;
        const response = await axios.get(baseUrl)
        return response.data
      } catch (error) {
        console.log("Error getConversations => ", error);
      }
    },
    async getSessionRecommenders(id) {
      try {
        let baseUrl = `${environment.URL_MICRO_CONVERSATIONS}/history_conversations/recommender_by_sessions/${id}`;
        const response = await axios.get(baseUrl)
        return response.data
      } catch (error) {
        console.log("Error getConversations => ", error);
      }
    },
    async getSessionFiles(id) {
      try {
        let baseUrl = `${environment.URL_MICRO_CONVERSATIONS}/history_conversations/files_by_sessions/${id}`;
        const response = await axios.get(baseUrl)
        return response.data
      } catch (error) {
        console.log("Error getConversations => ", error);
      }
    },
    async selectConversation(conversation){
      this.showMobileViewChat = true;
      this.getSessions(conversation.id);
      this.selectedConversation = conversation;
      this.setShowClientInfo(false);
      this.selectedDate = null;
    },
    async initialize() {
      try {
        this.isLoadingConversations = true;
        await this.getConversations();
        await this.getTags();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async getConversations() {
      try {
        let baseUrl = `${environment.URL_MICRO_CONVERSATIONS}/history_conversations/conversations`;
        let payload = {
          "items": this.itemsPerPage,
          "page": this.page,
          "bot_id": this.selected_bot.id,
          "from": this.dateInit,
          "to": this.dateEnd
        }
        if(this.searchText) payload['search_text'] = this.searchText
        if(this.selectedTags.length > 0) payload['tags_lead'] = this.selectedTags
        const response = await axios.post(baseUrl, payload)
        this.conversations = response.data.data
        this.pages = response.data.pages
      } catch (error) {
        console.log("Error getConversations => ", error);
      }
    },
    async getSessions(conversation){
      try {
        this.isLoadingSessions = true
        let baseUrl = `${environment.URL_MICRO_CONVERSATIONS}/history_conversations/session_by_conversations`;
        let payload = {
          "items": this.itemsPerPageSession,
          "page": this.pageSession,
          "conversation_id": conversation
        }
        if(this.selectedDate?.init) payload['from'] = this.selectedDate.init
        if(this.selectedDate?.end) payload['to'] = this.selectedDate.end
        const response = await axios.post(baseUrl, payload)
        this.sessions = response.data.data
        this.pagesSession = response.data.pages
        this.totalSessions = response.data.count
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingSessions = false
      }
    },
    async onSearchChat(searchChat) {
      this.page = 1
      this.searchText = searchChat
    },
    async getTags() {
      dashboard_api
        .get(`/label_leads/by_bot/${this.selected_bot.id}`)
        .then((response) => {
          this.tags = response.data;
        })
        .catch((error) => console.log(error));
    }
  }
}
</script>

<style lang="scss" scoped>
.history_conversations {
  position: relative;
  display: grid;
  grid-template-columns: 460px calc(100% - 460px);
  background-color: #fff;
  max-height: 100%;
  overflow: hidden;
  height: calc(100vh - 80px);

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .item-1 {
    position: inherit;
    padding: 1.5rem 1rem;
    border-right: 1px solid #e8e8e8;
    background-color: #fff;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;
    z-index: 1;

    @media (max-width: 1600px) {
      padding: 1.5rem 0.25rem;
    }

    @media (max-width: 1279px) {
      padding: 1.5rem 1rem;
    }

    @media (max-width: 1024px) {
      border-right: 0;
    }
  }

  .item-2 {
    position: inherit;
    padding: 0;
    background-color: #fff;
    border-right: 1px solid #e8e8e8;
    max-height: calc(100vh - 80px);
    overflow-y: hidden;

    @media (max-width: 1279px) {
      z-index: 2;
    }

    @media (max-width: 1024px) {
      border-right: 0;
    }
  }

  .item-3 {
    position: inherit;
    padding: 1.5rem 0;

    @media (max-width: 1600px) {
      padding: 1.5rem 0.25rem;
    }

    @media (max-width: 1279px) {
      z-index: 3;
    }
  }

  .fadeItemMobile {
    animation-name: test;
    animation-duration: 0.25s;
  }

  .fadeLeaveItemMobile {
    animation-name: test2;
    animation-duration: 0.25s;
  }

  .showMobileMode {
    @media (max-width: 1279px) {
      display: inline-block !important;
      height: 100%;
    }
  }

  .hidden {
    @media (max-width: 1279px) {
      display: none !important;
    }
  }
}
.history_item3 {
  grid-template-columns: 460px 2fr 460px;

  @media (max-width: 1600px) {
    grid-template-columns: 380px 2fr 380px;
  }
  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
@keyframes test {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes test2 {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}
</style>

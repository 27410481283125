<template>
  <div class="drag_copy_paste_input">
    <!-- before if $store.state.conversationsModule.selectedState -->
    <FadeTransition :duracion="200" :key="selectedChat.id">
      <div class="card-footer-2">
        <div v-if="selectedChat.state !== 'taken'">
          <button
            v-if="isWspChannel && timeRemaining < 1"
            class="btn btn-base second"
            @click="$emit('setShowPlantillas', true)"
          >
            <i class="fas fa-paper-plane" />Enviar plantilla
          </button>
          <button
            v-else
            class="btn btn-base second"
            :disabled="!modal.id"
            @click="$emit('sendConnectAgent', true)"
          >
            Responder
          </button>
        </div>
        <!-- Bloquea el input al admin/propietario hasta que apriete el botón de intervenir -->
        <div v-else-if="(!interruptedConversations.find(e => e.chat_id == selectedChat.id) && (user_role == 'admin' || user_role == 'propietario') && (selectedChat.agent_id != user.id))">
          <button
            class="btn btn-base second"
            :disabled="!modal.id"
            @click="$emit('interruptConversation', selectedChat)"
          >
            Intervenir
          </button>
        </div>
        <template v-else>
          <button
            v-if="isWspChannel && timeRemaining < 1"
            class="btn btn-base second"
            @click="$emit('setShowPlantillas', true)"
          >
            <i class="fas fa-paper-plane" />Enviar plantilla
          </button>
          <div v-else class="input_box">
            <div class="item">
              <IconFileUpload
                v-show="!recording"
                v-if="hideItems"
                :bot="selected_bot"
                :chat="selectedChat"
                @result="setMessageFile"
              />
            </div>
            <div class="item item-2">
              <!-- To drag file -->
              <template v-if="!recording && !audio_ready">
                <div v-if="on_drag_element">
                  <input
                    type="file"
                    id="file-input"
                    :accept="acceptFile"
                    :class="{
                      'input_drop-file-box': true,
                      'droped-file': on_drag_element && paste_file.name,
                      'file-loading': loading_img_paste
                    }"
                    @change="dropFile($event)"
                  />
                </div>
                <div
                  v-else
                  :class="{
                    'textarea_box to_scroll_bar': true,
                    disabled: show_input_text || loading_img_paste,
                  }"
                  @click="$emit('updateEmojis', new_show_emoji = false)"
                >
                  <SpinnerLoading v-if="loading_img_paste" />
                  <template v-else>
                    <span v-if="show_input_text" class="p-text-custom text_sm text-center">Presiona <span class="link">Enter</span> para enviar o <span class="link">Esc</span> para cancelar</span>
                    <div
                      :class="{ div_input: true, img_format: show_input_text }"
                      :key="refresh"
                      @input="textImgInput"
                      @keydown.enter="($emit('PreventLine', $event), refresh++)"
                      contenteditable="true"
                      placeholder="Escribe aquí"
                      id="paste"
                    />
                  </template>
                </div>
              </template>
              <template v-else-if="!audio_ready">
                <div class="info_box bg_grey">
                  <p class="text-center mb-0"><span class="recording_bullet mr-1"></span> Grabando audio..</p>
                </div>
              </template>
              <template v-else>
                <div class="info_box bg_grey">
                  <p class="text-center mb-0"><span class="fas fa-check link mr-1"></span>Audio listo. Presiona el botón de <span class="link">enviar</span></p>
                </div>
              </template>
            </div>
            <div class="item">
              <template v-if="hideItems">
                <span
                  @click="$emit('updateEmojis', new_show_emoji = !new_show_emoji)"
                  :class="{
                    'i-btn i-btn-white btn-emoji': true,
                    'fas fa-times btn-select-active': new_show_emoji,
                    'i-emoji': !new_show_emoji,
                  }"
                >
                </span>
                <VEmojiPicker v-show="new_show_emoji" @select="setSelectEmoji" />
              </template>
              <template v-else>
                <span class="i-btn i-delete btn-delete" @click="deletePasteImg"></span>
              </template>
            </div>
            <div class="item">
              <template v-if="new_message_chat.length">
                <span
                  @click="sendClickButton"
                  class="i-btn i-btn-second i-send btn-send"
                  ></span>
                </template>
                <template v-else-if="!paste_file.name">
                  <IconAudioUpload
                    :conversation="selectedChat"
                    :bot="selected_bot"
                    @setMessage="setMessage"
                    @result="$emit('sendMessageAudio', $event)"
                    @isRecording="recording = $event"
                  />
                </template>
            </div>
          </div>
        </template>
        <template v-if="showRestrictionsBots">
          <DataFloatMenu
            v-show="show_answers_menu"
            :items="searchedAnswers"
            :loading_answers="loading_answers"
            @close="show_answers_menu = false"
            @selected="setSelectedFastAnswer($event)"
            @search="search_answer = $event"
          />
        </template>
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions/dist/vue2-transitions.cjs';
import IconAudioUpload from '@/views/V2/DatabotLive/components/IconAudioUpload.vue';
import IconFileUpload from './IconFileUpload';
import { VEmojiPicker } from 'v-emoji-picker';
import { mapState } from 'vuex';
import conversations_api from "@/conversations_api.js";
import dashboard_api from '@/dashboard_api.js';
import DataFloatMenu from '../../../../components/builders/DataFloatMenu.vue';

export default {
  props: [
    'selectedChat',
    'showPlantillas',
    'checkActiveChat',
    'isWspChannel',
    'timeRemaining',
    'modal',
    'textInput',
    'message_chat',
    'showEmojis'
  ],
  components: {
    FadeTransition,
    IconAudioUpload,
    IconFileUpload,
    VEmojiPicker,
    DataFloatMenu
  },
  data() {
    return {
      loading_img_paste: false,
      show_input_text: false,
      is_paste: false,
      new_message_chat: this.message_chat,
      new_show_emoji: this.showEmojis,
      on_drag_element: false,
      paste_file: {},
      total: '',
      refresh: 0,
      acceptFile: ['image/jpg', 'image/png', 'image/jpeg', 'application/pdf', '.xlsx', '.xls'],
      recording: false,
      show_answers_menu: false,
      answers: [],
      selected_answer: {},
      search_answer: '',
      searchedAnswers: [],
      loading_answers: false
    }
  },
  async mounted() {
    this.globalEnter();
    this.globalEsc();
    this.getDragItem();
    this.getDropItem();
    this.getESCevent();
    await this.getAnswers()
  },
  computed: {
    ...mapState(['selected_bot', 'audio_ready', 'interruptedConversations', 'user_role', 'user']),

    showRestrictionsBots() {
      return [7, 64, 2413].includes(this.selected_bot.id)
    },
    sortedAnswers() {
      return this.answers.sort((a, b) => b.id - a.id)
    },
    hideItems() {
      return !this.paste_file.name;
    },
  },
  watch: {
    // async show_answers_menu(val) {
    //   if (val == true) await this.getAnswers();
    // }
  },
  methods: {
    // Cuando se selecciona una respuesta
    setSelectedFastAnswer(val) {
      console.log('val->', val);
      if (val.body.length > 0) {
        let div_img = document.getElementById('paste');
        let item = document.createTextNode(val.body);

        this.new_message_chat = this.new_message_chat.replace('/', '');
        div_img.appendChild(item);

        this.new_message_chat += val.body;
        this.$emit('addItemsToMessage', this.new_message_chat);
        this.show_answers_menu = false;
        div_img.focus();
        // for send to click event
        // this.$emit('imTyping', this.new_message_chat);

        // let custom_event = {
        //   keyCode: 13,
        //   click: true
        // }
        // this.$emit('PreventLine', custom_event);
      }
    },
    getESCevent() {
      document.addEventListener("keydown", (event) => {
        if (event.key === "Escape") this.show_answers_menu = false;
      })
    },
    async getAnswers() {
      this.loading_answers = true;
      this.answers = (await dashboard_api.get(`/speed_notes/speed_notes_by_bot/${this.selected_bot.id}`)).data;
      this.loading_answers = false;
      console.log('resp->', this.answers);
    },
    setMessage(event) {
      this.$emit('sendMessage', event);
    },
    sendClickButton() {
      if (this.new_show_emoji && this.new_message_chat.length > 0) {
        this.$emit('updateEmojis', this.new_show_emoji = false)
      };
      this.$emit('sendChatMessage', this.new_message_chat)
      this.new_message_chat = '';
      this.refresh++;
    },
    setSelectEmoji(emoji) {
      let div_img = document.getElementById('paste');
      let em = document.createTextNode(emoji.data);
      div_img.appendChild(em);
      this.new_message_chat += emoji.data;
      this.$emit('addItemsToMessage', this.new_message_chat);
    },
    textImgInput(event) {
      let regex = /^\/[.\S]|\s\/[.\S]/;
      let div_img = document.getElementById('paste');

      console.log('event.data->', event.target.innerHTML);
      // mostrar respuestas rápidas ingresando '/'
      if (this.showRestrictionsBots) {
        if (event.target?.innerText.startsWith('/')) {
          this.show_answers_menu = true;
          let cleanText = event.target?.innerText.replace('/', '').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

          console.log('cleanText->', cleanText);
          this.searchedAnswers = event.data == '/' ? this.sortedAnswers : this.answers.filter(answer => answer.title.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(cleanText));

          if (event.target?.innerHTML == '<br>') this.show_answers_menu = false;
          // if (event.data == '/') this.show_answers_menu = true;
          // if (!event.data && this.show_answers_menu) this.show_answers_menu = false;

          this.new_message_chat = event.target?.innerText.replace(regex, '').trim();
        } else {
          this.new_message_chat = event.target?.innerText;
        };
      } else {
        this.new_message_chat = event.target?.innerText;
      }
      if (event.target?.innerHTML == '<br>') {
        this.new_message_chat = '';
        div_img.innerText = '';
      };
      if (event.inputType == 'insertFromPaste') {
        // Al pegar elemento y enviar mensaje el html se rompe quitando un elemento
        // key que valida si es pegado o no antes de refrescar componente o se pierde el elemento focus del div input
        this.is_paste = true;
        if (!event.target.innerText) {
          let img = div_img.querySelector('img');
          if (img) {
            this.new_message_chat = '';
            this.show_input_text = true;
            img.style.cssText = "position: relative; display: flex; justify-content: center; top: -12rem; right: 0px; left: 0px; max-width: 480px; width: 100%; height: 250px; background-color: #f2f2f2; object-fit: contain; padding: 1rem; border: 1px solid rgb(205, 205, 205); border-radius: 1rem; margin: 0 auto 2rem; box-shadow: rgb(222, 237, 255) 0px 0px 60px;";
            this.dataUrlToFile(img.src);
            div_img.blur();
            div_img.removeAttribute('contenteditable');
          } else {
            this.show_input_text = false;
            this.refresh++;
          };
        } else {
          this.new_message_chat = event.target?.innerText;
          div_img.innerHTML = this.new_message_chat;
        }
      } else {
        this.is_paste = false;
      }
      this.$emit('imTyping', this.new_message_chat);
    },
    dataUrlToFile(img_url) {
      fetch(img_url)
        .then(res => res.blob())
        .then(blob => {
          this.paste_file = new File([blob], `Captura-pantalla-${this.$moment().format('DD_MM_YYYY-HH:mm.png')}`, { type: "image/png" })
          // console.log("paste_file->", this.paste_file);
        });
    },
    dropFile(event) {
      if (event.target.files.length >= 1) this.paste_file = event.target.files[0];
      else this.paste_file = {};
    },
    deletePasteImg() {
      this.paste_file = {};
      this.show_input_text = false;
      this.on_drag_element = false;
      this.refresh++;
    },
    setMessageFile(event) {
      this.on_drag_element = false;
      this.show_input_text = false;
      this.paste_file = {};
      this.$emit('setMessageFile', event);
      this.loading_img_paste = false;
    },
    getDropItem() {
      window.addEventListener("drop", (e) => {
        setTimeout(() => {
          if (!this.paste_file.name) {
            e.preventDefault();
            this.on_drag_element = false;
          } else {
            e.preventDefault();
            this.on_drag_element = true;
          }
        }, 1000);
      }, false);
    },
    getDragItem() {
      window.addEventListener("dragover", (e) => {
        e.preventDefault();
        if (e.isTrusted == true) this.on_drag_element = true;
      }, false);
    },
    globalEsc() {
      document.addEventListener("keyup", (event) => {
        if (event.key === "Escape" && this.paste_file?.name) this.deletePasteImg();
      })
    },
    globalEnter() {
      // Global enter
      document.addEventListener("keypress", (event) => {
        if (this.new_show_emoji && this.new_message_chat.length > 0) {
          this.$emit('sendChatMessage', this.new_message_chat);
          this.$emit('updateEmojis', this.new_show_emoji = false)
          this.new_show_emoji = false;
          this.new_message_chat = '';
          this.refresh++;
        };
        if (
          (event.keyCode == 13 && this.show_input_text) ||
          (event.keyCode == 13 && this.on_drag_element)
        ) {
          if (this.paste_file?.name?.length > 0) {
            return this.processFile();
          };
        };
      });
    },
    async processFile() {
      const self = this;
      const file = this.paste_file;
      const sessions = await self.getSessions(self.selectedChat.id);
      const reader = new FileReader();
      const MAXIMO_TAMANIO_BYTES = 3000000;
      const tamanio = MAXIMO_TAMANIO_BYTES / 1000000;

      if (file?.name.length) {
        self.$swal({
          icon: "info",
          title: "Subiendo archivo",
          showConfirmButton: false,
        });
        self.$swal.showLoading();

        if (file.size > MAXIMO_TAMANIO_BYTES) {
          self.$swal({
            icon: "error",
            title: "Achivo muy grande",
            text: `Por favor, adjunta un achivo menor a ${tamanio} Mb.`,
          });
        } else {
          reader.readAsDataURL(file);

          reader.onload = () => {
            conversations_api
              .post('/session_files', {
                file: {
                  file: reader.result,
                  name: file.name,
                  bot_id: self.selected_bot.id,
                  conversation_id: self.selectedChat.id,
                  session_id: sessions.pop().id
                }
              })
              .then((response) => {
                self.setMessageFile({
                  url: `${response.data.url}#${file.name}`,
                });
                self.$swal({
                  toast: true,
                  position: 'top-right',
                  icon: "success",
                  title: "¡Listo!",
                  timer: 1000,
                  showConfirmButton: false,
                });
                self.paste_file = {};
                self.loading_img_paste = false;
                self.show_input_text = false;
                self.on_drag_element = false;
                self.refresh++;
              })
              .catch((error) => console.log('error ->', error));
          };
          reader.onerror = (error) => {
            console.log('Error: ', error);
          };
        }
      }
    },
    async getSessions(conversationId) {
      const sessions = await this.$store.dispatch(
        'sessionsModule/byConversationId',
        {
          conversationId,
        },
      );
      return sessions;
    },
  }
}
</script>

<style lang="scss" scoped>
.drag_copy_paste_input {
  width: 100%;

  .card-footer-2 {
    width: 100%;
    position: relative;
    padding: 2rem;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
    z-index: 10;

    @media (max-width: 768px) {
      padding: 1rem;
    }
    @media (max-width: 480px) {
      bottom: 3rem;
    }
    .textarea_box {
      position: relative;
      // max-width: 850px;
      width: 100%;
      height: auto;
      min-height: 40px;
      max-height: 100px;
      overflow-y: hidden;
      border-radius: 1rem;
      background-color: #eff6ff;
      padding: 0.25rem;
      margin-bottom: 1rem;
      border: 1px solid #bfdbff;
      transition: 0.25s;
      z-index: 8;
      scroll-behavior: smooth;

      &.disabled {
        background-color: #ffffff !important;
        border-color: transparent !important;
        height: 53px !important;
        max-height: 100% !important;
        overflow-y: visible !important;
      }
      .bg-spinner {
        position: relative;
        display: flex;
        justify-content: center;
        top: -12rem;
        right: 0px;
        left: 0px;
        max-width: 480px;
        width: 100%;
        height: 250px;
        background-color: #fafafa;
        object-fit: contain;
        padding: 1rem;
        border: 1px solid #e8e8e8;
        border-radius: 1rem;
        margin: 0 auto 2rem;
      }
      .div_input {
        outline: none;
        width: 100%;
        height: auto;
        padding: 1rem;
        font-size: 1rem;

        &:focus {
          color: #181818;
        }
        &:empty::before {
          content: attr(placeholder);
          color: #767676;
        }
        &:empty:focus::before {
          content: "";
          display: block;
          height: 24px;
        }
      }
      .img_format {
        height: 53px !important;
        background-color: #ffffff;
      }
      .p-text-custom {
        position: absolute;
        top: -215px;
        left: 0;
        right: 0;
        width: fit-content;
        margin: 0 auto;
        z-index: 10;
      }
    }
  }
  .textarea_box:has(.div_input:focus) {
    border: 1px solid #2981ef !important;
  }
  .timer_label {
    position: absolute;
    width: 100%;
    top: 94px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 0.75rem;
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    color: #181818;
    z-index: 10;

    @media (max-width: 1279px) {
      top: 78px;
    }
  }
  .show {
    position: relative;
    padding: 1.25rem;
    border-radius: 0 0 1rem 1rem;
    border: 1px solid #cdcdcd;
    top: -17px;
  }
  .paste_box {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    animation-name: fade;
    animation-duration: .25s;
  }
  .input_drop-file-box {
    position: relative;
    max-height: 100px;
    width: 100%;
    height: 100px;
    border-radius: 1rem;
    color: transparent;
    background-color: #fafafa;
    border: 1px dashed #cdcdcd;
    bottom: .75rem;

    &::-webkit-file-upload-button {
      display: none;
    }
    &:hover {
      border-color: #2981EF !important;
    }
    &:empty::before {
      content: 'Arrastra tu archivo aquí o haz click aquí';
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      color: #767676;
      height: 50px;
      top: 25%;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .droped-file {
    background-color: #f8fbff;
    border: 1px solid #2981EF;

    &::before {
      content: '✓ Archivo cargado. Presiona enter para enviar o escape para cancelar' !important;
      color: #2981EF !important;
    }
  }
  .file-loading::before {
    content: 'Enviando archivo...' !important;
  }
  .input_box {
    width: 100%;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 40px 1fr 40px 40px;
    column-gap: 1rem;

    @media (max-width: 768px) {
      column-gap: .5rem;
    }
    .item {
      min-width: 40px;
      max-width: 40px;
      width: 100% !important;
      height: 40px !important;
    }
    .item-2 {
      max-width: 100%;
      width: 100%;
    }
  }
  .i-btn-white {
    background-color: #fff;
  }
  .btn-emoji, .btn-send, .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .btn-send:hover {
    background-color: #181818;
    border-color: #181818;
  }
  .recording_bullet {
    display: inline-block;
    width: .75rem;
    height: .75rem;
    border-radius: 1rem;
    background-color: #F5365C;
    animation: recording .5s alternate infinite;
  }
  .to_scroll_bar {
    overflow-y: scroll !important;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
      border-radius: 2rem;
      height: 2px;
      background: #bfdbff !important;
      transition: .25s;

      &:hover, &:active {
        background: #A8CDFC !important;
      }
    }
  }
  .textarea_box:hover.to_scroll_bar::-webkit-scrollbar-thumb {
    transition: .25s;
    visibility: visible !important;
  }
  @keyframes recording {
    from { opacity: 1; }
    to { opacity: 0.2; }
  }
}
</style>

<template>
  <th
    :style="`width: ${!is_checkbox ? move_eje_x : 0}px;`"
    :class="{ column: true, sm: is_checkbox}"
    :column_id="`id_${label}`"
    :ref="`column_border_${label}`"
    :draggable="is_drag"
    @mousedown="selected_column = $event"
  >
    <template v-if="!is_checkbox">
      <div class="label-box">
        <label class="mb-0 grey">{{ label }}</label>
        <img class="drag_icon" draggable="false" @mousedown="is_drag = true" src="/img/icons/49-i-drag.svg" alt="">
      </div>
      <span class="drag_border-right" @mousedown="handleMouseDown($event)"></span>
    </template>
    <template v-else>
      <input type="checkbox" v-model="selected" @change="$emit('updateCheck', selected)">
    </template>
  </th>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => ''
    },
    is_checkbox: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      move_eje_x: 200,
      current_column: {},
      is_drag: false,
      selected: [],
      selected_column: {}
    }
  },
  mounted() {
    // Add event listener for mouseup outside component
    document.addEventListener('mouseup', this.handleMouseUp);
  },
  beforeDestroy() {
    // Remove event listener for mouseup
    document.removeEventListener('mouseup', this.handleMouseUp);
  },
  watch: {
    is_checkbox(val) {
      console.log("here->", val);
      
      if (val) this.move_eje_x = 60;
    }
  },
  methods: {
    handleMouseDown(event) {
      this.current_column = event.toElement.offsetParent.getBoundingClientRect();
      this.is_drag = false;

      document.addEventListener('mousemove', this.handleMouseMove);
    },
    handleMouseMove(event) {
      const format = event.x - this.current_column.left;
      this.move_eje_x = format;
    },
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleMouseMove);
      this.is_drag = false;
    },
  }
}
</script>

<style lang="scss">
.column {
  position: relative;
  padding: 1rem;
  background-color: #fafafa;
  border-right: 1px solid #e8e8e8;
  min-width: 150px;
}
.column.sm {
  min-width: 50px !important;
  width: 60px !important;
}
.column .drag_icon {
  opacity: 0;
  padding: .1rem .25rem;
  transition: .25s;
  cursor: move;
}
.column:hover .drag_icon {
  opacity: 1;
}
.column .drag_border-right {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  opacity: 0;
  height: 100%;
  border-right: 1px solid #b2b2b2;
  transition: 0.25s;
}
.column:hover .drag_border-right {
  opacity: 1;
  border-right: 5px solid #b2b2b2 !important;
  cursor: col-resize;
}
</style>
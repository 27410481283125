<template>
  <div class="d-flex flex-column align-items-center">
    <div ref="selectWrapper" @click="isOpen = !isOpen" class="custom-select m-0">
      <div class="selected-option">
        <div class="circle" :style="{ backgroundColor: options.find(e => e.value == agent_status)?.color || 'grey' }">
        </div>
        {{ options.find(e => e.value == agent_status)?.text || 'Selecciona un estado' }}
      </div>
      <div v-if="isOpen" class="options-list">
        <div v-for="(option, index) in options.filter(e => e.value !== agent_status)" :key="option.text + index"
          @click="changeStatus(option.value)" class="option py-2">
          <div class="d-flex align-items-center">
            <div class="circle" :style="{ backgroundColor: option.color }"></div>
            <span>
              {{ option.text }}
            </span>
          </div>
          <p class="description pt-2">{{ option.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { changeAgentStatus } from "../utils/utils";

export default {
  data() {
    return {
      isOpen: false,
      options: [
        {
          text: "Conectado",
          description: "Presente en la plataforma",
          color: "#00D100",
          value: "active"
        },
        {
          text: "Ausente",
          description: "Lejos de la plataforma",
          color: "#efb810",
          value: "absent"
        },
        {
          text: "Desconectado",
          description: "Fuera de horario de atención",
          color: "#d44646",
          value: "inactive"
        },
      ]
    }
  },
  computed: {
    ...mapState([
      'agent_status',
      'userBot',
      'selected_bot'
    ]),
  },
  mounted() {
    // Event listener para cerrar el select cuando se clickea afuera
    document.addEventListener('click', this.handleClickOutside);
  },
  methods: {
    ...mapMutations([
      "setAgentStatus"
    ]),
    async changeStatus(value) {
      await changeAgentStatus(value)
    },
    handleClickOutside(event) {
      // Verificar si el clic es fuera del contenedor del select
      const selectElement = this.$refs.selectWrapper;
      if (selectElement && !selectElement.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 100%;
  padding: 10px 30px 10px 16px;

  &:hover {
    background-color: #f0f0f0;
  }
}

.selected-option {
  user-select: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options-list {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  border-radius: 10px;
}

.option {
  padding: 8px 12px;
  cursor: pointer;
  user-select: none;
}

.option:hover {
  background-color: #f0f0f0;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.description {
  color: #aaa;
  font-size: 0.7rem;
  margin-bottom: 0;
  margin-left: 20px;

}
</style>
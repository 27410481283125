<template>
  <Modal
    class="modal-2"
    ancho="700"
    :show="show"
    @close="$emit('close')"
  >
    <template v-if="show" slot="content">
      <section class="asociar-agente">
        <div class="row">
          <div class="col-md-6 pl-0 pr-2">
            <p v-if="error == 'empty_mail'" class="text_error">Ingresa el correo</p>
            <p v-else-if="error == 'invalid_mail'" class="text_error">Ingresa un correo válido</p>
            <label v-else>Correo del agente a asociar</label>
            <input
              :class="{
                input_error:
                  error == 'empty_mail' ||
                  error == 'invalid_mail',
                to_lowercase: true
              }"
              type="email"
              placeholder="nombre-agente@empresa.cl"
              v-model="model.email"
              required
            />
          </div>
          <div class="col-md-6 pr-0 pl-2">
            <p v-if="error == 'empty_role'" class="text_error">Selecciona el Rol</p>
            <label v-else>Rol en {{ selected_bot.bot_name}}</label>
            <template v-if="roles && roles.length > 0">
              <select
                v-if="selected_bot.id"
                :class="{ capitalize: true, input_error: error == 'empty_role' }"
                name="mostrarrole"
                v-model="model.rol_id"
              >
                <option value disabled>Seleccionar</option>
                <option
                  v-for="role in roles"
                  :value="role.id"
                  :key="role.id"
                >{{ role.name }}</option>
              </select>
            </template>
          </div>
        </div>
        <p class="text_sm">También puedes crear un nuevo agente <router-link to="/agente/nuevo">aquí</router-link></p>
        <div class="row justify-content-center mt-3">
          <button
            class="btn btn-base"
            @click="saveUserBot"
            >Asociar agente
          </button>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";
import axios from "axios";
import environment from "../../environment";

export default {
  name: "AsociarAgentes",
  props: ['show', 'usersResume', 'all_roles'],

  data() {
    return {
      model: {
        rol_id: "",
        email: "",
      },
      error: "",
      selected_rol: {}
    };
  },
  watch: {
    'model.rol_id'(val) {
      this.selected_rol = this.roles.find(el => el.id == val);
      console.log("selected_rol->", this.selected_rol);
    }
  },
  computed: {
    ...mapState(["user", "selected_bot"]),

    roles() {
      if (this.all_roles?.length > 0) {
        return this.all_roles.filter(el => el.name != 'propietario');
      };
    },
  },
  methods: {
    saveUserBot() {
      const self = this;
      self.model.email = self.model.email.toLocaleLowerCase();
      self.error = "";

      if (self.model.email === "") self.error = "empty_mail";
      else if (!self.model.email.includes('@')) self.error = "invalid_mail";
      else if (self.model.rol_id === "") self.error = "empty_role";
      else {
        self.error = "";

        self.$swal({
          icon: "info",
          title: "Estamos relacionando el agente...",
          showConfirmButton: false,
          allowOutsideClick: false
        });
        self.$swal.showLoading();
        const roleName = this.selected_rol.name
        dashboard_api
        .post("/userbot/userbotnew", {
            email: self.model.email,
            bot_id: self.selected_bot.id,
            role_id: self.model.rol_id,
            category: ["ventas", "facturación", "post-venta", "soporte", "general"]
          })
          .then((result) => {
            if (result.status == 201) {
              axios.post(`${environment.url_redis}/new_counter_for_agent`, {
                "agent_id": result.data.user_id,
                "categories": ["ventas", "facturación", "post-venta", "soporte", "general"],
                "role": roleName,
                "bot_id": this.selected_bot.id
              }).then(res=>{
                self.$swal({
                  icon: "success",
                  title: "Agente relacionado correctamente",
                  showConfirmButton: false,
                  timer: 1000
                });
                let user_to_table = {
                  category: ["ventas", "facturación", "post-venta", "soporte", "general"],
                  email: this.model.email,
                  name: result.data.user.name,
                  role: roleName
                }
                self.$emit('addAgentTableIndex', user_to_table);
              }).catch(err => console.log("new_counter_for_agent serror=>", err))
            }
            self.model.email = "";
            self.model.rol_id = "";
            // self.loading = false;
          })
          .catch((err) => {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error",
            });
          });
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.asociar-agente {
  text-align: left;
  padding: 2rem;

  select {
    width: 100%;
  }
  .pl-2, .pr-2 {
    @media (max-width: 960px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .to_lowercase {
    text-transform: lowercase !important;
  }
}
</style>

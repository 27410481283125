<template>
  <tr class="lead_row">
    <td
      v-for="(item, index) in data"
      :key="index"
      class="column item"
      column_id="name"
      item_id="lead_id_3331"
    >{{ item }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style>
.data-table .lead_row {
  border-bottom: 1px solid #e8e8e8;
  transition: .25s;
}
.data-table .lead_row .column .item {
  background-color: #fff !important;
}
.data-table .lead_row:hover td {
  background: #FAFCFF !important;
  cursor: pointer;
}
</style>